import { AIRequest } from '../index'


export function getGameTypeAPI(data) {
  return AIRequest.post({
    url: '/katago/homepage',
    data
  })
}

export function getBigLevelAPI(data) {
  return AIRequest.post({
    url: '/katago/second',
    data
  })
}

export function getSmallLevelAPI(data) {
  return AIRequest.post({
    url: '/katago/third',
    data
  })
}
