<template>
  <div class="main">
    <div v-if="gameInfo.judge">
      <!-- 形势判断 -->
      <button class="xingshi-judge"
              @click="judge(1)"
              :disabled='disableBtn.xingshiBtn'>
      </button>
      <!-- 悔棋 -->
      <button class="takeback"
              @click="takeBack"
              :disabled='disableBtn.takeBackBtn'>
      </button>
      <!-- 申请数棋 -->
      <button class="count"
              @click="judge(2)"
              :disabled='disableBtn.countBtn'>
      </button>
      <!-- 认输 -->
      <button class="giveup"
              @click="fail"
              :disabled='disableBtn.failBtn'>
      </button>
    </div>
    <div v-if="gameInfo.IsFinished">
      <button class="first-step"
              @click="FirstStep"></button>
      <button class="pre-ten-steps"
              @click="PreTenSteps"></button>
      <button class="pre-step"
              @click="PreStep"></button>
      <button class="set-count"
              @click="SetCount"></button>
      <button class="next-step"
              @click="NextStep"></button>
      <button class="next-ten-steps"
              @click="NextTenSteps"></button>
      <button class="last-step"
              @click="LastStep"></button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, getCurrentInstance, watch, ref, nextTick } from 'vue';
import { useStore } from 'vuex';

import { ElMessageBox } from 'element-plus';

const store = useStore();

const props = defineProps({
  gameInfo: Object,
  tempSteps: Number
});

console.log(props.gameInfo);

//watch对局结果，控制按钮disable
watch(
  () => props.gameInfo.IsFinished,
  (newVal, oldVal) => {
    // console.log('deep', newVal, oldVal);
    if (newVal) {
      disableBtn.value = {
        failBtn: true,
        xingshiBtn: true,
        takeBackBtn: true,
        countBtn: true
      };
    } else {
      disableBtn.value = {
        failBtn: false,
        xingshiBtn: false,
        takeBackBtn: false,
        countBtn: false
      };
    }
  },
  { deep: true }
);

//watch下棋步数，控制按钮disable
watch(
  () => props.gameInfo.Steps,
  (newVal, oldVal) => {
    // console.log('deep', newVal, oldVal);
    stepsForBtn();
  },
  { deep: true }
);

const disableBtn = ref({
  failBtn: false,
  xingshiBtn: false,
  takeBackBtn: false,
  countBtn: false
});

const stepsForBtn = () => {
  const setDisableBtn = (steps) => {
    //steps,分有棋形和没棋形的情况，有棋形从第2步开始，没棋形从第1步开始
    if (props.gameInfo.Steps % 2 === steps) {
      //轮黑走
      if (props.gameInfo.Color === 1) {
        //执黑
        disableBtn.value.takeBackBtn = false;
        disableBtn.value.countBtn = false;
      } else {
        //执白
        disableBtn.value.takeBackBtn = true;
        disableBtn.value.countBtn = true;
      }
    } else {
      //轮白走
      if (props.gameInfo.Color === 1) {
        //执黑
        disableBtn.value.takeBackBtn = true;
        disableBtn.value.countBtn = true;
      } else {
        //执白
        disableBtn.value.takeBackBtn = false;
        disableBtn.value.countBtn = false;
      }
    }
  };

  if (props.gameInfo.GameType === 4 || props.gameInfo.GameType === 11) {
    //吃子，有棋形，手数从2开始,,,Steps % 2=1
    setDisableBtn(1);
    disableBtn.value.xingshiBtn = true;
    disableBtn.value.countBtn = true;
  } else {
    setDisableBtn(0);
  }
};

//获取当前实例
const CpnInstance = getCurrentInstance();
// console.log(CpnInstance.parent); CpnInstance.parent.exposed //父组件; //父组件使用setup暴露的属性方法

const $parentFun = CpnInstance.parent.exposed;

const fail = () => {
  ElMessageBox.alert('Are you sure to resign?', 'Confirm', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    type: 'warning'
  })
    .then(() => {
      store.state.mainSocket.giveUp();
    })
    .catch(() => {});
};

const judge = (type) => {
  console.log('点击judge');
  store.state.mainSocket.getXingShiData({ ope: type });
};

const takeBack = () => {
  store.state.mainSocket.withdraw();
};

//上一步
const PreStep = () => {
  if (props.tempSteps > 1) {
    $parentFun.uniBoard.value.GotoPreStep();
  }
  nextTick(() => {
    $parentFun.setTempSteps();
  });
};
const PreTenSteps = () => {
  if (props.tempSteps > 10) {
    $parentFun.uniBoard.value.GoToPreTenSteps();
  } else {
    const tempStep = props.tempSteps;
    for (let i = 1; i < tempStep; i++) {
      console.log(i);
      PreStep();
    }
  }
  nextTick(() => {
    $parentFun.setTempSteps();
  });
};

const NextStep = () => {
  if (props.tempSteps < props.gameInfo.Steps) {
    $parentFun.uniBoard.value.GotoNextStep();
    nextTick(() => {
      $parentFun.setTempSteps();
    });
  }
};

const NextTenSteps = () => {
  if (props.tempSteps < props.gameInfo.Steps - 10) {
    $parentFun.uniBoard.value.GoToNextTenSteps();
  } else {
    const steps = props.gameInfo.Steps,
      tempStep = props.tempSteps;
    for (let i = 0; i < steps - tempStep; i++) {
      NextStep();
    }
  }
  nextTick(() => {
    $parentFun.setTempSteps();
  });
};

const FirstStep = () => {
  $parentFun.uniBoard.value.GotoFirstStep();
  nextTick(() => {
    $parentFun.setTempSteps();
  });
};

const LastStep = () => {
  $parentFun.uniBoard.value.GotoLastStep();
  nextTick(() => {
    $parentFun.setTempSteps();
  });
};

// 在棋子上显示手数
let WriteCount = 0;
const SetCount = () => {
  /*
     判断显示手数的坐标WriteCount：0.显示红色块标记; 1.显示全部手；2.显示当前手
  */
  $parentFun.uniBoard.value.SetWriteCount(WriteCount);
  if (WriteCount === 2) {
    WriteCount = 0;
  } else {
    WriteCount++;
  }
};
</script>




<style scoped lang="scss">
@import '@/assets/css/rem.scss';

.main {
  background-color: rgba($color: #000000, $alpha: 0.3);
  width: 100%;
  padding: rem(10px);
  border-radius: rem(20px);
  // height: 20px;
}

.xingshi-judge {
  background: url(~@/assets/images/gogame/gamebtn/xaj-8.png) no-repeat;
  background-size: cover;
  width: rem(67px);
  height: rem(67px);
  border: 0;
}

.takeback {
  background: url(~@/assets/images/gogame/gamebtn/xaj-11.png) no-repeat;
  background-size: cover;
  width: rem(67px);
  height: rem(67px);
  border: 0;
}

.giveup {
  background: url(~@/assets/images/gogame/gamebtn/xaj-13.png) no-repeat;
  background-size: cover;
  width: rem(67px);
  height: rem(67px);
  border: 0;
}

.count {
  background: url(~@/assets/images/gogame/gamebtn/xaj-12.png) no-repeat;
  background-size: cover;
  width: rem(67px);
  height: rem(67px);
  border: 0;
}

button:disabled {
  cursor: not-allowed;
}

@mixin fupan-btn {
  background-size: cover;
  background-repeat: no-repeat;
  width: rem(40px);
  height: rem(40px);
  border: 0;
  background-color: transparent;
  margin: rem(10px) rem(10px);
}

.first-step {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-2.png);
}

.pre-ten-steps {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-6.png);
}
.pre-step {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-4.png);
}
.set-count {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-3.png);
}
.next-step {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-5.png);
}
.next-ten-steps {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-7.png);
}
.last-step {
  @include fupan-btn;
  background-image: url(~@/assets/images/gogame/gamebtn/xaj-1.png);
}
</style>
