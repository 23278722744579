import { AIRequest } from '../index';

//获取已经完成的比赛
export function All_Matches(params) {
  return AIRequest.post({
    url: '/qipu/getold',
    data: params
  });
}

//获取棋盘数据
export function The_ongoing(params) {
  return AIRequest.post({
    url: '/qipu/getstep',
    data: params
  });
}
