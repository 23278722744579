<template>
  <div class="main">
    <div class="bg">
      <div class="title">
        <span>Buy you membership</span>
        <span style="display:none;">you have 0 day(s) left</span>
      </div>
      <div class="buy">
        <img v-for="(item, i) in goods" :src="imgurl(item.src)" :key="i.id" @click="buygoods(item.price_id)" />
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" class="facklok">
      <el-form :model="form" label-width="100px">
        <el-form-item label="Alias Name" :label-width="name">
          <el-input style="width:100%;" v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Rank" :label-width="level">
          <el-select style="width:100%;" v-model="form.level" placeholder="Please select a level">
            <el-option v-for="item in option" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="information">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus';
import { goods, information } from '../../service/login/login';
export default {
  data() {
    return {
      goods: this.$store.state.goodsinfo,
      sessionid: '',
      isbuy: '',
      dialogFormVisible: false,
      option: [],
      form: {
        name: '',
        level: ''
      }
    };
  },
  methods: {
    buygoods(price_id) {
      if (this.isbuy == 1) {
        let url = 'http://www.xbdeer.com/pay/pay.php?sessionid=' + this.sessionid + '&price_id=' + price_id;
        window.open(url, '_blank');
      } else if (this.isbuy == 0) {
        this.$router.push('./login');
      } else if (this.isbuy == 2) {
        this.dialogFormVisible = true;
      }
    },
    imgurl(url) {
      return require(`@/assets/images/buystore/${url}.png`);
    },
    information() {
      let params = new FormData();
      params.append('sessionid', sessionStorage.getItem('sessid'));
      params.append('nickname', this.form.name);
      params.append('level', this.form.level);
      information(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
          sessionStorage.setItem('info', JSON.stringify(res.data));
          this.dialogFormVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      });
    }
  },
  created() {
    let param = { sessionid: sessionStorage.getItem('sessid') };
    goods(param).then((res) => {
      let arr = ['a1', 'a2', 'a3'];
      for (let i = 0; i < arr.length; i++) {
        res.data[i].src = arr[i];
      }
      this.isbuy = res.is_buy;
      this.goods = res.data;
      if (res == 'login') {
        this.$router.push('./login');
        sessionStorage.removeItem('sessid');
        sessionStorage.removeItem('info');
      }
    });
    for (var i = 25; i >= 1; i--) {
      this.option.push(i + 'k');
    }
    for (var k = 1; k <= 5; k++) {
      this.option.push(k + 'd');
    }
    this.sessionid = sessionStorage.getItem('sessid');
  }
};
</script>
<style scoped lang="scss">
@import '@/assets/css/rem.scss';
.main {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: rem(30px);
  .bg {
    position: relative;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5%;
    .title {
      width: 100%;
      top: 10%;
      left: 10%;
      position: absolute;
      // display: flex;
      // justify-content: space-around;
      font-size: rem(30px);
      color: #fff;
    }
    .buy {
      width: 50%;
      padding: rem(30px);
      text-align: center;
      color: #fff;
      position: absolute;
      margin-top: rem(30px);
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: rem(492px);
        height: rem(103px);
        margin-bottom: rem(20px);
      }
    }
  }
}
</style>
