<template>
        <div class="main">
            <div class="login">
            <el-form class="email-from" :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item prop="username">
                        <!-- 账号 -->
                        <el-input
                        v-model.trim="ruleForm.username"
                        type="email"
                        autocomplete="off"
                        placeholder="Email">
                        </el-input>
                        <img class="icon" src="@/assets/images/login/tb-2.png" alt="">
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item  prop="password">
                        <el-input
                        v-model.trim="ruleForm.password"
                        type="password"
                        autocomplete="off"
                        placeholder="Password">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                    <img src="@/assets/images/login/aj.png" alt="" @click="submitForm('ruleForm')">
                    <img src="@/assets/images/login/aj-2.png" alt="" @click="toforgot">
                    <img src="@/assets/images/login/aj-3.png" style="margin-bottom:0px;" alt="" @click="toresgted">
                    </el-form-item>
                </el-form>
            </div>
            <!-- 弹出框 -->
        </div>
</template>

<script>
import { CommonPicker,ElLoading } from 'element-plus'
import { login } from '../../service/login/login'
import emit from '@/utils/eventbus.js'
export default {
    data(){
      let Email = (rule, value, callback) => {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    if (!value) {
      return callback(new Error('Please input email address'))
    }else{
        callback()
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback()
      } else {
        callback(new Error('Please enter the correct email address'))
      }
    }, 100)
      }
      let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password'));
        } else {
          callback();
        }
      }
        return {
          informations:false,
          option:[],
          dialogFormVisible: false,
             ruleForm:{
               username:'',
               password:''
           },
           rules:{
            username:[
                {validator:Email,trigger:'blur'}
            ],
            password:[
               {validator:validatePass,trigger:'blur'}
            ]
         },
        }
    },
    methods:{
      toforgot(){
        this.$router.push('./forgot')
      },
      toresgted(){
        this.$router.push('./registered')
      },
        submitForm(formName){
          let params = new FormData()
          params.append('username', this.ruleForm.username)
          params.append('password', this.ruleForm.password)
            this.$refs[formName].validate((valid) => {
                if(valid){
                    login(params).then(res=>{
                      if(res==undefined){
                        this.$message({
                        showClose: true,
                        message: 'Incorrect username or password',
                        type: 'error'
                      });
                      }
                      if(res.code==0){
                        this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'success'
                      }); 
                      sessionStorage.setItem('sessid',res.session_id)
                      sessionStorage.setItem('info',JSON.stringify(res.data))
                      this.$router.push('./home')
                      emit.emit('loginsuccess')
                      }
                      if(res=='login'){
                        this.$router.push('./login')
                        sessionStorage.removeItem('sessid')
                        sessionStorage.removeItem('info')
                      }
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';

.main{
    position: relative;
    width: 100%;
    height: 100%;
    .bg{
      width: rem(1090px);
      height: rem(589px);
    }
    .login{
        width: rem(1090px);
        height: rem(589px);
        background:url(~@/assets/images/login/k.png);
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .email-from{
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        img{
          width: 100%;
          height: rem(63px);
          cursor: pointer;
        }
        .icon{
          position: absolute;
          top: 50%;
          right: rem(20px);
          transform: translateY(-50%);
          width:rem(45px);
          height: rem(45px);
        }
        }
    }
}
</style>
