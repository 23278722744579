<template>
  <div id="board_div"
       ref="board_div"
       class="board_div"
       @click="drawChess"
       @mousemove="handleMove">

    <canvas id="Canvas1"
            canvas-id="Canvas1"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas2"
            canvas-id="Canvas2"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas3"
            canvas-id="Canvas3"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas4"
            canvas-id="Canvas4"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas5"
            canvas-id="Canvas5"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas6"
            ref="canvas6"
            canvas-id="Canvas6"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas7"
            canvas-id="Canvas7"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas8"
            canvas-id="Canvas8"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas9"
            canvas-id="Canvas9"
            style="width: 100%; height: 100%; position: absolute;" />
    <canvas id="Canvas10"
            canvas-id="Canvas10"
            style="width: 100%; height: 100%; position: absolute;"
            ref="canvas10" />
    <canvas id="Canvas11"
            canvas-id="Canvas11"
            style="width: 100%; height: 100%; position: absolute;" />
    <img ref="heizi"
         id="heizi"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/hei1.png"
         style="display: none" />
    <img ref="baizi"
         id="baizi"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/bai1.png"
         style="display: none" />
    <img id="heizi2"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/hei2.png"
         style="display: none" />
    <img id="baizi2"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/bai2.png"
         style="display: none" />

    <img id="baizi3"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/bai3.png"
         style="display: none" />
    <img id="heizi3"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/hei3.png"
         style="display: none" />

    <img ref="help_stone"
         id="help_stone"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/help1.png"
         style="display: none" />
    <img id="markChess"
         src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/markChess.png"
         style="display: none" />
    <!-- <audio src="@/static/weiqi/board/dang.wav" id="musice" ref="playChessMusices"></audio> -->
    <!-- <audio :src="successMusic" id="success-musice" ref="successMusices"></audio>
    <audio :src="failMusic" id="fail-musice" ref="failMusices"></audio> -->
  </div>
</template>
<style lang="scss">
canvas {
  position: absolute;
  left: 0px;
  top: 0px;
}

.board_div {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/board1.png');

  // border:1px solid blue;
  .isShow {
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    // border:1px solid blue;
    z-index: 10;

    p {
      width: 24%;
      height: 12%;
      line-height: 12%;
      text-align: center;
      // border: 1px solid red;
      position: absolute;
      left: 39%;
      top: 45%;
      z-index: 10;
      color: red;
    }

    img {
      width: 16%;
      height: 5%;
      cursor: pointer;
      // border: 1px solid red;
      position: absolute;
      left: 42%;
      top: 50%;
      cursor: pointer;
      z-index: 10;
    }
  }
}
</style>
<script>
import CreateABoard from './js/vg_board.js';
// import failMusic1 from "@/assets/images/exercise/cuo-1.wav";
// import failMusic2 from "@/assets/images/exercise/cuo-2.wav";
// import failMusic3 from "@/assets/images/exercise/cuo-3.wav";
// import failMusic4 from "@/assets/images/exercise/cuo-4.wav";
// import failMusic5 from "@/assets/images/exercise/cuo-5.wav";
// import successMusic1 from "@/assets/images/exercise/dui-1.wav";
// import successMusic2 from "@/assets/images/exercise/dui-2.wav";
// import successMusic3 from "@/assets/images/exercise/dui-3.wav";
// import successMusic4 from "@/assets/images/exercise/dui-4.wav";
// import successMusic5 from "@/assets/images/exercise/dui-5.wav";
export default {
  // components:{
  // 	CreateABoard
  // },
  props: {
    getAI: {
      type: Number,
      default: 0
    },
    getBoardSize: {
      type: Number,
      default: 0
    },
    getType: {
      type: Number,
      default: 0
    },
    isPlayChess: {
      type: Number,
      default: 0
    },
    getColor: {
      type: Number,
      default: 0
    },
    taskManage: {
      type: Number,
      default: 0
    },
    noMove: {
      type: Boolean
    }
    // getKeyCode: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    // getIsAgainSearch: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      isSave: 0, //是否保存之前的标记字母进度
      blockAndwhite: 1, //落子颜色
      keyCode: {}, //监听键盘按的键
      isRelax: 0, //语音教室是否显示跳过休息
      againSearch: 0,
      stopChess: false, //默认可以落子
      //创建一个存储失败音乐的数组
      failMusic: '',
      //创建一个存储成功音乐的数组
      successMusic: '',
      thisBoard: null,
      boardReay: false,
      CurrentSliderValue: -1,
      bgimg: 'http://duiyi.xbkids.cn/wx/img/ai/weiqi/board/board1.png',
      chessBoxBg: '',
      SelfPu: '',
      GotoStepByKeyStr: '',
      isFirst: false, //判断习题模块研究是否下的是第一颗棋子
      control: false, //任务管理模块控制是否有声音
      openSearch: false, //是否打开习题模块研究模式
      mule: false,
      xy1: null,
      x: 0,
      y: 0
    };
  },
  created() {
    //console.log("create a board");
  },
  watch: {
    // keyCode: {
    //   deep: true,
    //   handler(v) {
    //     this.keyCode = v;
    //     //console.log(this.keyCode);
    //     ;
    //   },
    // },
    // "$store.state.setUp.boardType": function () {
    //   //设置模块改变棋盘
    //   if (this.$store.state.setUp.boardType) {
    //     this.bgimg =
    //       "./img/board/board" + this.$store.state.setUp.boardType + ".png";
    //   }
    // },
    // "$store.state.setUp.chessType": function () {
    //   //设置模块改变棋子
    //   if (this.$store.state.setUp.chessType) {
    //     this.getImgUrl("black");
    //     this.getImgUrl("white");
    //   }
    // },
    // "$store.state.getRelax": function () {
    //   //语音教室模块播放是否休息
    //   this.isRelax = this.$store.state.getRelax;
    // },
    // "$store.state.getKeyCode": function () {
    //   this.keyCode = this.$store.state.getKeyCode;
    // },
    // "$store.state.blockAndwhite": function () {
    //   this.blockAndwhite = this.$store.state.blockAndwhite;
    //   //console.log(this.blockAndwhite);
    // },
    '$store.state.blockAndwhite': {
      deep: true,
      handler: function (newV, oldV) {
        //console.log(newV);
        //console.log(oldV);
        // ////////////debugger;
        if (this.$store.state.judgeMenuNumber == 3) {
          this.blockAndwhite = this.$store.state.blockAndwhite.Tree.name;
          //console.log(this.blockAndwhite);
        } else if (this.$store.state.judgeMenuNumber == 4) {
          this.blockAndwhite = this.$store.state.blockAndwhite.Four.name;
          //console.log(this.blockAndwhite);
        } else if (this.$store.state.judgeMenuNumber == 5) {
          this.blockAndwhite = this.$store.state.blockAndwhite.Five.name;
          //console.log(this.blockAndwhite);
        }
      }
    },
    '$store.state.judgeMenuNumber': function () {
      if (this.$store.state.judgeMenuNumber == 3) {
        this.blockAndwhite = this.$store.state.blockAndwhite.Tree.name;
        //console.log(this.blockAndwhite);
      } else if (this.$store.state.judgeMenuNumber == 4) {
        this.blockAndwhite = this.$store.state.blockAndwhite.Four.name;
        //console.log(this.blockAndwhite);
      } else if (this.$store.state.judgeMenuNumber == 5) {
        this.blockAndwhite = this.$store.state.blockAndwhite.Five.name;
        //console.log(this.blockAndwhite);
      }
    }
  },
  mounted() {
    this.initBoard();

    // setTimeout(that.resizeBoard, 1000);
    // this.resizeBoard();
  },

  activated: function () {},
  onShow: function () {
    //console.log("Show board");
  },
  methods: {
    initBoard() {
      let board_div = this.$refs.board_div;
      if (board_div) {
        if (this.thisBoard == null) {
          //console.log("创建棋盘");
          this.thisBoard = new CreateABoard(board_div, this.getBoardSize, this, this.getType);
          this.$emit('boardEvent', '1 1');
          this.boardReay = true;
          this.painting = false; //画棋的标记
          this.mark_status = ''; //棋谱标记 圆、三角等
          // that.blockAndwhite = 1; //黑、白、黑白交替
          // that.keyCode = {}; //键盘按键
          this.clockState = false; //时钟开启事件
          this.SelfPu = this.thisBoard.SelfPu;
          this.GotoStepByKeyStr = this.thisBoard.GotoStepByKeyStr;
          this.illegality = false; //非法落子的状态
          // console.log(this.thisBoard);
        } else {
          this.boardReay = true;
        }
      } else {
        setTimeout(() => {
          this.initBoard();
        }, 2000);
      }
    },

    xyPos(x, y) {
      //获取第一手棋子数据
      if (this.thisBoard != null) {
        return this.thisBoard.xyPos(x, y);
      }
    },
    getFirstString() {
      //获取第一手棋子数据
      if (this.thisBoard != null) {
        return this.thisBoard.getFirstString();
      }
    },
    getCurrentComment() {
      //获取当前评论
      if (this.thisBoard != null) {
        return this.thisBoard.getCurrentComment();
      }
    },
    deleteLetChess(item) {
      //删除第一手让子 cb加
      if (this.thisBoard != null) {
        this.thisBoard.deleteLetChess(item);
      }
    },
    deleteSingleShape(item, number) {
      //删除某个摆形棋子 cb加
      //console.log(number);
      if (this.thisBoard != null) {
        this.thisBoard.deleteSingleShape(item, number);
      }
    },
    deleteSingleMark(item) {
      //删除某个标记 cb加
      if (this.thisBoard != null) {
        this.thisBoard.deleteSingleMark(item);
      }
    },
    chooseMarkChess(item, number) {
      //选择某个标记 cb加
      if (this.thisBoard != null) {
        this.thisBoard.chooseMarkChess(item, number);
      }
    },
    chooseShapeChess(item, number) {
      //选择某个摆棋 cb加
      if (this.thisBoard != null) {
        this.thisBoard.chooseShapeChess(item, number);
      }
    },
    changeType(param) {
      //任务管理模块控制是否有声音 cb加
      //console.log("===============================>");
      this.control = param;
      //console.log(this.control);
      this.thisBoard.changeBoardType(param);
    },
    relaxCourse() {
      //语音教室模块播放课程休息 cb加
      this.isRelax = 0;
      this.$emit('showRelax', this.isRelax);
    },
    isAgainSearch() {
      //习题模块是否重新研究 cb加
      this.againSearch++;
    },
    isStopChess(params) {
      //是否可以落子 cb加
      this.stopChess = params;
    },
    handleMove(e) {
      var x = e.offsetX;
      var y = e.offsetY;
      //console.log("x="+x+"----------y="+y);
      if (this.thisBoard != null) {
        if (this.thisBoard.GetBoardType() == 5) {
          return;
        }
        this.thisBoard.ShowIndicator(x, y);
      }
    },
    // getImgUrl(imgid) {
    //   var imgname = "t_" + imgid + this.$store.state.setUp.chessType + ".png";
    //   return "./img/board/" + imgname;
    // },
    closeScoket() {
      if (window.ipcRenderer) {
        window.ipcRenderer.send('close2');
      }
    },
    setCanvasStateFn() {
      //专门用于下棋时，画布失效
      if (this.painting) {
        //画棋时，不能下棋
        return;
      }
      this.closeCanvas(); //画布失效
    },
    drawChess(e) {
      // console.log(e)
      // console.log('点击棋盘');
      let str = '';
      // console.log(this.keyCode);
      // if (this.keyCode.length == 1) {
      // 	//摆棋  第一手摆棋
      // 	if (this.keyCode[0] == 16) {
      // 		str = "shift";
      // 	} else if (this.keyCode == 17) {
      // 		// console.log("进来了");
      // 		str = "ctrl";
      // 	}
      // }
      // console.log(str)
      if (!this.boardReay) {
        return;
      }
      if (this.noMove) {
        //画棋时，不能下棋
        return;
      }
      if (this.stopMove) {
        return;
      }
      if (this.aiFir) {
        return;
      }

      // this.clearCanvas(); //清空画布
      let div = document.getElementById('Canvas1'); //创建节点视图，视图为当前棋盘组件，选择Canvas1节点
      let xx = e.clientX; //clientX 事件属性返回当事件被触发时鼠标指针相对于浏览器页面（或客户区）的水平坐标。
      let yy = e.clientY; // 客户区指的是当前窗口。

      // console.log("点击x的坐标:" + xx); //测试
      // console.log("点击y的坐标:" + yy); //测试
      let x = 0;
      let y = 0;
      let xy = {};
      // console.log(this.stopChess)
      if (this.stopChess == false) {
        //AI闯关无棋谱标记，老师评棋时候有棋谱表及操作

        // 非标记操作时
        this.thisBoard.changeMarkPic(this.isSave);
        let that = this;
        //添加节点的布局位置的查询请求
        console.log(div);
        let data = div.getBoundingClientRect();
        console.log(data);
        // console.log('发送落子')
        x = xx - data.left;
        y = yy - Math.round(data.top);
        xy = that.xyPos(x, y);
        // console.log('发送落子1')

        that.clearCanvas5(x, y);
        that.illegality = that.thisBoard.GetCurrentCol(x, y, str, that.blockAndwhite);
        // console.log(that.illegality)
        if (!that.illegality) {
          this.$emit('boardEventTree', 'addstep');
          // that.sound()
          that.mule = false;
        } else {
          // console.log('发送落子2')

          // console.log(that.illegality);
          // console.log(that.$store.state.WeiQiSocket);
          that.$store.state.mainSocket.luoZi({ stepStr: that.illegality });
        }
        // }
      }
    },

    // sound() {
    //   var audioContext = uni.createInnerAudioContext()
    //   audioContext.autoplay = false
    //   audioContext.src = '../../static/weiqi/board/dang.wav'
    //   audioContext.onPlay(() => {
    //     //console.log('开始播放')
    //   })
    //   audioContext.onError((res) => {
    //     //console.log('sdasdsadsadasdsadas', res.errMsg)
    //     //console.log(res.errCode)
    //   })
    //   audioContext.play()
    // },
    resizeBoard() {
      this.thisBoard.getwh();
    },
    changeBoardSize() {
      this.thisBoard.changeBoardSize();
    },
    SetSize(inw, inh) {
      //console.log("Set Size,", inw, ",", inh);
      // var that = this;
      // if (inw == 19) {
      //   // this.pu = "B[AA];W[BB];";
      //   //this.thisBoard = new CreateABoard(inw, inh, this);
      // } else if (inw == 13) {
      //   // this.pu = "B[AA];W[BB];";
      //   //this.thisBoard = new CreateABoard(inw, inh, this);
      // }
    },
    SetBoardInfo(inh) {
      var bs = inh;
      this.thisBoard.SetBoardSize(bs);
      // this.resizeBoard();
    },
    ReceiveStep(inS) {
      //console.log("Send a Step in to board");
      this.thisBoard.ReaceiveAStep(inS);
    },
    AddToLast(inS) {
      this.thisBoard.AddToLast(inS);
    },
    /*
			  画表情
			*/
    drawEmotionBoard(an) {
      if (this.thisBoard != null) {
        this.thisBoard.drawEmotionBoard(an);
      }
    },
    judgeAI() {
      //判断是否是AI闯关  cb加
      if (this.thisBoard != null) {
        this.thisBoard.judgeAI(true);
      }
    },
    /*
			  取消表情
			*/
    cancelDrawEmotionBoard() {
      if (this.thisBoard != null) {
        this.thisBoard.cancelDrawEmotionBoard();
      }
    },
    /*
			  取消支招
			*/
    cancelDrawHelpStoneMore() {
      if (this.thisBoard != null) {
        this.thisBoard.cancelDrawHelpStoneMore();
      }
    },
    /*
			  取消AI形势
			*/
    cancelDrawSelfAN() {
      if (this.thisBoard != null) {
        this.thisBoard.cancelDrawSelfAN();
      }
    },
    /*
			  判断AI形势、AI支招、棋子表情是否有数据
			*/
    hasAIData() {
      if (this.thisBoard != null) {
        return this.thisBoard.hasAIData();
      }
    },
    LoadFromString(inStr) {
      if (this.thisBoard != null) {
        //console.log("Hahahahah");
        this.thisBoard.LoadFromString(inStr);
      }
    },
    UndoRemoveAll() {
      this.thisBoard.UndoRemoveAll();
    },
    SetCanStepIn(inValue) {
      this.thisBoard.SetCanStepIn(inValue);
    },
    ReadFromStream(data) {
      this.thisBoard.ReadFromStream(data);
    },
    changeScale() {
      this.thisBoard.changeScale();
    },
    GotoNextSub() {
      this.thisBoard.GotoNextSub();
    },
    hideScale() {
      if (this.thisBoard != null) {
        this.thisBoard.hideScale();
      }
    },
    GetCurrentNodeInfo() {
      if (this.thisBoard != null) {
        return this.thisBoard.GetCurrentNodeInfo();
      }
    },
    isMark(param) {
      //是否有标记题
      this.thisBoard.isMark(param);
    },
    taskManages(param) {
      //cb加
      //console.log("cb加");
      this.thisBoard.taskManage(param);
    },
    GenerateTreeData() {
      //cb加
      if (this.thisBoard != null) {
        var tree = this.thisBoard.GenerateTreeData();
        return tree;
      }
    },
    addEmotion() {
      this.thisBoard.addEmotion();
    },
    setJudge() {
      this.thisBoard.setJudge();
    },
    addStep(item) {
      //对方落子 cb加
      if (this.thisBoard != null) {
        this.thisBoard.addStep(item);
        // var audioContext = uni.createInnerAudioContext()
        // audioContext.autoplay = false
        // audioContext.src = '../../static/weiqi/board/dang.wav'
        // audioContext.onPlay(() => {
        //   //console.log('开始播放')
        // })
        // audioContext.onError((res) => {
        //   //console.log('sdasdsadsadasdsadas', res.errMsg)
        //   //console.log(res.errCode)
        // })
        // audioContext.play()
      }
    },
    MsgFromBoard(cmd, msg, number, number1) {
      //console.log(cmd);
      var S = '';
      let arr = [];
      // if(typeof(msg)=="string"){
      //   this.$emit("title");
      // }
      //console.log("cmd====" + cmd, msg, number, number1);
      if (cmd == 3) {
        // 双方提子数
        S = '3 ' + msg[0] + ' ' + msg[1];
      } else if (cmd == 4) {
        // 当前手数..
        S = '4 ' + msg;
      } else if (cmd == 6) {
        // 总共手数
        S = '6 ' + msg;
      } else if (cmd == 7) {
        //显示变化图
        arr.push(msg);
        S = '7 ' + arr;
      } else if (cmd == 8) {
        // 显示当前评论
        if (msg) {
          msg = msg.trim(msg);
          if (msg != '') {
            this.$emit('title', msg);
          }
        }
        S = '8 ' + msg;
      } else if (cmd == 9) {
        // 显示当前声音
        S = '9 ' + msg;
      } else if (cmd == 10) {
        // 生成评论棋谱字符串？
        S = '10 ' + msg;
      } else if (cmd == 11) {
        S = '11 ' + msg;
      } else if (cmd == 12) {
        S = '12 ' + msg;
        //console.log(msg);

        this.$emit('title', msg);
      } else if (cmd == 13) {
        S = '13 ' + msg;
      } else if (cmd == 14) {
        S = '14 ' + msg;
      } else if (cmd == 55) {
        //console.log("here receive 55");
        if (msg == -1) {
          //console.log("答错了");
          // if (!this.control) {
          //   this.$emit("homeWork", 0);
          // }
          // if (
          //   this.$store.state.rightErrorMusic == true &&
          //   !this.control &&
          //   this.taskManage == 0 &&
          //   !this.openSearch
          // ) {
          //   //console.log("进来了");
          //   //随机播放答错音频
          //   // var failAllMusic = [
          //   //   failMusic1,
          //   //   failMusic2,
          //   //   failMusic3,
          //   //   failMusic4,
          //   //   failMusic5,
          //   // ];
          //   // this.failMusic = failAllMusic[Math.floor(Math.random() * 5)];
          //   // this.$nextTick(() => {
          //   //   this.$refs.failMusices.play();
          //   // });
          // } //chenbin改
          // // //console.log(this.failMusic);
        } else if (msg == 0) {
          //console.log("请继续选点。");
        } else if (msg == 1) {
          //console.log("恭喜你，答对了。");
          // //随机播放答对音频
          // if (!this.control) {
          //   this.$emit("homeWork", 1);
          // }
          // if (
          //   this.$store.state.rightErrorMusic == true &&
          //   !this.control &&
          //   this.taskManage == 0 &&
          //   !this.openSearch
          // ) {
          //   //console.log("进来了");
          //   var successAllMusic = [
          //     successMusic1,
          //     successMusic2,
          //     successMusic3,
          //     successMusic4,
          //     successMusic5,
          //   ];
          //   this.successMusic = successAllMusic[Math.floor(Math.random() * 5)];
          //   this.$nextTick(() => {
          //     this.$refs.successMusices.play();
          //   });
          // } //chenbin改
          // //console.log(this.successMusic);
        } else if (msg == 2) {
          //console.log("请继续选点。");
        } else if (msg == 3) {
          //console.log("答错了");
          // if (!this.control) {
          //   this.$emit("homeWork", 0);
          // }
          // if (
          //   this.$store.state.rightErrorMusic == true &&
          //   !this.control &&
          //   this.taskManage == 0 &&
          //   !this.openSearch
          // ) {
          //   //随机播放答错音频
          //   var failAllMusic = [
          //     failMusic1,
          //     failMusic2,
          //     failMusic3,
          //     failMusic4,
          //     failMusic5,
          //   ];
          //   this.failMusic = failAllMusic[Math.floor(Math.random() * 5)];
          //   this.$nextTick(() => {
          //     this.$refs.failMusices.play();
          //   });
          // } //chenbin改
        } else if (msg == 4) {
          //console.log("请继续选点。");
        }
        // 播放动画
      } else if (cmd == 56) {
        //console.log(msg);
        //var a = msg.slice(2);
        var b = msg;
        // //console.log(this.$store.state.nextChess);
      } else if (cmd == 57) {
        //cb加
        this.$emit('title', '黑先,请做题');
      } else if (cmd == 100) {
        //cb加 返回删除某个摆形棋子
        ////////////////debugger;
        //console.log(msg);
        // alert("3333333333333");
        this.$emit('boardEventTree', 10, number);
      } else if (cmd == 101) {
        //cb加 返回让多少子数据
        //console.log(msg);
        this.$emit('boardEventTree', 11, msg);
      } else if (cmd == 102) {
        //cb加 删除第一手让子
        this.$emit('boardEventTree', 12);
      } else if (cmd == 1000) {
        //console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
        //console.log(msg);
        //console.log(number);
        this.$emit('boardEventTree', cmd, msg, number);
      } else if (cmd == 1001) {
        this.$emit('boardEventTree', cmd);
      } else if (cmd == 10000) {
        //对局是否结束
        this.$emit('boardEventTree', cmd, msg, number, number1);
      }

      if (S != '') {
        this.$emit('boardEvent', cmd, S);
        return false;
      }
      //console.log(cmd);
      //console.log(msg);
      this.$emit('boardEvent', cmd, msg, b);
    },
    ClearAll() {
      if (this.thisBoard != null) {
        //console.log("清空棋盘");
        this.thisBoard.ClearAll();
      }
    },
    gameResult(item, blackTime, whiteTime) {
      //对局是否结束
      if (this.thisBoard != null) {
        this.thisBoard.gameResult(item, blackTime, whiteTime);
      }
    },
    SetBoardSize(inBZ) {
      if (this.thisBoard != null) {
        this.thisBoard.SetBoardSize(inBZ);
        this.resizeBoard();
      } else {
        setTimeout(() => {
          this.SetBoardSize(inBZ);
        }, 2000);
      }
    },
    openHomeWork() {
      //打开今日作业模块 cb加
      if (this.thisBoard != null) {
        this.thisBoard.openHomeWork(true);
      }
    },
    SetShowCol() {
      if (this.thisBoard != null) {
        this.thisBoard.changeZhouWei();
      }
    },
    GeneratePrintInfo(startStep, stopStep) {
      if (this.thisBoard != null) {
        return this.thisBoard.GeneratePrintInfo(startStep, stopStep);
      }
    },
    clearSaveMarkChess1() {
      //清空保留的标记棋子

      if (this.thisBoard != null) {
        this.thisBoard.clearSaveMarkChess();
      }
    },
    changeBoxPhoto() {
      //棋谱转成图片 cb加
      if (this.thisBoard != null) {
        var box = this.thisBoard.changeBoxPhoto();

        //console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBB");
        return box;
      }
    },
    changeChessPhoto() {
      //棋谱转成图片 cb加
      if (this.thisBoard != null) {
        var chess = this.thisBoard.changeChessPhoto();

        //console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBB");

        return chess;
      }
    },
    GotoFirstStep(param) {
      // 跳转到第一手
      if (this.thisBoard != null) {
        // this.thisBoard.diyibu();
        this.thisBoard.GotoFirstStep(param);
      }
    },
    GetCurrentNodeKeyStr(number) {
      return this.thisBoard.GetCurrentNodeKeyStr(number);
    },
    GetCount() {
      return this.thisBoard.GetCount();
    },
    GotoPreStep(param) {
      if (this.thisBoard != null) {
        // this.thisBoard.jianyi();
        this.thisBoard.GotoPreStep(param);
      }
    },
    GotoNextStep() {
      if (this.thisBoard != null) {
        // this.thisBoard.jiayi();
        this.thisBoard.GotoNextStep();
      }
    },
    GotoLastStep() {
      if (this.thisBoard != null) {
        this.thisBoard.GotoLastStep();
      }
    },
    GoToPreTenSteps(param) {
      if (this.thisBoard != null) {
        this.thisBoard.GoToPreTenSteps(param);
      }
    },
    GoToNextTenSteps() {
      if (this.thisBoard != null) {
        this.thisBoard.GoToNextTenSteps();
      }
    },
    WriteCount() {
      if (this.thisBoard != null) {
        // this.thisBoard.xianshiquanbushou();
        this.thisBoard.GetCurrentStepPosition();
      }
    },
    setWriteCountValue() {
      if (this.thisBoard != null) {
        // this.thisBoard.xianshiquanbushou();
        this.thisBoard.setWriteCountValue();
      }
    },
    SetStartCount(inValue) {
      if (this.thisBoard != null) {
        this.thisBoard.SetStartCount(inValue);
      }
    },
    ReturnMainPu() {
      if (this.thisBoard != null) {
        this.thisBoard.ReturnMainPu();
      }
    },
    AddVary() {
      if (this.thisBoard != null) {
        this.thisBoard.AddVary();
      }
    },
    MarkPic(num) {
      if (this.thisBoard != null) {
        this.thisBoard.MarkPic(num);
      }
    },
    changeMarkPic() {
      if (this.thisBoard != null) {
        this.thisBoard.changeMarkPic(0);
      }
    },
    FreeDrawpic(FreeDrawpicColor = 'red', FreeDrawpicSize = '3') {
      if (this.thisBoard != null) {
        this.thisBoard.FreeDrawpic(FreeDrawpicColor, FreeDrawpicSize);
      }
    },
    DrawMypic(type) {
      if (this.thisBoard != null) {
        this.thisBoard.DrawMypic(type);
      }
    },
    clearCanvas() {
      if (this.thisBoard != null) {
        this.thisBoard.clearCanvas();
      }
    },
    clearCanvas5(x, y) {
      if (this.thisBoard != null) {
        if (x && y) {
          this.thisBoard.clearCanvas5(x, y);
          this.mule = false;
        } else {
          //console.log('sdasdasdsadasdasdasdasdsa')
          this.thisBoard.clearCanvas5(this.x, this.y);
          this.mule = false;
        }
      }
    },
    revocationCanvas() {
      if (this.thisBoard != null) {
        this.thisBoard.revocationCanvas();
      }
    },
    closeCanvas() {
      if (this.thisBoard != null) {
        this.thisBoard.closeCanvas();
      }
    },
    GoInToVaryLast(str) {
      if (this.thisBoard != null) {
        this.thisBoard.GoInToVaryLast(str);
      }
    },
    SetBoardType(inType) {
      if (this.thisBoard != null) {
        // this.getType = inType
        this.thisBoard.SetBoardType(inType);
      }
    },
    ReceiveAComment(strr) {
      if (this.thisBoard != null) {
        this.thisBoard.ReceiveACommentIn(strr);
      }
    },
    GotoPreComment() {
      if (this.thisBoard != null) {
        this.thisBoard.GotoPreComment();
      }
    },
    GotoNextComment() {
      if (this.thisBoard != null) {
        this.thisBoard.GotoNextComment();
      }
    },
    GotoCountCommentIn() {
      if (this.thisBoard != null) {
        var ComNum = this.thisBoard.CountCommentIn();
        //console.log("gotocountcommentIn:" + ComNum);
        return ComNum;
      }
    },
    getStepComment() {
      if (this.thisBoard != null) {
        var stepCom = this.thisBoard.getStepComment();
        return stepCom;
      }
    },
    DrawOutGraphic() {
      if (this.thisBoard != null) {
        this.thisBoard.DrawOutGraphic();
      }
    },
    ReceiveAudio(str, num) {
      if (this.thisBoard != null) {
        //console.log("声音：" + str);
        var AudioNum = this.thisBoard.ReceiveAudio(str, num);
        return AudioNum;
      }
    },
    drawSuccessPoint(item, number) {
      //画胜率点
      if (this.thisBoard != null) {
        var drawSuccess = this.thisBoard.drawSuccessPoint(item, number);
        return drawSuccess;
      }
    },
    RegretAtep(str) {
      if (this.thisBoard != null) {
        if (str == 'delete') {
          this.thisBoard.RegretAtep(str);
        } else {
          var a = this.thisBoard.RegretAtep();
          if (a == 'showmodal') {
            return a;
          }
        }
      }
    },
    SliderToCurrentStep(CurrentValue) {
      this.CurrentSliderValue = CurrentValue;
    },
    SaveToString(param) {
      if (this.thisBoard != null) {
        var str = this.thisBoard.SaveToString(param);
        return str;
      }
    },
    GetToCurrent(inType) {
      //console.log(inType);
      if (this.thisBoard != null) {
        var str = this.thisBoard.GetToCurrent(inType);
        return str;
      }
    },
    openSearchs(param) {
      //打开习题模块研究模式 cb加
      this.openSearch = param;
      //console.log(this.openSearch);
    },
    AssignJudgeData(inM, inFinal) {
      if (this.thisBoard != null) {
        this.thisBoard.AssignJudgeData(inM, inFinal);
      }
    },
    ShowAnswer(param) {
      //cb改
      if (this.thisBoard != null) {
        this.thisBoard.ShowAnswer(param);
      }
    },
    ShowErrorAction(ErrorStr) {
      if (this.thisBoard != null) {
        this.thisBoard.ShowErrorAction(ErrorStr);
      }
    },
    SaveToFile() {
      var s = this.thisBoard.SaveToFile();
      return s;
    },
    CancelAnswer() {
      if (this.thisBoard != null) {
        this.thisBoard.CancelAnswer();
      }
    },
    DeleteNowGraph(str) {
      if (this.thisBoard != null) {
        if (str == 'delete') {
          this.thisBoard.DeleteNowGraph(str);
        } else {
          var a = this.thisBoard.DeleteNowGraph();
          if (a == 'nomark') {
            return a;
          }
        }
      }
    },
    DeleteNowParent(str) {
      if (this.thisBoard != null) {
        if (str == 'delete') {
          this.thisBoard.DeleteNowParent(str);
        } else {
          var a = this.thisBoard.DeleteNowParent();
          if (a == 'nobranch') {
            return a;
          }
        }
      }
    },
    HasVoice() {
      if (this.thisBoard != null) {
        var a = this.thisBoard.HasVoice();
        return a;
      }
    },
    // 解析95字节
    UnzipBoard(board) {
      var a = this.thisBoard.UnzipBoard(board);
      return a;
    },
    // 画目
    DrawMu(finalboard, muboard) {
      this.thisBoard.DrawMu(finalboard, muboard);
    },
    //AI画目
    AIDrawMu(finalboard, muboard) {
      this.thisBoard.AIDrawMu(finalboard, muboard);
    },
    // 重新画棋盘
    DrawBoardFn(arr) {
      this.thisBoard.DrawBoardFn(arr);
    },
    // 请求帮助
    drawHelpStone(pu, num) {
      this.thisBoard.drawHelpStone(pu);
    },
    // 请求帮助--多个 AI支招
    drawHelpStoneMore(arr) {
      this.thisBoard.drawHelpStoneMore(arr);
    },
    // AI形势
    drawSelfAN(selfAN) {
      this.thisBoard.drawSelfAN(selfAN);
    },
    GetStepInfo() {
      if (this.thisBoard) {
        var a = this.thisBoard.GetStepInfo();
      }

      //console.log(a);
      return a;
    },
    GetZippedBoard() {
      if (this.thisBoard != null) {
        return this.thisBoard.GetZippedBoard();
      }
    },
    SetShowIndicator(inValue) {
      if (this.thisBoard != null) {
        this.thisBoard.SetCanShowIndicator(inValue);
      }
    },
    ShowIndicator(x, y) {
      if (this.thisBoard != null) {
        this.thisBoard.ShowIndicator(x, y);
      }
    },
    SetWriteCount(inValue) {
      if (this.thisBoard) {
        this.thisBoard.setWriteCount(inValue);
      }
    },
    DecideWriteCount() {
      if (this.thisBoard) {
        this.thisBoard.decideWriteCount();
      }
    },
    initWriteCount() {
      //cb加
      if (this.thisBoard) {
        this.thisBoard.initWriteCount();
      }
    },
    //播放时拿当前声音的文件名
    getNowVoice() {
      if (this.thisBoard) {
        return this.thisBoard.getNowVoice();
      }
    },
    //播放时拿当前声音的文件名
    AddAudioNum() {
      if (this.thisBoard) {
        return this.thisBoard.AddAudioNum();
      }
    },
    changeAudioNum(num) {
      if (this.thisBoard != null) {
        this.thisBoard.changeAudioNum(num);
      }
    },
    setYYBoard(inData) {
      if (this.thisBoard != null) {
        this.thisBoard.SetCurrentBoardForYY(inData);
      }
    },
    setFreeDrawData(inData) {
      if (this.thisBoard != null) {
        this.thisBoard.DrawOutFreeDraw(inData);
      }
    },
    getLastStepColor() {
      if (this.thisBoard != null) {
        return this.thisBoard.GetLastStepColor();
      }
    },
    getStepValue(i, j) {
      if (this.thisBoard != null) {
        return this.thisBoard.getStepValue(i, j);
      }
    },
    judgeNextNode() {
      if (this.thisBoard != null) {
        return this.thisBoard.judgeNextNode();
      }
    },
    SetEndTypeByKeyStr(param1, param2) {
      if (this.thisBoard != null) {
        return this.thisBoard.SetEndTypeByKeyStr(param1, param2);
      }
    },
    saveLetterProgress(param) {
      this.isSave = param;
      //console.log(this.isSave);
    },
    GetKeyStrByStep(param) {
      if (this.thisBoard != null) {
        return this.thisBoard.GetKeyStrByStep(param);
      }
    },
    GotoStepByKeystr(inKey) {
      if (this.thisBoard != null) {
        return this.thisBoard.GotoStepByKeyStr(inKey);
      }
    }
  }
};
</script>

<style></style>
