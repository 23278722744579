/*
   棋盘部分代码，这里只负责画的部分，具体的棋谱的逻辑，由AilasQipu来完成
*/
let TheQipuManager = require('./Aliasqipu.js')

import store from '../../../store/index'

let CreateABoard = function (thisboard_div, BoardSize, that, BoardType) {
  let locationLinePos = null
  let c = document.createElement('Canvas')
  let cxt = c.getContext('2d')
  let c2 = document.createElement('Canvas')
  let cxt2 = c2.getContext('2d')
  let c3 = document.createElement('Canvas')
  let cxt3 = c3.getContext('2d')
  let c4 = document.createElement('Canvas')
  let cxt4 = c4.getContext('2d')
  let c5 = document.createElement('Canvas')
  let cxt5 = c5.getContext('2d')
  let c6 = document.createElement('Canvas')
  let cxt6 = c6.getContext('2d')
  let c7 = document.createElement('Canvas')
  let cxt7 = c7.getContext('2d')
  let c10 = document.createElement('Canvas');
  let cxt10 = c10.getContext('2d')
  let revocationData = {}
  let revocationList = []
  let revocationNum = 0
  thisboard_div.appendChild(c)
  thisboard_div.appendChild(c2)
  thisboard_div.appendChild(c3)
  thisboard_div.appendChild(c4)
  thisboard_div.appendChild(c5)
  thisboard_div.appendChild(c7)
  thisboard_div.appendChild(c6)
  thisboard_div.appendChild(c10)

  let baizi = document.getElementById('baizi')
  let heizi = document.getElementById('heizi')

  let hei1 = document.getElementById('hei1')
  let bai1 = document.getElementById('bai1')
  let hei2 = document.getElementById('hei2')
  let bai2 = document.getElementById('bai2')
  let hei3 = document.getElementById('hei3')
  let bai3 = document.getElementById('bai3')
  let hei4 = document.getElementById('hei4')
  let bai4 = document.getElementById('bai4')

  let judgeBool = false //是否显示AI形势
  let AiGiveStep = new Array() //AI形势保存数据的数组
  let AiGiveStepBool = false //是否显示AI支招
  let rectboardArray = new Array() //AI支招保存数据的数组
  let Board = new Array() // 最终用于呈现的数组...
  let NumbericBoard = new Array()
  let WriteCount = 2 // 0 不显示手数  1 显示所有手数  2 显示最后一手
  let StepSize = 0 // 全局用于表示格子的宽度(yige)
  //let BoardType = 1; // 1 普通棋盘  2 作业棋盘，可能只会显示其中的部分  3 标记死子的棋盘  4 新的下棋
  let RowCount = 0 // 棋盘横向要显示的格子数(heng)
  let ColCount = 0 // 棋盘纵向要显示的格子数(shu)
  let BoardWidth = 0 // 整个棋盘区域的宽度（通过canvas获取传过来）
  let LeftIndex = 0 // 棋盘的左边起点
  let RightIndex = 0 // 棋盘的右边终点
  let TopIndex = 0 // 棋盘的上边起点
  let BottomIndex = 0 // 棋盘的下边终点
  let SelfPu = new TheQipuManager.TAliasQipu()
  let SizeOfBoard = 0 //qipandaxiao
  let xzshang = 0 //未变
  let xzzuo = 0 //未变
  let HeightOfBoard = 0 //棋盘画布宽
  let WidthOfBoard = 0 //棋盘画布高
  let ShowCol = true //是否显示棋盘刻度（qipan）
  let xCol //画棋子横坐标
  let yCol //画棋子纵坐标
  let tmpX //画方框临时变量
  let tmpY //画方框临时变量
  let isDrawBlock = true //是否显示黑色方格
  let drawBlockNum = 0 //控制画方格变量
  let isAddVary = true //是否添加变化
  let isMarkPic = false //是否进入标记模式
  let MarkType = '' //当前是画什么
  let EngStrNum = 0 //记录画第几个英文字母
  let CanStepIn = true // 当前是否可以落子
  let MuBoard // 形式判断后的目对应的数组
  let FinalBoard // 形式判断后的最终数组
  let NowOnSlider = false
  let isShowVioce = true
  let StartCount = 0 // 从第几手开始显示数据...
  let thisAn = new Array()
  let CanShowIndicator = true // 是否可以显示Indicator...

  //获取棋盘宽高信息
  function getwh() {
    WidthOfBoard = thisboard_div.offsetWidth
    HeightOfBoard = thisboard_div.offsetHeight
    // //console.log("WidthOfBoard=="+WidthOfBoard);

    if (WidthOfBoard >= HeightOfBoard) {
      SizeOfBoard = HeightOfBoard
    } else {
      SizeOfBoard = WidthOfBoard
    }

    BoardWidth = SizeOfBoard

    SizeOfBoard = parseInt(SizeOfBoard, 10)

    cxt.clearRect(0, 0, BoardWidth, BoardWidth)

    c.width = SizeOfBoard
    c.height = SizeOfBoard
    c2.width = SizeOfBoard
    c2.height = SizeOfBoard
    c3.width = SizeOfBoard
    c3.height = SizeOfBoard
    c4.width = SizeOfBoard
    c4.height = SizeOfBoard
    c5.width = SizeOfBoard
    c5.height = SizeOfBoard
    c6.width = SizeOfBoard
    c6.height = SizeOfBoard
    c7.width = SizeOfBoard
    c7.height = SizeOfBoard
    c10.width = SizeOfBoard
    c10.height = SizeOfBoard
    if (ShowCol) {
      StepSize = SizeOfBoard / (parseInt(BoardSize, 10) + 1)
    } else {
      StepSize = SizeOfBoard / parseInt(BoardSize, 10)
    }
    // StepSize = SizeOfBoard / (parseInt(BoardSize, 10) + 1);

    InitBoard()
    SelfDraw()
    SelfPu.SetBoardSize(BoardSize)
  }

  // 初始化棋盘，画底图，画线，画星位
  function InitBoard() {
    cxt.clearRect(0, 0, BoardWidth, BoardWidth)
    ShowScale()
    let mm = 0.5 // 划线用参数？
    let mf = 1 - 0.5
    if (BoardType != 2) {
      // 普通棋盘，显示所有的路数
      RowCount = BoardSize
      ColCount = BoardSize
      LeftIndex = 0
      RightIndex = BoardSize - 1
      TopIndex = 0
      BottomIndex = BoardSize - 1
      // //console.log("LeftIndex" + LeftIndex)
    } else {
      // 作业棋盘，可能只显示部分的路数

      if (BoardSize - 4 > RightIndex) {
        RightIndex = RightIndex + 2
      } else {
        RightIndex = BoardSize - 1
      }

      if (3 < LeftIndex) {
        LeftIndex = LeftIndex - 2
      } else {
        LeftIndex = 0
      }

      if (BoardSize - 4 > BottomIndex) {
        BottomIndex = BottomIndex + 2
      } else {
        BottomIndex = BoardSize - 1
      }

      if (3 < TopIndex) {
        TopIndex = TopIndex - 2
      } else {
        TopIndex = 0
      }

      RowCount = RightIndex - LeftIndex + 1
      ColCount = BottomIndex - TopIndex + 1
      //alert('TopIndex'+TopIndex+'BottomIndex'+BottomIndex+'xzshang'+xzshang);
      let diff = 0 //横竖的差值（cha）

      if (RowCount >= ColCount) {
        if (ShowCol) {
          StepSize = SizeOfBoard / (RowCount + 1)
        } else {
          StepSize = SizeOfBoard / RowCount
        }
        diff = RowCount - ColCount
        ColCount = RowCount
        if (TopIndex <= diff) {
          BottomIndex = BottomIndex + diff - TopIndex
          TopIndex = 0
        } else if (BottomIndex + diff >= BoardSize - 1) {
          TopIndex = TopIndex + BoardSize - BottomIndex - diff - 1
          BottomIndex = BoardSize - 1
        } else {
          TopIndex = TopIndex - 1
          BottomIndex = BottomIndex + diff - 1
        }
      } else {
        if (ShowCol) {
          StepSize = SizeOfBoard / (ColCount + 1)
        } else {
          StepSize = SizeOfBoard / ColCount
        }

        diff = ColCount - RowCount
        RowCount = ColCount
        if (LeftIndex <= diff) {
          RightIndex = RightIndex + diff - LeftIndex
          LeftIndex = 0
        } else if (RightIndex + diff >= BoardSize - 1) {
          LeftIndex = LeftIndex + BoardSize - RightIndex - diff - 1
          RightIndex = BoardSize - 1
        } else {
          LeftIndex = LeftIndex - 1
          RightIndex = RightIndex + diff - 1
        }
      }
      xzshang = TopIndex - 0
      xzzuo = LeftIndex - 0
    }
    //画星位（19,17,15路）
    let tmpNum = 0
    let middleIndex = (parseInt(BoardSize) + 1) / 2
    BoardSize = parseInt(BoardSize)
    if (BoardSize >= 15) {
      switch (BoardSize) {
        case 19:
          tmpNum = 6
          break
        case 17:
          tmpNum = 5
          break
        case 15:
          tmpNum = 4
          break
      }
      if (LeftIndex < 5) {
        for (let m = 4; m < RightIndex; m = m + tmpNum) {
          if (TopIndex < 5) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < middleIndex + 1) {
            for (let n = middleIndex; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < BoardSize - 2) {
            for (let n = BoardSize - 3; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          }
        }
      } else if (LeftIndex < middleIndex + 1) {
        for (let m = middleIndex + 1; m <= RightIndex; m = m + tmpNum) {
          if (TopIndex < 5) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < middleIndex + 1) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < BoardSize - 2) {
            let n = BoardSize - 3
            drawArc(m, n)
          }
        }
      } else if (LeftIndex < BoardSize - 2) {
        for (let m = BoardSize - 3; m <= BottomIndex; m = m + tmpNum) {
          if (TopIndex < 5) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < middleIndex + 1) {
            for (let n = middleIndex + 1; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < BoardSize - 2) {
            let n = BoardSize - 3
            drawArc(m, n)
          }
        }
      }
    }
    //13路
    if (BoardSize == 13) {
      tmpNum = 6
      if (LeftIndex < 5) {
        let num = 0
        for (let m = 4; m < RightIndex; m = m + tmpNum) {
          if (TopIndex < 5) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              num = num + 1
              drawArc(m, n)
              if (num == 4) {
                drawArc(middleIndex, middleIndex)
              }
            }
          } else if (TopIndex < middleIndex + 1) {
            let n = middleIndex
            drawArc(n, n)
          } else if (TopIndex < BoardSize - 2) {
            for (let n = BoardSize - 3; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          }
        }
      } else if (LeftIndex < middleIndex + 1) {
        for (let m = middleIndex; m <= RightIndex; m = m + tmpNum) {
          if (TopIndex < middleIndex + 1) {
            //console.log("@#!");
          }
        }
      } else if (LeftIndex < BoardSize - 2) {
        for (let m = BoardSize - 3; m <= BottomIndex; m = m + tmpNum) {
          if (TopIndex < 5) {
            for (let n = 4; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < BoardSize - 2) {
            let n = BoardSize - 3
            drawArc(m, n)
          }
        }
      }
    }
    //7,9,11路
    if (BoardSize >= 9 && BoardSize <= 11) {
      switch (BoardSize) {
        case 11:
          tmpNum = 6
          break
        case 9:
          tmpNum = 4
          break
        case 7:
          tmpNum = 2
          break
      }

      if (LeftIndex < 4) {
        let num = 0
        for (let m = 3; m < RightIndex; m = m + tmpNum) {
          if (TopIndex < 4) {
            for (let n = 3; n <= BottomIndex + 1; n = n + tmpNum) {
              num = num + 1
              drawArc(m, n)
              if (num == 4) {
                drawArc(middleIndex, middleIndex)
              }
            }
          } else if (TopIndex < middleIndex + 1) {
            let n = middleIndex
            drawArc(n, n)
          } else if (TopIndex < BoardSize - 1) {
            for (let n = BoardSize - 2; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          }
        }
      } else if (LeftIndex < middleIndex) {
        for (let m = middleIndex; m <= RightIndex; m = m + tmpNum) {
          if (TopIndex < middleIndex + 1) {
            let n = middleIndex
            drawArc(n, n)
          }
        }
      } else if (LeftIndex < BoardSize - 1) {
        for (let m = BoardSize - 2; m <= BottomIndex; m = m + tmpNum) {
          if (TopIndex < 4) {
            for (let n = 3; n <= BottomIndex + 1; n = n + tmpNum) {
              drawArc(m, n)
            }
          } else if (TopIndex < BoardSize - 1) {
            let n = BoardSize - 2
            drawArc(m, n)
          }
        }
      }
    }

    let Offset = 0
    if (!ShowCol) {
      Offset = 0.5 * StepSize
    }

    //划棋盘线
    cxt.beginPath()
    for (let m = 1; m < RowCount + 1; m++) {
      cxt.lineWidth = mm
      cxt.strokeStyle = '#000000'
      cxt.moveTo(Math.floor(m * StepSize - Offset) + mf, Math.floor(StepSize - Offset) + mf)
      cxt.lineTo(
        Math.floor(m * StepSize - Offset) + mf,
        Math.floor(ColCount * StepSize - Offset) + mf
      )
      cxt.moveTo(Math.floor(StepSize - Offset) + mf, Math.floor(m * StepSize - Offset) + mf)
      cxt.lineTo(
        Math.floor(RowCount * StepSize - Offset) + mf,
        Math.floor(m * StepSize - Offset) + mf
      )
    }
    cxt.stroke()
    cxt.beginPath()

    if (BottomIndex != BoardSize - 1) {
      for (let m = 1; m < RowCount + 1; m++) {
        cxt.lineWidth = mm
        cxt.strokeStyle = '#000000'
        cxt.moveTo(
          Math.floor(m * StepSize - Offset) + mf,
          Math.floor(ColCount * StepSize - Offset) + mf
        )
        cxt.lineTo(
          Math.floor(m * StepSize - Offset) + mf,
          Math.floor((ColCount + 0.3 - Offset) * StepSize) + mf
        )
      }
    }

    if (TopIndex != 0) {
      for (let m = 1; m < RowCount + 1; m++) {
        cxt.lineWidth = mm
        cxt.strokeStyle = '#000000'
        cxt.moveTo(Math.floor(m * StepSize - Offset) + mf, Math.floor(0.7 * StepSize - Offset) + mf)
        cxt.lineTo(Math.floor(m * StepSize - Offset) + mf, Math.floor(StepSize - Offset) + mf)
      }
    }

    if (LeftIndex != 0) {
      for (let m = 1; m < ColCount + 1; m++) {
        cxt.lineWidth = mm
        cxt.strokeStyle = '#000000'
        cxt.moveTo(Math.floor(0.7 * StepSize - Offset) + mf, Math.floor(m * StepSize - Offset) + mf)
        cxt.lineTo(Math.floor(StepSize - Offset) + mf, Math.floor(m * StepSize - Offset) + mf)
      }
    }

    if (RightIndex != BoardSize - 1) {
      for (let m = 1; m < ColCount + 1; m++) {
        cxt.lineWidth = mm
        cxt.strokeStyle = '#000000'
        cxt.moveTo(
          Math.floor(RowCount * StepSize - Offset) + mf,
          Math.floor(m * StepSize - Offset) + mf
        )
        cxt.lineTo(
          Math.floor((RowCount + 0.3) * StepSize - Offset) + mf,
          Math.floor(m * StepSize - Offset) + mf
        )
      }
    }

    cxt.stroke()
    // cxt.draw();

    // 		if (type == 1) {
    // 		    heizisd.style.width = StepSize + 'px';
    // 		    heizisd.style.height = StepSize + 'px';
    // 		    baizisd.style.width = StepSize + 'px';
    // 		    baizisd.style.height = StepSize + 'px';
    // 		}

    RowCount = RightIndex - LeftIndex + 1
    ColCount = BottomIndex - TopIndex + 1
    //画星位-
    function drawArc(m, n) {
      let mf = 0.5
      let Offset = 0
      if (!ShowCol) {
        Offset = 0.5 * StepSize
      }
      let _x = Math.floor((m - xzzuo) * StepSize - Offset) + mf
      let _y = Math.floor((n - xzshang) * StepSize - Offset) + mf
      let _r = StepSize / 8
      cxt.beginPath()
      cxt.arc(_x, _y, _r, 0, Math.PI * 2, true)
      cxt.closePath()
      cxt.fillStyle = '#000000'
      cxt.fill()
    }
    //改变是否显示刻度（暂时未使用）
    // function changeScale() {
    // 	if (ShowCol == false) {
    // 		ShowCol = true;
    // 	} else {
    // 		ShowCol = false;
    // 	}
    // }
  }

  //改变是否显示刻度
  function changeScale() {
    //隱藏刻度
    //console.log("changeScale");
    cxt5.clearRect(0, 0, BoardWidth, BoardWidth)
    if (ShowCol == true) {
      ShowCol = false
      StepSize = SizeOfBoard / parseInt(BoardSize, 10)
    } else {
      ShowCol = true
      StepSize = SizeOfBoard / (parseInt(BoardSize, 10) + 1)
    }
    //console.log("@1 StepSize = ", StepSize);
    InitBoard()
    SelfDraw()
  }
  function setShowScale(bool) {
    cxt5.clearRect(0, 0, BoardWidth, BoardWidth)
    ShowCol = bool
    if (ShowCol == true) {
      StepSize = SizeOfBoard / (parseInt(BoardSize, 10) + 1)
    } else {
      StepSize = SizeOfBoard / parseInt(BoardSize, 10)
    }
    InitBoard()
    SelfDraw()
  }
  //显示刻度
  function ShowScale() {
    //将这个方法单独拿出来(guo)
    if (ShowCol == true) {
      let a = new Array(
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T'
      )
      let b = new Array(
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19'
      )
      for (let g = 0; g < BoardSize; g++) {
        cxt.font = Math.ceil(StepSize * 0.5) + 'px'
        cxt.textAlign = 'center'
        cxt.textBaseline = 'middle'
        cxt.fillStyle = '#000000'
        cxt.font = StepSize * 0.65 + 'px'
        cxt.fillText(a[g], (g + 1) * StepSize, 0.4 * StepSize)
        if (g < 9) {
          cxt.font = Math.ceil(StepSize * 0.65) + 'px'
        } else {
          cxt.font = Math.ceil(StepSize * 0.55) + 'px'
        }
        cxt.textAlign = 'center'
        cxt.textBaseline = 'middle'
        cxt.fillStyle = '#000000'
        cxt.fillText(b[g], 0.2 * StepSize, (g + 1) * StepSize)
      }
      // cxt4.draw();
    }
  }

  function getEmo(_color, x, y) {
    let emoid = 0
    let tmpqizi = 0

    if (thisAn.length <= 0) {
      if (_color == 1) {
        return heizi
      } else {
        return baizi
      }
    }

    let lVal = thisAn[x][y]
    if (lVal > 0 && _color == 2) {
      emoid = -1
    } else if (lVal < 0 && _color == 1) {
      emoid = -1
    } else {
      emoid = Math.floor(Math.abs(lVal) / 10)
    }

    if (_color == 1) {
      if (emoid == -1) {
        tmpqizi = hei1
      } else if (emoid <= 3) {
        tmpqizi = hei2
      } else if (emoid > 3 && emoid <= 6) {
        tmpqizi = hei3
      } else if (emoid > 6) {
        tmpqizi = hei4
      }
    } else {
      if (emoid == -1) {
        tmpqizi = bai1
      } else if (emoid <= 3) {
        tmpqizi = bai2
      } else if (emoid > 3 && emoid <= 6) {
        tmpqizi = bai3
      } else if (emoid > 6) {
        tmpqizi = bai4
      }
    }

    return tmpqizi
  }
  // 非标机时(qgl)
  function changeMarkPic() {
    isMarkPic = false
    EngStrNum = 0
  }
  // 画棋盘上的棋子，各种线等
  function SelfDraw(number) {
    let x = 0
    let y = 0
    let arr
    let tmpqizi
    heizi.onload = function () {
      baizi.onload = function () {
        drawStone()
      }
    }
    if (baizi.complete && heizi.complete) {
      drawStone()
    }

    function drawStone() {
      let offset = 0
      if (!ShowCol) {
        offset = 0.5
      }
      SelfPu.CopyBoard(Board)
      cxt3.clearRect(0, 0, BoardWidth, BoardWidth)
      for (x = 0; x < BoardSize; x++) {
        for (y = 0; y < BoardSize; y++) {
          if (FinalBoard != null) {
            if (FinalBoard[y][x] == 0) {
              cxt3.globalAlpha = 0.5
            } else {
              cxt3.globalAlpha = 1
            }
          } else {
            cxt3.globalAlpha = 1
          }

          if (Board[x][y] == 1) {
            tmpqizi = heizi
            if (thisAn.length > 0) {
              tmpqizi = getEmo(1, y, x)
            }
            cxt3.drawImage(
              tmpqizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.06,
              StepSize * 1.06
            )
          } else if (Board[x][y] == 2) {
            tmpqizi = baizi
            if (thisAn.length > 0) {
              tmpqizi = getEmo(2, y, x)
            }
            cxt3.drawImage(
              tmpqizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.06,
              StepSize * 1.06
            )
          }
          cxt3.globalAlpha = 1
          if (MuBoard != null) {
            if (MuBoard[y][x] == 1) {
              cxt3.fillStyle = 'black'
              cxt3.beginPath()
              cxt3.rect(
                (x - xzzuo - offset) * StepSize + StepSize * 0.75,
                (y - xzshang - offset) * StepSize + StepSize * 0.75,
                StepSize * 0.5,
                StepSize * 0.5
              )
              cxt3.closePath()
              cxt3.fill()
            } else if (MuBoard[y][x] == 2) {
              cxt3.fillStyle = 'white'
              cxt3.beginPath()
              cxt3.rect(
                (x - xzzuo - offset) * StepSize + StepSize * 0.75,
                (y - xzshang - offset) * StepSize + StepSize * 0.75,
                StepSize * 0.5,
                StepSize * 0.5
              )
              //cxt3.arc((x - xzzuo) * StepSize + StepSize*0.75, (y - xzshang) * StepSize + StepSize*0.75,StepSize * 0.47,0,2 * Math.PI,true);
              cxt3.closePath()
              cxt3.fill()
            }
          }
        }
      }
      // cxt2.fill();
      //cxt3.draw();

      if (BoardType == 3) {
        DrawOutDeadStone()
      } else {
        DrawOutNumberic()
      }
      if (BoardType != 2) {
        DrawOutVar()
      }
      if (judgeBool) {
        setRectByData()
      }
      if (AiGiveStepBool) {
        setAiGiveStepData()
      }
      // if (isMarkPic == true) {
      //   DrawOutGraphic();
      // }
      DrawOutGraphic()

      let tmpArr = SelfPu.GetStepInfo()
      if (tmpArr == null) {
        return
      }
      //调用添加变化方法
      if (isAddVary == true) {
        drawVaryPic()
      }
      //显示当前变化图
      function drawVaryPic() {
        // arr = SelfPu.FormVaryPics();
        arr = SelfPu.FormCurrentVary()
        that.MsgFromBoard(7, arr)
        return arr
      }
      drawComment()
      drawAudio()
      //显示当前评论
      function drawComment() {
        // //console.log("I'm in drawComment：");
        let str = SelfPu.getCurrentComment()
        // //console.log("当前我在vgboard里面获得的str:" + str);
        if (str == null || str == '') {
          str = ''
          that.MsgFromBoard(8, ' ')
        } else {
          that.MsgFromBoard(8, str)
        }
      }
      //显示当前声音
      function drawAudio() {
        let str = SelfPu.getCurrentVoice()
        if (str == null) {
          str = ''
          that.MsgFromBoard(9, str)
        } else {
          that.MsgFromBoard(9, str)
        }
      }
      // that.MsgFromBoard(7,arr);
      that.MsgFromBoard(3, [tmpArr[2], tmpArr[3]])
      if (!NowOnSlider) {
        that.MsgFromBoard(4, tmpArr[0])
      } else {
        that.MsgFromBoard(13, tmpArr[0])
      }
      that.MsgFromBoard(6, tmpArr[1])
    }
    if (MuBoard == null && FinalBoard == null) {
      if (store.state.showJudge == 3 && store.state.remainJudge < 2) {
        store.state.showJudge = 2
      } else if (store.state.showJudge == 3 && store.state.remainJudge == 2) {
        store.state.showJudge = 1
      }
    }
  }

  /**
   * 获取95字节...
   */
  function GetZippedBoard() {
    if (SelfPu) {
      return SelfPu.GetZippedBoard()
    }
  }

  function drawBlock(x, y, blockWith) {
    //console.log('进来了 飒飒飒飒撒');
    // drawBlockNum = drawBlockNum + 1;
    // let xWidth1 = Math.round(x - 0.5 * blockWith);
    // let xWidth2 = Math.round(x + 0.5 * blockWith);
    // let yWidth1 = Math.round(y - 0.5 * blockWith);
    // let yWidth2 = Math.round(y + 0.5 * blockWith);
    // SelfPu.CopyBoard(Board);
    let drawX = (x + 1) * blockWith - 0.3 * blockWith
    let drawY = (y + 1) * blockWith - 0.3 * blockWith
    cxt2.beginPath()
    if (isDrawBlock == true && drawBlockNum == 1) {
      //画棋子
      isDrawBlock = false
      //console.log("画了一个棋子");
    } else if (isDrawBlock == true && drawBlockNum == 2) {
      //画了别的地方（还是画方框）
      // cxt2.clearRect(x,y,drawX,drawY);
      cxt2.clearRect(0, 0, BoardWidth, BoardWidth) //每次画方框之前，先将之前画的方框删除(guo)
      // cxt2.rect(drawX, drawY, 2 * blockWith * 0.3, 2 * blockWith * 0.3);
      cxt2.fillStyle = 'black'
      cxt2.fill()
      // cxt2.draw();
      // cxt2.stroke();
      isDrawBlock = true
      //console.log("画了一个方框");
      isShowVioce = true
    }
    cxt2.closePath()
  }
  /*
     判断显示手数的坐标WriteCount：0.显示红色块标记; 1.显示全部手；2.显示当前手
  */
  function decideWriteCount() {
    WriteCount = WriteCount + 1
    if (WriteCount > 2) {
      WriteCount = 0
    }
  }

  /**
   * 用于直接设置显示手数...
   * inValue int  0 1 2
   */
  function setWriteCount(inValue) {
    if (inValue < 0) {
      inValue = 0
    }
    if (inValue > 2) {
      inValue = 2
    }
    WriteCount = inValue
    SelfDraw()
  }
  /*
     DrawOutNumberic
     用于显示手数
  */
  function DrawOutNumberic() {
    let tmpArr
    let x1
    let y1
    let kk = 0
    let fillStyle = ''
    let OriWriteCount = WriteCount
    if (SelfPu.CheckInSub() == true) {
      WriteCount = 1
    }

    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }

    cxt4.clearRect(0, 0, BoardWidth, BoardWidth)
    if (WriteCount == 0) {
      tmpArr = SelfPu.GetCurrentStepPosition()
      if (tmpArr.length != 3) {
        return
      }
      x1 = tmpArr[0]
      y1 = tmpArr[1]
      if (x1 < 0 || y1 < 0) {
        return
      }
      cxt4.beginPath()
      cxt4.rect(
        (x1 - xzzuo + 0.85 - offset) * StepSize,
        (y1 - xzshang + 0.85 - offset) * StepSize,
        0.3 * StepSize,
        0.3 * StepSize
      )
      cxt4.fillStyle = 'red'
      cxt4.fill()
      cxt4.closePath()
      //cxt3.draw();
      return
    }

    if (WriteCount == 1) {
      SelfPu.CopyNumbericBoard(NumbericBoard)
      //console.log(NumbericBoard);
      for (let i = 0; i < BoardSize; i++) {
        for (let j = 0; j < BoardSize; j++) {
          if (NumbericBoard[i][j] > 0) {
            kk = NumbericBoard[i][j]
            //console.log(kk);
            //console.log(StartCount);
            //debugger
            if (StartCount > 0) {
              kk = kk - StartCount
              if (kk <= 0) {
                continue
              }
            }
            if (Board[i][j] == 1) {
              fillStyle = '#FFFFFF'
            } else {
              fillStyle = '#000000'
            }
            if (kk >= 100) {
              cxt4.font = Math.ceil(StepSize * 0.5) + 'px Arial'
            } else {
              cxt4.font = Math.ceil(StepSize * 0.6) + 'px Arial'
            }
            cxt4.textAlign = 'center'
            cxt4.textBaseline = 'middle'
            cxt4.fillStyle = fillStyle
            //console.log(kk);
            //debugger
            cxt4.fillText(
              '' + kk + '',
              (i - xzzuo + 1 - offset) * StepSize,
              (j - xzshang + 1.05 - offset) * StepSize
            )
          }
        }
      }
      WriteCount = OriWriteCount
    } else if (WriteCount == 2) {
      tmpArr = SelfPu.GetCurrentStepPosition()
      if (tmpArr.length != 3) {
        return
      }
      kk = tmpArr[2]
      if (kk <= 0) {
        return
      }
      x1 = tmpArr[0]
      y1 = tmpArr[1]
      if (x1 < 0 || y1 < 0) {
        return
      }
      if (Board[x1][y1] == 1) {
        fillStyle = '#FFFFFF'
      } else {
        fillStyle = '#000000'
      }

      if (kk >= 100) {
        cxt4.font = Math.ceil(StepSize * 0.5) + 'px Arial'
      } else {
        cxt4.font = Math.ceil(StepSize * 0.6) + 'px Arial'
      }
      // //console.log("kk:", kk)
      // //console.log("fontsize:", cxt4.font);
      // //console.log("StepSize:", StepSize);
      // cxt4.textAlign = "center";
      // cxt4.textBaseline = "middle";
      // cxt4.fillStyle = fillStyle;
      // cxt4.fillText("" + kk + "", (x1 - xzzuo + 1 - offset) * StepSize, (y1 - xzshang + 1.05 - offset) * StepSize);
      cxt4.textAlign = 'center'
      cxt4.textBaseline = 'middle'
      cxt4.fillStyle = fillStyle
      cxt4.fillText(
        '' + kk + '',
        (x1 - xzzuo + 1 - offset) * StepSize,
        (y1 - xzshang + 1.05 - offset) * StepSize
      )
      cxt4.beginPath()
      cxt4.moveTo(
        (x1 - xzzuo + 0.5 - offset) * StepSize,
        (y1 - xzshang + 0.63 - offset) * StepSize - StepSize * 0.1
      )
      cxt4.lineTo(
        (x1 - xzzuo + 0.55 - offset) * StepSize - StepSize * 0.05,
        (y1 - xzshang + 0.72 - offset) * StepSize + StepSize * 0.05
      )
      cxt4.lineTo(
        (x1 - xzzuo + 0.7 - offset) * StepSize + StepSize * 0.05,
        (y1 - xzshang + 0.48 - offset) * StepSize + StepSize * 0.05
      )
      cxt4.fillStyle = 'red'
      cxt4.closePath()
      cxt4.fill()
    }
  }

  function setJudgeBool(bool) {
    judgeBool = bool
    //console.log("改变了judgebool：", bool);
    if (judgeBool == false) {
      cxt5.clearRect(0, 0, BoardWidth, BoardWidth)
      rectboardArray = []
    }
  }

  function saveRectData(data) {
    rectboardArray = data
  }

  function setRectByData() {
    let chessWidth = BoardSize
    cxt5.clearRect(0, 0, BoardWidth, BoardWidth)
    for (let idx = 0; idx < chessWidth; idx++) {
      for (let idy = 0; idy < chessWidth; idy++) {
        let width = rectboardArray[idy][idx]
        let color
        if (width < 0) {
          color = 'rgba(255,255,255,180)'
        } else {
          color = 'rgba(0,0,0,180)'
        }

        if (Math.abs(width) > 20 && Board[idx][idy] == 0) {
          let x1
          let y1
          let offset
          if (ShowCol) {
            x1 = Math.round(idx)
            y1 = Math.round(idy)
            x1 = x1 - 1
            y1 = y1 - 1
            offset = -1
          } else {
            x1 = Math.round(idx + 0.5)
            y1 = Math.round(idy + 0.5)
            x1 = x1 - 1
            y1 = y1 - 1
            offset = 0.5
          }
          cxt5.beginPath()
          x1 += LeftIndex //落子横坐标
          y1 += TopIndex //落子纵坐标
          cxt5.fillStyle = color
          let stepsize = (StepSize / 2) * (Math.abs(width) / 100)
          let x = (x1 - offset + 1) * StepSize - stepsize / 2
          let y = (y1 - offset + 1) * StepSize - stepsize / 2
          cxt5.fillRect(x, y, stepsize, stepsize)
        }
      }
    }
  }

  function setAiGiveStepBool(bool) {
    AiGiveStepBool = bool
    //console.log("改变了AiGiveStepBool：", bool);
    if (AiGiveStepBool == false) {
      cxt7.clearRect(0, 0, BoardWidth, BoardWidth)
      AiGiveStep = []
    }
  }

  function saveAiGiveStepData(data) {
    AiGiveStep = data
  }

  function setAiGiveStepData() {
    cxt7.clearRect(0, 0, BoardWidth, BoardWidth)
    let mf = 0.5
    let Offset = 0
    if (!ShowCol) {
      Offset = 0.5 * StepSize
    }

    let _r = StepSize / 2.5
    for (let i = 0; i < AiGiveStep.length; i++) {
      let m = Math.abs(AiGiveStep[i][0] + 1)
      let n = Math.abs(AiGiveStep[i][1] + 1)
      let ops = AiGiveStep[i][2]
      let _y = Math.floor(m * StepSize - Offset) + mf
      let _x = Math.floor(n * StepSize - Offset) + mf
      if (Board[AiGiveStep[i][0]][AiGiveStep[i][1]] != 0) {
        // //console.log('stop')
        // //console.log(AiGiveStep[i][0], AiGiveStep[i][1])
        continue
      }
      cxt7.beginPath()
      let color = `rgba(0,128,0,${ops / 100})`
      cxt7.fillStyle = color
      cxt7.arc(_y, _x, _r, 0, Math.PI * 2, false)
      cxt7.closePath()
      cxt7.fill()
    }
  }
  /**
   * 显示鼠标移动的时候的小方块...
   * @param {*} x
   * @param {*} y
   */
  function ShowIndicator(x, y) {
    // //console.log("XXXXX>>>>", x, ", ", y, "xzshang=" + StepSize)
    cxt5.clearRect(0, 0, BoardWidth, BoardWidth)

    if (!CanShowIndicator) {
      return
    }
    let x1
    let y1

    let offset

    if (ShowCol) {
      x1 = Math.round(x / StepSize)
      y1 = Math.round(y / StepSize)
      x1 = x1 - 1
      y1 = y1 - 1
      offset = 0
    } else {
      x1 = Math.round(x / StepSize + 0.5)
      y1 = Math.round(y / StepSize + 0.5)
      x1 = x1 - 1
      y1 = y1 - 1
      offset = 0.5
    }
    if (judgeBool) {
      setRectByData()
    }
    if (AiGiveStepBool) {
      setAiGiveStepData()
    }
    // //console.log("the size = ", x, ", ", y);

    if (x1 < 0 || y1 < 0) {
      return
    }
    if (x1 >= BoardSize || y1 >= BoardSize) {
      return
    }

    if (Board[x1][y1] != 0) {
      return
    }

    cxt5.beginPath()

    x1 = x1 + LeftIndex //落子横坐标
    y1 = y1 + TopIndex //落子纵坐标

    cxt5.fillStyle = 'black'
    cxt5.fillRect(
      (x1 - LeftIndex + 0.75 - offset) * StepSize,
      (y1 - TopIndex + 0.75 - offset) * StepSize,
      0.5 * StepSize,
      0.5 * StepSize
    )

    // //console.log("x==="+yCol);
    //	cxt5.fill();
    cxt5.closePath()
  }

  /*
    DrawOutDeadStone
  */
  function DrawOutDeadStone() {
    if (BoardType != 3) {
      return
    }
    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }
    //console.log("draw dead stone");
    let DrawBoard = new Array()
    for (let i = 0; i < 19; i++) {
      DrawBoard[i] = new Array()
      for (let j = 0; j < 19; j++) {
        DrawBoard[i][j] = 0
      }
    }
    SelfPu.CopyDeadBoard(DrawBoard)
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        if (DrawBoard[i][j] > 0) {
          cxt4.fillStyle = 'red'
          cxt4.fillRect(
            (i - xzzuo + 1 - offset) * StepSize - StepSize / 4,
            (j - xzshang + 1 - offset) * StepSize - StepSize / 4,
            StepSize / 2,
            StepSize / 2
          )
        }
      }
    }
  }

  /*
     DrawOutVar
     一个点如果有分支的话，这里要显示ABCD
  */
  function DrawOutVar() {
    if (SelfPu == null) {
      //cxt4.draw();
      return
    }
    let tmpArr = SelfPu.GetCurrentSub()
    if (tmpArr == null) {
      //cxt4.draw();
      return
    }
    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }
    let x1 = 0
    let y1 = 0
    let S = ''
    cxt4.save()
    for (let i = 0; i < tmpArr.length; i++) {
      x1 = tmpArr[i][0]
      y1 = tmpArr[i][1]
      cxt4.font = Math.ceil(StepSize * 0.8) + 'px'
      cxt4.textAlign = 'center'
      cxt4.textBaseline = 'middle'
      cxt4.fillStyle = 'blue'
      S = String.fromCharCode(65 + i)
      cxt4.fillText(
        S,
        (x1 - xzzuo + 1 - offset) * StepSize,
        (y1 - xzshang + 1.05 - offset) * StepSize
      )
    }
  }

  function FreeDrawpicRemote(data) {
    //console.log(data);
    data = JSON.parse(data)
    cxt6.strokeStyle = data.color
    cxt6.lineWidth = data.width
    cxt6.beginPath()

    let arr = data.arr
    for (let i = 0; i < arr.length; i++) {
      arr[i][0] = (arr[i][0] / data.canvasWidth) * c6.width
      arr[i][1] = (arr[i][1] / data.canvasWidth) * c6.width
    }

    if (data.type == 1) {
      revocationData = cxt6.getImageData(0, 0, c6.width, c6.height)
      let startPos = arr[0]
      cxt6.moveTo(startPos[0], startPos[1])
      for (let i = 1; i < arr.length; i++) {
        cxt6.lineTo(arr[i][0], arr[i][1])
      }
      cxt6.stroke()
      locationLinePos = arr[arr.length - 1]
    } else if (data.type == 2) {
      cxt6.moveTo(locationLinePos[0], locationLinePos[1])
      for (let i = 0; i < arr.length; i++) {
        cxt6.lineTo(arr[i][0], arr[i][1])
      }
      cxt6.stroke()
      locationLinePos = arr[arr.length - 1]
    } else if (data.type == 3) {
      if (locationLinePos) {
        cxt6.moveTo(locationLinePos[0], locationLinePos[1])
      } else {
        revocationData = cxt6.getImageData(0, 0, c6.width, c6.height)
        let startPos = arr[0]
        cxt6.moveTo(startPos[0], startPos[1])
      }
      for (let i = 0; i < arr.length; i++) {
        cxt6.lineTo(arr[i][0], arr[i][1])
      }
      cxt6.stroke()
      locationLinePos = null
      revocationList[revocationNum] = revocationData
      revocationNum++
    }
  }
  //自由作画(guo)
  function FreeDrawpic(FreeDrawpicColor = 'red', FreeDrawpicSize = '3') {
    let x = 0 // 鼠标开始移动的位置X
    let y = 0 // 鼠标开始移动的位置Y
    c6.onmousedown = function (event) {
      revocationData = cxt6.getImageData(0, 0, c6.width, c6.height)

      // SetCanShowIndicator(false);
      // SetCanStepIn(false);

      x = event.offsetX // 鼠标落下时的X
      y = event.offsetY // 鼠标落下时的Y
      // let x = event.clientX - c6.offsetLeft;
      // let y = event.clientY - c6.offsetTop;
      cxt6.beginPath()

      cxt6.moveTo(x, y)
      document.onmousemove = function (event) {
        // let x = event.clientX - c6.offsetLeft;
        // let y = event.clientY - c6.offsetTop;
        // cxt6.moveTo(x, y);
        cxt6.putImageData(revocationData, 0, 0)
        cxt6.lineTo(event.offsetX, event.offsetY)
        cxt6.strokeStyle = FreeDrawpicColor
        cxt6.lineWidth = FreeDrawpicSize
        cxt6.stroke()
      }
      // 如果不是c6是document时，revocationNum会计算错误，为什么？
      c6.onmouseup = function () {
        revocationList[revocationNum] = revocationData
        revocationNum++
        cxt6.closePath()
        document.onmousemove = null
        document.onmouseup = null
        // SetCanShowIndicator(true);// 设置光标
        // SetCanStepIn(true);

        event.stopPropagation()
      }
    }
  }
  // 远程作画
  function DrawMypicRemote(data) {
    // //console.log("我画了")
    data = JSON.parse(data)
    //console.log(data);
    let type = data.type
    let url = c6.toDataURL()

    let img = new Image()
    img.src = url
    // cxt6.clearRect(0, 0, c6.width, c6.height);
    cxt6.drawImage(img, 0, 0, c6.width, c6.height)
    cxt6.beginPath()
    let x = (data.startX / data.canvasWidth) * c6.width
    let y = (data.startY / data.canvasWidth) * c6.width
    let eX = (data.endX / data.canvasWidth) * c6.width
    let eY = (data.endY / data.canvasWidth) * c6.width
    if (type == 1) {
      cxt6.fillStyle = 'rgba(103,194,58,0.4)'
      cxt6.fillRect(x, y, eX - x, eY - y)
      cxt6.stroke()
    } else if (type == 2) {
      let rx = (eX - x) / 2
      let ry = (eY - y) / 2
      let r = Math.sqrt(rx * rx + ry * ry)
      cxt6.arc(rx + x, ry + y, r, 0, Math.PI * 2) // 第5个参数默认是false-顺时针
      cxt6.strokeStyle = 'rgb(121,187,255)'
      cxt6.stroke()
      cxt6.fillStyle = 'rgba(121,187,255,0.1)'
      cxt6.fill()
    } else if (type == 3) {
      cxt6.fillStyle = 'rgba(121,187,255,0.1)'
      cxt6.moveTo(x, y)
      cxt6.lineTo(eX, eY)
      cxt6.stroke()
    }
  }
  // 画圆，矩形，直线(guo)
  function DrawMypic(type) {
    let flag = false
    let x = 0 // 鼠标开始移动的位置X
    let y = 0 // 鼠标开始移动的位置Y
    let url = '' // canvas图片的二进制格式转为dataURL格式
    c6.onmousedown = function (e) {
      flag = true
      x = e.offsetX // 鼠标落下时的X
      y = e.offsetY // 鼠标落下时的Y
      url = c6.toDataURL()
      document.onmouseup = function (e) {
        flag = false
        // url = c4.toDataURL(); // 每次 mouseup 都保存一次画布状态
        document.onmousemove = null
        document.onmouseup = null
        // //console.log('我是url的值 飒飒撒',url);
      }
      document.onmousemove = function (e) {
        cxt6.clearRect(0, 0, c6.width, c6.height)

        let img = new Image()
        img.src = url
        cxt6.drawImage(img, 0, 0, c6.width, c6.height)
        cxt6.beginPath()

        if (type == 1) {
          //画矩形
          if (flag) {
            // cxt4.clearRect(0,0,c4.width,c4.height);

            // let img = new Image();
            // img.src = url;
            // cxt4.drawImage(img,0,0,c4.width,c4.height);

            // cxt4.beginPath();
            cxt6.fillStyle = 'rgba(103,194,58,0.4)'
            cxt6.fillRect(x, y, e.offsetX - x, e.offsetY - y)
            cxt6.stroke()
          }
        } else if (type == 2) {
          //画圆
          if (flag) {
            // cxt4.clearRect(0,0,c4.width,c4.height);

            // let img = new Image();
            // img.src = url;
            // cxt4.drawImage(img,0,0,c4.width,c4.height);

            // cxt4.beginPath();
            let rx = (e.offsetX - x) / 2
            let ry = (e.offsetY - y) / 2
            let r = Math.sqrt(rx * rx + ry * ry)
            cxt6.arc(rx + x, ry + y, r, 0, Math.PI * 2) // 第5个参数默认是false-顺时针
            cxt6.strokeStyle = 'rgb(121,187,255)'
            cxt6.stroke()
            cxt6.fillStyle = 'rgba(121,187,255,0.1)'
            cxt6.fill()
          }
        } else if (type == 3) {
          //画直线
          if (flag) {
            // cxt4.clearRect(0,0,c4.width,c4.height);
            // let img = new Image();
            // img.src = url;
            // cxt4.drawImage(img,0,0,c4.width,c4.height);
            // cxt4.beginPath();
            cxt6.moveTo(x, y)
            cxt6.lineTo(e.offsetX, e.offsetY)
            cxt6.stroke()
          }
        }
      }
    }
  }
  /**
   * 清空画布
   */
  function clearCanvas() {
    // //console.log("清空画布")
    cxt6.clearRect(0, 0, c6.width, c6.height)
    revocationData = {}
    revocationList = []
    revocationNum = 0
  }
  /**
   * 撤销
   */
  function revocationCanvas() {
    // //console.log("自由作画撤销")
    if (revocationNum > 0) {
      revocationData = revocationList[revocationNum - 1]
      cxt6.putImageData(revocationData, 0, 0)
      revocationNum--
    }
  }
  /**
   * 关闭画布
   */

  function closeCanvas() {
    // //console.log("关闭画布");
    c6.onmousedown = null
    c6.onmouseup = null
    c6.onmousemove = null
    document.onmousedown = null
    document.onmouseup = null
    document.onmousemove = null
  }

  /**
   * 视频加载时，处理标记
   * @param {[]} arr 标记数组
   * @param {[]} arr2 最后一手
   */
  function DrawOutGraphicVideo(arr, arr2) {
    if (arr2.length > 0) {
      //如果有最后一手，放入arr中
      let newArr = [1]
      arr.push(newArr.concat(arr2))
    }

    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }
    if (SelfPu != null) {
      let RR
      if (arr && Array.isArray(arr)) {
        RR = arr
      }
      // //console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR',RR)
      if (RR == null) {
        // cxt4.draw();
        return
      }
      // //console.log('我进到这里了------', RR);
      for (let i = 0; i < RR.length; i++) {
        let r1 = RR[i][0]
        let r2 = RR[i][1]
        let r3 = RR[i][2]
        if (Board[r2][r3] == 1) {
          cxt4.fillStyle = 'white'
          cxt4.strokeStyle = 'white'
          // cxt4.setFillStyle("white");
          // cxt4.setStrokeStyle("white");
        } else if (Board[r2][r3] == 2) {
          cxt4.fillStyle = 'black'
          cxt4.strokeStyle = 'black'
          // cxt4.setFillStyle("black");
          // cxt4.setStrokeStyle("black");
        } else {
          cxt4.fillStyle = 'blue'
          cxt4.strokeStyle = 'blue'
          // cxt4.setFillStyle("blue");
          // cxt4.setStrokeStyle("blue");
        }

        if (arr2.length > 0 && arr.length - 1 == i) {
          cxt4.fillStyle = 'red'
          cxt4.strokeStyle = 'red'
        }

        if (r1 == 0) {
          // 画圆圈
          cxt4.beginPath()
          cxt4.arc(
            (r2 - xzzuo - offset) * StepSize + StepSize,
            (r3 - xzshang - offset) * StepSize + StepSize,
            StepSize * 0.2,
            0,
            2 * Math.PI,
            true
          )
          cxt4.closePath()
          cxt4.fill()
          // cxt4.draw();
        } else if (r1 == 1) {
          // 画三角形
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize * 0.3
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.2
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.2
          )
          cxt4.closePath()
          cxt4.fill()
        } else if (r1 == 2) {
          // 画十字线
          cxt4.lineWidth = 2
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize * 0.3
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.3
          )
          cxt4.stroke()
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize
          )
          cxt4.stroke()
        } else if (r1 == 3) {
          //画斜十字
          cxt4.lineWidth = 2
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4
          )
          cxt4.stroke()
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4
          )
          cxt4.stroke()
        } else if (r1 == 4) {
          // 画方块
          cxt4.fillRect(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize / 4,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize / 4,
            StepSize / 2,
            StepSize / 2
          )
        } else if (r1 == 5) {
          // 画圆圈，暂时替代下作业的内容
          cxt4.fillStyle = 'red'
          cxt4.beginPath()
          cxt4.arc(
            (r2 - xzzuo - offset) * StepSize + StepSize,
            (r3 - xzshang - offset) * StepSize + StepSize,
            StepSize * 0.2,
            0,
            2 * Math.PI,
            true
          )
          cxt4.closePath()
          cxt4.fill()
        } else {
          // 检查下是不是要画字母
          let zz = /^[a-z]+$/
          //console.log(r1 + "============");
          if (zz.test(r1)) {
            cxt4.textAlign = 'center'
            cxt4.textBaseline = 'middle'
            cxt4.font = Math.ceil(StepSize * 0.7) + 'px Arial'
            cxt4.fillText(
              r1,
              (r2 - xzzuo + 1 - offset) * StepSize,
              (r3 - xzshang + 1 - offset) * StepSize
            )
          }
        }
      }
      // cxt4.draw();
    }
  }

  /*
     DrawOutGraphic
     把当前的三角，等标记画出来
  */
  function DrawOutGraphic() {
    //console.log('==================================================', isMarkPic);
    // if(BoardType != 2 && BoardSize >= 13){
    //ShowScale();
    // }
    //  //console.log('我是selfpu的值----------->',SelfPu)
    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }
    if (SelfPu != null) {
      let RR = SelfPu.GetOutGraphicStr()
      if (RR == null) {
        // cxt4.draw();
        return
      }
      //console.log('我进到这里了------', RR);
      for (let i = 0; i < RR.length; i++) {
        let r1 = RR[i][0]
        let r2 = RR[i][1]
        let r3 = RR[i][2]
        if (Board[r2][r3] == 1) {
          cxt4.fillStyle = 'white'
          cxt4.strokeStyle = 'white'
          // cxt4.setFillStyle("white");
          // cxt4.setStrokeStyle("white");
        } else if (Board[r2][r3] == 2) {
          cxt4.fillStyle = 'black'
          cxt4.strokeStyle = 'black'
          // cxt4.setFillStyle("black");
          // cxt4.setStrokeStyle("black");
        } else {
          cxt4.fillStyle = 'blue'
          cxt4.strokeStyle = 'blue'
          // cxt4.setFillStyle("blue");
          // cxt4.setStrokeStyle("blue");
        }
        if (r1 == 0) {
          // 画圆圈
          cxt4.beginPath()
          cxt4.arc(
            (r2 - xzzuo - offset) * StepSize + StepSize,
            (r3 - xzshang - offset) * StepSize + StepSize,
            StepSize * 0.2,
            0,
            2 * Math.PI,
            true
          )
          cxt4.closePath()
          cxt4.fill()
          // //console.log((r2 - xzzuo - offset) * StepSize + StepSize);
          // //console.log((r3 - xzshang - offset) * StepSize + StepSize);
          // //console.log(StepSize * 0.2);
          // cxt4.draw();
          // //console.log("我画了圆圈");
        } else if (r1 == 1) {
          // 画三角形
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize * 0.3
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.2
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.2
          )
          cxt4.closePath()
          cxt4.fill()
          // //console.log("我画了三角形");
        } else if (r1 == 2) {
          // 画十字线
          cxt4.lineWidth = 2
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize * 0.3
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize,
            (r3 - xzshang + 1 - offset) * StepSize + StepSize * 0.3
          )
          cxt4.stroke()
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + StepSize * 0.3,
            (r3 - xzshang + 1 - offset) * StepSize
          )
          cxt4.stroke()
          // //console.log("我画了十字线");
        } else if (r1 == 3) {
          //画斜十字
          cxt4.lineWidth = 2
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4
          )
          cxt4.stroke()
          cxt4.beginPath()
          cxt4.moveTo(
            (r2 - xzzuo + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4
          )
          cxt4.lineTo(
            (r2 - xzzuo + 1 - offset) * StepSize - (StepSize * 0.3) / 1.4,
            (r3 - xzshang + 1 - offset) * StepSize + (StepSize * 0.3) / 1.4
          )
          cxt4.stroke()
        } else if (r1 == 4) {
          // 画方块
          cxt4.fillRect(
            (r2 - xzzuo + 1 - offset) * StepSize - StepSize / 4,
            (r3 - xzshang + 1 - offset) * StepSize - StepSize / 4,
            StepSize / 2,
            StepSize / 2
          )
          // //console.log("我画了方块");
        } else if (r1 == 5) {
          // 画圆圈，暂时替代下作业的内容
          cxt4.fillStyle = 'red'
          cxt4.beginPath()
          cxt4.arc(
            (r2 - xzzuo - offset) * StepSize + StepSize,
            (r3 - xzshang - offset) * StepSize + StepSize,
            StepSize * 0.2,
            0,
            2 * Math.PI,
            true
          )
          cxt4.closePath()
          cxt4.fill()
        } else {
          // 检查下是不是要画字母
          let zz = /^[a-z]+$/
          // //console.log(r1 + "============");
          if (zz.test(r1)) {
            cxt4.textAlign = 'center'
            cxt4.textBaseline = 'middle'
            cxt4.font = Math.ceil(StepSize * 0.7) + 'px Arial'
            cxt4.fillText(
              r1,
              (r2 - xzzuo + 1 - offset) * StepSize,
              (r3 - xzshang + 1 - offset) * StepSize
            )
          }
        }
      }
      // cxt4.draw();
    }
  }
  /**
   * 获取当前node的KeyStr
   */
  function GetCurrentNodeKeyStr() {
    return SelfPu.GetCurrentNodeKeyStr()
  }

  /*
       回到上一手(guo 补)
    */
  function GotoPreStep() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GotoPreStep()
    SelfDraw()
    PlayDown()
  }

  /*
     下一手(guo 补)
  */
  function GotoNextStep() {
    if (SelfPu == null) {
      return
    }
    let msg = SelfPu.GotoNextStep()
    SelfDraw()
    PlayDown()
    if (BoardType == 2 && msg != null) {
      that.MsgFromBoard(55, msg)
    }
  }
  /*
     上十手(guo 补)
  */
  function GoToPreTenSteps() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GoToPreTenSteps()
    SelfDraw()
    PlayDown()
  }
  /*
     下十手(guo 补)
  */
  function GoToNextTenSteps() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GoToNextTenSteps()
    SelfDraw()
    PlayDown()
  }
  /*
      回到第一手(guo 补)
  */
  function GotoFirstStep() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GotoFirstStep()
    SelfDraw()
    PlayDown()
  }

  /*
     到最后一手(guo 补)
  */
  function GotoLastStep() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GotoLastStep()
    SelfDraw()
    PlayDown()
  }
  /*
     (qgl 补)
  */
  function GotoStepByKeyStr(inKey) {
    if (SelfPu == null) {
      return
    }
    SelfPu.JumpPointByKeyStr(inKey)
    SelfDraw()
    PlayDown()
  }

  /**
   * 设置开始显示手数
   * @param {* int} inValue
   */
  function SetStartCount(inValue) {
    StartCount = inValue
  }

  function SetEndTypeByKeyStr(inKey, value) {
    if (SelfPu == null) {
      return
    }
    SelfPu.SetEndTypeByKeyStr(inKey, value)
    SelfDraw()
    PlayDown()
  }

  function PlayDown() {
    //return;
    //console.log("播放落子的声音在assets/dang.wav");
    // if (XBStore.state.G_AudioPlayer == null) {
    // 	XBStore.state.G_AudioPlayer = new Audio();
    // 	XBStore.state.G_AudioPlayer.autoplay = false;
    // 	XBStore.state.G_AudioPlayer.src = '/static/assets/dang.wav';
    // }
    // XBStore.state.G_AudioPlayer.play();
  }
  /*
     悔棋
  */
  function RegretAtep(str) {
    if (SelfPu == null) {
      return
    }
    if (str == 'delete') {
      SelfPu.RegretAtep(str)
      SelfDraw()
    } else {
      let a = SelfPu.RegretAtep()
      if (a == 'showmodal') {
        return a
      } else {
        SelfDraw()
      }
    }
  }
  /*
    删除当前标记
  */
  function DeleteNowGraph(str) {
    if (SelfPu == null) {
      return
    }
    if (str == 'delete') {
      SelfPu.DeleteNowGraph(str)
      SelfDraw()
    } else {
      let a = SelfPu.DeleteNowGraph()
      if (a == 'nomark') {
        return a
      } else {
        SelfDraw()
      }
    }
  }
  /*
      删除分支
  */
  function DeleteNowParent(str) {
    if (SelfPu == null) {
      return
    }
    if (str == 'delete') {
      SelfPu.DeleteNowParent(str)
      SelfDraw()
    }
    let a = SelfPu.DeleteNowParent()
    if (a == 'nobranch') {
      return a
    } else {
      SelfDraw()
    }
  }
  /*
     显示棋子坐标
  */
  function GetCurrentStepPosition() {
    if (SelfPu == null) {
      return
    }
    // decideWriteCount()
    SelfDraw()
  }
  /*
     获取当前棋盘坐标
  */
  function GetCurrentCol(x, y, str = '', blockAndwhite = 0, cover) {
    //console.log('wo有进来了 ', BoardType);
    let msg
    let qgl_xCol
    let qgl_yCol
    let types = false
    if (BoardType == 1) {
      // put棋盘
      let blockWith = StepSize
      if (ShowCol) {
        xCol = Math.round(x / blockWith) - 1 + LeftIndex //落子横坐标
        yCol = Math.round(y / blockWith) - 1 + TopIndex //落子纵坐标
      } else {
        xCol = Math.round(x / blockWith + 0.5) - 1 + LeftIndex //落子横坐标
        yCol = Math.round(y / blockWith + 0.5) - 1 + TopIndex //落子纵坐标
      }

      if (xCol < 0 || xCol > BoardSize - 1 || yCol < 0 || yCol > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        return true
      }
      //console.log("xCol:" + xCol, "yCol:" + yCol);

      if (!SelfPu.CheckCanSetpIn(xCol, yCol, str)) {
        //guo 打开
        //console.log("非法落子点");
        return true
      }

      //let s = SelfPu.ReceiveAStepIn(xCol, yCol);
      ////console.log(store.state.stopChess);

      // if(store.state.stopChess==false){
      // //console.log("哈哈哈哈哈哈哈哈哈哈哈哈哈或或或或或或或或或")
      // SelfDraw();
      // }
      //SelfDraw();
      let s = TransferDToC(xCol) + TransferDToC(yCol)
      SelfDraw()
      that.MsgFromBoard(56, s)

      return s
    } else if (BoardType == 2) {
      // 作业棋盘
      let blockWith = StepSize
      if (ShowCol) {
        xCol = Math.round(x / blockWith) - 1 + LeftIndex //落子横坐标
        yCol = Math.round(y / blockWith) - 1 + TopIndex //落子纵坐标
      } else {
        xCol = Math.round(x / blockWith + 0.5) - 1 + LeftIndex //落子横坐标
        yCol = Math.round(y / blockWith + 0.5) - 1 + TopIndex //落子纵坐标
      }
      if (xCol < 0 || xCol > BoardSize - 1 || yCol < 0 || yCol > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        return true
      }
      //console.log("xCol:" + xCol, "yCol:" + yCol);

      if (!SelfPu.CheckCanSetpIn(xCol, yCol, str)) {
        //guo 打开
        //console.log("非法落子点");
        return true
      }

      if (str != '') {
        msg = SelfPu.ReceiveAStepInWithCtrlOrShiftForWork(xCol, yCol, str)
      } else {
        if (blockAndwhite == 1) {
          // 黑白交替...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol)
        } else if (blockAndwhite == 2) {
          // 黑子...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol, 1)
        } else if (blockAndwhite == 3) {
          // 白子...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol, 2)
        } else {
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol)
        }
      }
      SelfDraw()
      //console.log("here send 55 out");
      that.MsgFromBoard(55, msg)
      return
    } else if (BoardType == 4) {
      let blockWith = StepSize

      if (CanStepIn == false) {
        return false
      }
      if (str == 'auto') {
        //自动落子
        xCol = x
        yCol = y
      } else {
        if (ShowCol) {
          xCol = Math.round(x / blockWith) - 1 + LeftIndex //落子横坐标
          yCol = Math.round(y / blockWith) - 1 + TopIndex //落子纵坐标
        } else {
          xCol = Math.round(x / blockWith + 0.5) - 1 + LeftIndex //落子横坐标
          yCol = Math.round(y / blockWith + 0.5) - 1 + TopIndex //落子纵坐标
        }
      }
      //console.log(xCol);
      //console.log(yCol);
      if (str == 'deleteMark') {
        //删除标记
        //console.log(x);
        //console.log(y);
        //console.log(xCol);
        //console.log(yCol);
        SelfPu.deleteMark(xCol, yCol, blockAndwhite)
        let data = SelfPu.GetCurrentNodeInfo(1)
        //console.log(data);
        if (blockAndwhite == 2) {
          EngStrNum = 0
        }
        SelfDraw()
        return
      } else if (str == 'checkMark') {
        //检查是否有标记
        let data = SelfPu.GetCurrentNodeInfo(1)
        let arry1 = data.GraphStr.split('').filter((val) => val && val.trim())
        let arry2 = []
        let judge = false
        for (let i = 0; i < arry1.length; i = i + 3) {
          //console.log(arry1[i]);
          //console.log(arry1[i + 1]);
          //console.log(arry1[i + 2]);
          let x1 = arry1[i + 1].charCodeAt(0) - 65
          let y1 = arry1[i + 2].charCodeAt(0) - 65
          if (x1 > 7) {
            x1 = x1 - 1
          }
          if (y1 > 7) {
            y1 = y1 - 1
          }
          arry2.push({
            name: arry1[i] + arry1[i + 1] + arry1[i + 2],
            x: x1,
            y: y1
          })
        }
        //console.log(x);
        //console.log(y);
        //console.log(xCol);
        //console.log(yCol);
        //console.log(arry2);
        for (let i = 0; i < arry2.length; i++) {
          if (arry2[i].x == xCol && arry2[i].y == yCol) {
            judge = true
            break
          }
        }
        return judge
      } else if (str == 'receiveDeleteMark') {
        //收到双师删除标记
        SelfPu.deleteMark(x, y, blockAndwhite)
        let data = SelfPu.GetCurrentNodeInfo(1)
        //console.log(data);
        if (blockAndwhite == 2) {
          EngStrNum = 0
        }
        SelfDraw()
        return
      }
      if (xCol < 0 || xCol > BoardSize - 1 || yCol < 0 || yCol > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        return true
      }
      //console.log("xCol:" + xCol, "yCol:" + yCol);
      qgl_xCol = xCol
      qgl_yCol = yCol

      // if (!SelfPu.CheckCanSetpIn(xCol, yCol, str)) {
      //   //guo 打开
      //   //console.log("非法落子点");
      //   return true
      // }

      if (str != '' && str != 'auto') {
        msg = SelfPu.ReceiveAStepInWithCtrlOrShiftForWork(xCol, yCol, str)
      } else {
        if (blockAndwhite == 1) {
          // 黑白交替...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol)
          //debugger
        } else if (blockAndwhite == 2) {
          // 黑子...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol, 1)
          //debugger
        } else if (blockAndwhite == 3) {
          // 白子...
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol, 2)
          //debugger
        } else {
          msg = SelfPu.ReceiveAStepInForWork(xCol, yCol)
          //debugger
        }
      }
      if (types) {
        SelfDraw(1)
      } else {
        SelfDraw()
      }
    }

    if (!CanStepIn) {
      return
    }

    if (BoardType == 3) {
      // 如果当前在标记死子
      let blockWith = BoardWidth / (parseInt(BoardSize) + 1)
      xCol = Math.round(x / blockWith) - 1 //落子横坐标
      yCol = Math.round(y / blockWith) - 1 //落子纵坐标
      if (xCol < 0 || xCol > BoardSize - 1 || yCol < 0 || yCol > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        //console.log("xCol:" + xCol, "yCol:" + yCol);
        return
      }
      // //console.log("blockWith:" + blockWith);
      //console.log("xCol:" + xCol, "yCol:" + yCol);
      SelfPu.GetRemoveString(xCol, yCol)
      SelfDraw()
      SelfPu.GetMu()
      return
    }

    if (MarkType == 'cancel') {
      isMarkPic = false
      isAddVary = true
      isDrawBlock = true
    }
    if (isDrawBlock == true) {
      let blockWith = BoardWidth / (parseInt(BoardSize) + 1)
      tmpX = Math.round(x / blockWith) - 1
      tmpY = Math.round(y / blockWith) - 1
      if (tmpX < 0 || tmpX > BoardSize - 1 || tmpY < 0 || tmpY > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        return
      }
      if (tmpX == xCol && tmpY == yCol && isAddVary == true) {
        //画棋子
        drawBlockNum = 1
        isDrawBlock = false
      } else if (isMarkPic == true) {
        isDrawBlock = false
      } else {
        //画方格
        drawBlockNum = 2
        drawBlock(tmpX, tmpY, blockWith)
        xCol = tmpX //落子横坐标
        yCol = tmpY //落子纵坐标
      }
    }
    if ((isAddVary == true || isMarkPic == true) && isDrawBlock == false) {
      //console.log("接收到坐标值：x:" + x, "y:" + y);

      isDrawBlock = true
      // 			if (isMarkPic == true) {
      //
      // 			}
      if (isAddVary == true && isShowVioce == true) {
        isShowVioce = false
        PlayDown()
      }

      let blockWith = BoardWidth / (parseInt(BoardSize) + 1)
      xCol = Math.round(x / blockWith) - 1 //落子横坐标
      yCol = Math.round(y / blockWith) - 1 //落子纵坐标
      if (xCol < 0 || xCol > BoardSize - 1 || yCol < 0 || yCol > BoardSize - 1) {
        //console.log("在落子区域外，不能落子");
        //console.log("xCol:" + xCol, "yCol:" + yCol);
        return
      }
      //console.log("xCol:" + xCol, "yCol:" + yCol);

      //if (SelfPu.CheckCanSetpIn(xCol, yCol) == false && isAddVary == true) {
      //console.log("canNotStepIn在这停掉了")
      //  return
      // }

      if (isAddVary == true) {
        //console.log('isAddVary == true');
        SelfPu.ReceiveAStepIn(xCol, yCol)
        if (types) {
          SelfDraw(1)
        } else {
          SelfDraw()
        }
      } else if (isMarkPic == true) {
        if (xCol > 7) {
          xCol = xCol + 1
        }
        if (yCol > 7) {
          yCol = yCol + 1
        }
        if (qgl_xCol > 7) {
          qgl_xCol = qgl_xCol + 1
        }
        if (qgl_yCol > 7) {
          qgl_yCol = qgl_yCol + 1
        }
        if (MarkType == 'a') {
          if (EngStrNum > 25) {
            return
          }
          let datas = SelfPu.GetCurrentNodeInfo(1)
          //console.log(datas);
          if (datas.GraphStr == null || datas.GraphStr == '') {
            //没有标记
            EngStrNum = 0
          } else {
            //有标记
            let arry11 = datas.GraphStr.split('').filter((val) => val && val.trim())
            let arry22 = []
            //console.log(arry11);
            for (let i = 0; i < arry11.length; i += 3) {
              arry22.push(arry11.slice(i, i + 3))
            }
            //console.log(arry22);
            //console.log(arry22.length);
            let strs = ''
            let reg = /^[a-z]+$/
            for (let i = 0; i < arry22.length; i++) {
              if (reg.test(arry22[i][0])) {
                strs = arry22[i][0]
              }
            }
            if (strs == '') {
              EngStrNum = 0
            } else {
              EngStrNum = strs.charCodeAt(0) - 96
            }
          }
          //console.log(EngStrNum);
          let EngStr = String.fromCharCode(MarkType.charCodeAt() + EngStrNum)
          // let str_eng = String(EngStr) + String.fromCharCode(65 + parseInt(xCol)) + String.fromCharCode(65 + parseInt(yCol));
          let str_eng =
            String(EngStr) +
            String.fromCharCode(65 + parseInt(qgl_xCol)) +
            String.fromCharCode(65 + parseInt(qgl_yCol))
          let a = SelfPu.AddGraphic(str_eng, cover)
          if (a == null) {
            if (types) {
              SelfDraw(1)
            } else {
              SelfDraw()
            }
            EngStrNum = EngStrNum + 1
          } else {
            return
          }
        } else {
          // let str_eng = String(MarkType) + String.fromCharCode(65 + parseInt(xCol)) + String.fromCharCode(65 + parseInt(yCol));
          let str_eng =
            String(MarkType) +
            String.fromCharCode(65 + parseInt(qgl_xCol)) +
            String.fromCharCode(65 + parseInt(qgl_yCol))
          EngStrNum = 0
          SelfPu.AddGraphic(str_eng, cover)
          if (types) {
            SelfDraw(1)
          } else {
            SelfDraw()
          }
        }
      }
    }
  }
  /*
     前台接收一个评论字符串的方法
  */
  function ReceiveACommentIn(strr) {
    SelfPu.ReceiveACommentIn(strr)
  }
  /*
     前台接收一个录音文件的路径的方法
  */
  function ReceiveAudio(str, num) {
    let AudioNum = SelfPu.AddAudio(str, num)
    //console.log("SelfPu声音：" + AudioNum);
    return AudioNum
  }
  /*
     从String中加载棋谱
  */
  function LoadFromString(inStr) {
    // //console.log(inStr);
    SelfPu.ReadFromString(inStr)
    SelfPu.GotoLastStep()
    // //console.log("Load From String," + inStr);
    if (BoardType == 2) {
      // //console.log("type @2");
      WriteCount = 1
      let RR = SelfPu.GetBounderInfo()
      LeftIndex = RR[0]
      RightIndex = RR[1]
      TopIndex = RR[2]
      BottomIndex = RR[3]
      InitBoard()
      // //console.log("after INitboarf");
      let S = SelfPu.getTimuText()
      if (S != null && S != '') {
        S = S.trim()
        // //console.log("121212");
        // //console.log(S)
        that.MsgFromBoard(12, S)
      }
    }
    if (BoardType == 4) {
      // //console.log("type @2");
      WriteCount = 1
      let RR = SelfPu.GetBounderInfo()
      LeftIndex = RR[0]
      RightIndex = RR[1]
      TopIndex = RR[2]
      BottomIndex = RR[3]
      InitBoard()
      // //console.log("after INitboarf");
      let S = SelfPu.getTimuText()
      if (S != null && S != '') {
        S = S.trim()
        // //console.log("121212");
        // //console.log(S)
        that.MsgFromBoard(12, S)
      }
    }
    SelfDraw()
    let BNum = 0
    let WNum = 0
    // //console.log(Board);
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        if (Board[i][j] == 1) {
          BNum++
        } else if (Board[i][j] == 2) {
          WNum++
        }
      }
    }
    // //console.log(BNum);
    that.MsgFromBoard(14, BNum + ' ' + WNum)
    if (BoardType == 3) {
      SelfPu.GetMu()
    }
  }

  function LoadFromString2(inStr) {
    // //console.log(inStr);
    SelfPu.ReadFromString(inStr)
    SelfPu.GotoLastStep()
    SelfPu.CopyBoard(Board)
    // //console.log("Load From String," + inStr);
    if (BoardType == 2) {
      // //console.log("type @2");
      WriteCount = 1
      let RR = SelfPu.GetBounderInfo()
      LeftIndex = RR[0]
      RightIndex = RR[1]
      TopIndex = RR[2]
      BottomIndex = RR[3]
      InitBoard()
      // //console.log("after INitboarf");
      let S = SelfPu.getTimuText()
      if (S != null && S != '') {
        S = S.trim()
        // //console.log("121212");
        // //console.log(S)
        that.MsgFromBoard(12, S)
      }
    }
    if (BoardType == 4) {
      // //console.log("type @2");
      WriteCount = 1
      let RR = SelfPu.GetBounderInfo()
      LeftIndex = RR[0]
      RightIndex = RR[1]
      TopIndex = RR[2]
      BottomIndex = RR[3]
      InitBoard()
      // //console.log("after INitboarf");
      let S = SelfPu.getTimuText()
      if (S != null && S != '') {
        S = S.trim()
        that.MsgFromBoard(12, S)
      }
    }
    isMarkPic = true
    SelfDraw()
    let BNum = 0
    let WNum = 0
    // //console.log(Board);
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        if (Board[i][j] == 1) {
          BNum++
        } else if (Board[i][j] == 2) {
          WNum++
        }
      }
    }
    // //console.log(BNum);
    that.MsgFromBoard(14, BNum + ' ' + WNum)
    if (BoardType == 3) {
      SelfPu.GetMu()
    }
  }
  /*
     清空棋盘
  */
  function ClearAll() {
    SelfPu.ClearAll()
    InitBoard()
    SelfDraw()
  }

  /*
    收到一手落子
  */
  function ReaceiveAStep(inStr) {
    //console.log("收到一手落子" + inStr);
  }

  /*
    收到一颗落子，强制放在最后一步
  */
  function AddToLast(inStr) {
    inStr = inStr + ';'
    if (SelfPu.AddStepToLast(inStr) == true) {
      SelfDraw()
      PlayDown()
    }
  }
  /*
     返回主谱
  */
  function ReturnMainPu() {
    //console.log("vgBoard");
    let a = true
    SelfPu.JumpToMain(a)
    SelfDraw()
  }
  /*
     到分支的最后一手
  */
  function GoInToVaryLast(str) {
    if (SelfPu == null) {
      return
    }
    SelfPu.GoInToVaryLast(str)
    SelfDraw()
  }
  /*
     评论棋谱开关(当前是否处于落子状态)
  */
  function AddVary() {
    isAddVary = true
    isMarkPic = false
  }
  /*
     标记开关(当前是否处于标记状态)
  */
  function MarkPic(num) {
    //console.log('是否打开了标记开关')
    isMarkPic = true
    isAddVary = false
    MarkType = num
  }
  /*
     设置棋盘路数大小
  */
  function SetBoardType(inType) {
    BoardType = inType
    InitBoard()
    SelfDraw()
  }

  /**
   * 获取下当前的棋盘类型...
   */
  function GetBoardType() {
    return BoardType
  }

  /*
    重置棋盘路数
  */
  function SetBoardSize(inBZ) {
    BoardSize = inBZ
    cxt.clearRect(0, 0, BoardWidth, BoardWidth)
    SelfPu.ClearAll()
    SelfPu.SetBoardSize(inBZ)
    //console.log("wozaivgboardclear");
    //getwh();
    //InitBoard();
    // SelfDraw();
  }

  function GetBoardSize() {
    return BoardSize
  }
  /*
     返回评论上一步
  */
  function GotoPreComment() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GotoPreComment()
    SelfDraw()
  }
  /*
     返回评论下一步
  */
  function GotoNextComment() {
    if (SelfPu == null) {
      return
    }
    SelfPu.GotoNextComment()
    SelfDraw()
  }
  /*
    计算总的评论数
  */
  function CountCommentIn() {
    if (SelfPu == null) {
      return
    }
    let ComNum = SelfPu.CountComment()
    return ComNum
  }
  //获取当前手评论
  function getStepComment() {
    if (SelfPu == null) {
      return
    }
    let stepCom = SelfPu.getStepComment(true)
    return stepCom
  }
  /*
    判断当前手是否有声音
  */
  function HasVoice() {
    if (SelfPu == null) {
      return
    }
    let a = SelfPu.HasVoice()
    return a
  }

  function UndoRemoveAll() {
    if (BoardType == 3) {
      SelfPu.UndoRemoveAll()
      SelfDraw()
      SelfPu.GetMu()
    }
  }

  function SetCanStepIn(inValue) {
    CanStepIn = inValue
  }

  function SliderToCurrentStep(CurrentValue) {
    if (SelfPu == null) {
      return
    }
    SelfPu.SliderToCurrentStep(CurrentValue)
    NowOnSlider = true
    SelfDraw()
    NowOnSlider = false
  }

  //生成评论棋谱字符串
  function SaveToString(param) {
    if (SelfPu == null) {
      return
    }
    //console.log("dasdadasda:" + SelfPu.SaveToString(param));
    let str = SelfPu.SaveToString(param)
    if (str != null) {
      that.MsgFromBoard(10, str)
    } else {
      return
    }
    return str
  }

  function GetToCurrent(inType) {
    let S = ''
    if (SelfPu == null) {
      return S
    }
    S = SelfPu.GetToCurrent(inType)
    that.MsgFromBoard(11, S)
    //console.log(inType);
    return S
  }

  function AssignJudgeData(inMu, inFinal) {
    MuBoard = inMu
    FinalBoard = inFinal
    SelfDraw()
  }

  /*
    查看答案
  */
  function ShowAnswer() {
    if (SelfPu != null) {
      SelfPu.ShowAnswer()
      SelfPu.GotoLastStep()
      WriteCount = 1
      SelfDraw()
    }
  }

  /*
     查看错误的解答
  */
  function ShowErrorAction(ErrorStr) {
    if (SelfPu != null) {
      SelfPu.CheckErrorAction(ErrorStr)
      SelfPu.GotoLastStep()
      WriteCount = 1
      SelfDraw()
    }
  }

  /*
    取消查看答案
  */
  function CancelAnswer() {
    if (SelfPu != null) {
      SelfPu.CancelAnswer()
      SelfPu.GotoFirstStep()
      WriteCount = 0
      SelfDraw()
    }
  }
  // 设置题
  function setTitle(str) {
    if (!str) {
      return
    }
    SelfPu.setTitle(str)
  }

  function drawSuccessPoint(item, number) {
    //画胜率点
    if (number == 1) {
      //推荐点
      SelfDraw()
      let offset = 0
      if (!ShowCol) {
        offset = 0.5
      }
      ////console.log(item);
      for (let i = 0; i < item.length; i++) {
        let percent = (item[i].winrate * 100).toFixed(2)
        let str = item[i].step
        let x1 = TransferCToD(str.charAt(0))
        let y1 = TransferCToD(str.charAt(1))
        //console.log(x1);
        //console.log(y1);
        let colors = 96 - item[i].winrate * 50
        cxt4.fillStyle = 'rgb(' + colors + ',139,87)'
        cxt4.beginPath()
        cxt4.arc(
          (x1 - xzzuo - offset) * StepSize + StepSize,
          (y1 - xzshang - offset) * StepSize + StepSize,
          StepSize * 0.5,
          0,
          2 * Math.PI,
          true
        )
        cxt4.closePath()
        cxt4.fill()
        // cxt4.state.font=20+"px sans-serif"
        //    cxt4.state.fontSize=20+"px";
        // cxt4.setFontSize(Math.ceil(StepSize * 0.4));
        cxt4.font = Math.ceil(StepSize * 0.3) + 'px sans-serif'
        cxt4.textAlign = 'center'
        cxt4.fillStyle = '#FFFFFF'
        cxt4.fillText(
          percent,
          (x1 - xzzuo + 1.02 - offset) * StepSize,
          (y1 - xzshang + 1.05 - offset) * StepSize
        )
      }
    } else if (number == 2) {
      //取消推荐点
      cxt4.clearRect(0, 0, BoardWidth, BoardWidth)
      SelfDraw()
    }
  }

  /*
   *	解压95字节Board
   */
  function UnzipBoard(board) {
    let boardsize
    let tmpboard = new Array()
    let a = 0
    let b = 0
    let s = 0
    if (board.length == 95) {
      if (board[94] > 42) {
        boardsize = board[94] - 99
      } else {
        boardsize = 19
      }
      for (let i = 0; i < 19; i++) {
        tmpboard[i] = new Array()
      }
      for (let i = 0; i < 19; i++) {
        if (i < 19) {
          for (let j = 0; j < 5; j++) {
            if (j * 4 < 19) {
              a = board[i * 5 + j] & 0xff
              for (let z = 0; z < 4; z++) {
                if (j * 4 + z < 19) {
                  if (j == 4) {
                    if (z == 0) {
                      b = a >> 4
                      a = a % 16
                      s = parseInt(j * 4 + z + 2, 10)
                    } else if (z == 1) {
                      b = a >> 2
                      a = a % 4
                      s = parseInt(j * 4 + z, 10)
                    } else if (z == 2) {
                      b = a
                      s = parseInt(j * 4 + z - 2, 10)
                    }
                  } else {
                    if (z == 0) {
                      b = a >> 6
                      a = a % 64
                      s = parseInt(j * 4 + z + 3, 10)
                    } else if (z == 1) {
                      b = a >> 4
                      a = a % 16
                      s = parseInt(j * 4 + z + 1, 10)
                    } else if (z == 2) {
                      b = a >> 2
                      a = a % 4
                      s = parseInt(j * 4 + z - 1, 10)
                    } else if (z == 3) {
                      b = a
                      s = parseInt(j * 4 + z - 3, 10)
                    }
                  }

                  i = parseInt(i, 10)
                  tmpboard[s][i] = b
                }
              }
            }
          }
        }
      }

      if (boardsize == 19) {
        return tmpboard
      } else {
        let tmpboard2 = new Array()
        for (let i = 0; i < boardsize; i++) {
          tmpboard2[i] = new Array()
        }
        for (let i = 0; i < boardsize; i++) {
          for (let j = 0; j < boardsize; j++) {
            tmpboard2[i][j] = tmpboard[i][j]
          }
        }
        return tmpboard2
      }
    }
    return 'error board'
  }

  /**
   *  画表情
   */
  function drawEmotionBoard(an) {
    thisAn = an
    SelfDraw()
  }

  /**
   *
   GetStepInfo
   */
  function GetStepInfo() {
    return SelfPu.GetStepInfo()
  }

  /**
   * 重新画棋盘
   */
  function DrawBoardFn(arr) {
    if (Array.isArray(arr)) {
      Board = arr
    }
  }

  /*
    画目
  */
  function DrawMu(finalboard, muboard) {
    // cxt2.clearRect(0, 0, 10000, 10000);
    let offset = 0
    if (!ShowCol) {
      offset = 0.5
    }
    cxt3.clearRect(0, 0, 10000, 10000)
    for (let x = 0; x < BoardSize; x++) {
      for (let y = 0; y < BoardSize; y++) {
        if (Board[x][y] == 1) {
          if (finalboard[y][x] == 0) {
            cxt3.drawImage(
              heizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.06,
              StepSize * 1.06
            )
          } else {
            cxt3.drawImage(
              heizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.06,
              StepSize * 1.06
            )
          }
        } else if (Board[x][y] == 2) {
          if (finalboard[y][x] == 0) {
            cxt3.drawImage(
              baizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.05,
              StepSize * 1.05
            )
          } else {
            cxt3.drawImage(
              baizi,
              (x - xzzuo + 0.47 - offset) * StepSize,
              (y - xzshang + 0.47 - offset) * StepSize,
              StepSize * 1.05,
              StepSize * 1.05
            )
          }
        }

        if (!muboard) {
          continue
        }

        if (muboard[y][x] == 1) {
          cxt3.fillStyle = 'black'
          cxt3.fillRect(
            (x - xzzuo + 0.75 - offset) * StepSize,
            (y - xzshang + 0.75 - offset) * StepSize,
            StepSize * 0.5,
            StepSize * 0.5
          )
        } else if (muboard[y][x] == 2) {
          cxt3.fillStyle = 'white'
          cxt3.fillRect(
            (x - xzzuo + 0.75 - offset) * StepSize,
            (y - xzshang + 0.75 - offset) * StepSize,
            StepSize * 0.5,
            StepSize * 0.5
          )
        }
      }
    }
  }
  /*
   * 请求帮助
   */
  function drawHelpStone(pu) {
    let arr = pu.split('')
    let x = TransferCToD(arr[0])
    let y = TransferCToD(arr[1])
    try {
      let img = document.getElementById('help_stone')
      cxt3.drawImage(
        img,
        (x - xzzuo + 0.47) * StepSize,
        (y - xzshang + 0.47) * StepSize,
        StepSize * 1.06,
        StepSize * 1.06
      )
    } catch (error) {
      // canvas 自己画个棋子
      //console.log(error);
    }
  }

  function TransferCToD(inC) {
    // 强制做下大小写转换，避免不必要的问题
    inC = inC.toUpperCase()
    let t = inC.charCodeAt(0)
    t = t - 65
    if (t > 8) {
      t = t - 1
    }

    if (t > 19) {
      t = -1
    }

    return t
  }

  function TransferDToC(inNum) {
    let t = ''

    if (inNum > 7) {
      inNum = inNum + 1
    }

    t = String.fromCharCode(inNum + 65)
    return t
  }

  function SetCanShowIndicator(inValue) {
    CanShowIndicator = inValue
    cxt5.clearRect(0, 0, BoardWidth, BoardWidth)
  }

  function ReadFromStream(data) {
    SelfPu.ReadFromStream(data)
    let bdsize = SelfPu.GetBoardSize()
    SetBoardSize(bdsize)
    let puStr = SelfPu.SaveToString()
    LoadFromString(puStr)
  }

  function SaveToFile() {
    // 保存ctn文件
    let s = SelfPu.SaveToFile()
    return s
  }

  function GetKeyStrByStep(param) {
    //根据手数跳转到当前手的棋盘数据 cb加
    if (SelfPu == null) {
      return
    }
    let str = SelfPu.GetKeyStrByStep(param)
    return str
  }

  function SaveToFileWithHead() {
    let s = SelfPu.SaveToFileWithHead()
    return s
  }

  function SetQipuForResearch(param) {
    if (SelfPu == null) {
      return
    }
    SelfPu.SetQipuForResearch(param)
  }

  function GetCurrentNodeInfo(number) {
    //拿到当前手的树数据
    if (SelfPu == null) {
      return
    }
    let tree = SelfPu.GetCurrentNodeInfo(number)
    return tree
  }

  function xyPos(x, y) {
    var blockWith = StepSize
    if (ShowCol) {
      xCol = Math.round(x / blockWith) - 1 + LeftIndex //落子横坐标
      yCol = Math.round(y / blockWith) - 1 + TopIndex //落子纵坐标
    } else {
      xCol = Math.round(x / blockWith + 0.5) - 1 + LeftIndex //落子横坐标
      yCol = Math.round(y / blockWith + 0.5) - 1 + TopIndex //落子纵坐标
    }
    let xy = {
      xCol: xCol,
      yCol: yCol
    }
    //console.log(xy)
    return xy
  }

  function clearCanvas5(x, y) {
    //console.log(x, y)
    //console.log(this.c5width, this.c5height)
    cxt5.clearRect(x, y, this.c5width, this.c5height) //清除指定的矩形区域，然后这块区域会变的完全透明。
    //x ：清除矩形起始点的 x 坐标
    //y ：清除矩形起始点的 y 坐标
    //width ： 清除矩形矩形的宽
    //height ： 清除矩形矩形的高
    // cxt5.draw()
  }

  function GetCount() {
    if (SelfPu != null) {
      var step = SelfPu.GetCurrentStepPosition()
      //console.log(step)
      return step[2]
    }
  }

  /**
   * 挂盘讲解 AI形势
   */
  function drawSelfAN(selfAN) {
    var yesD;
    var FW;
    var Color;
    var offset = 0;
    if (!ShowCol) {
      offset = 0.5;
    }
    for (var x = 0; x < BoardSize; x++) {
      for (var y = 0; y < BoardSize; y++) {
        yesD = false;
        if (Board[x][y] == 1) {
          if (selfAN[y][x] < 0 && selfAN[y][x] <= -20) {
            yesD = true;
          }
        } else if (Board[x][y] == 2) {
          if (selfAN[y][x] > 20) {
            yesD = true;
          }
        } else if (Board[x][y] == 0) {
          if (selfAN[y][x] < -20 || selfAN[y][x] > 20) {
            yesD = true;
          }
        }
        if (!yesD) {
          continue;
        }
        FW = selfAN[y][x];
        if (FW < 0) {
          Color = 'white';
        } else {
          Color = 'black';
        }
        FW = StepSize / 2 * Math.abs(FW) / 100;

        if (ShowCol) {
          var left = (y + 1) * StepSize - FW / 2;
          var top = (x + 1) * StepSize - FW / 2;
          var Right = (y + 1) * StepSize + FW / 2;
          var Bottom = (x + 1) * StepSize + FW / 2;
          // //console.log(x,y,left, top, Right, Bottom)
        }

        // cxt6.color = Color;
        cxt10.beginPath();
        cxt10.moveTo(top, left);
        cxt10.lineTo(top, Right);
        cxt10.lineTo(Bottom, Right);
        cxt10.lineTo(Bottom, left);
        cxt10.closePath();
        cxt10.fillStyle = Color;
        cxt10.strokeStyle = Color;
        cxt10.fill();
        cxt10.stroke();
        // cxt6.fillRect((x - xzzuo + 0.47 - offset) * StepSize, (y - xzshang + 0.47 - offset) * StepSize, FW, FW);
      }
    }
    SelfPu.saveCurrentData({
      type: 'situation',
      isSave: true,
      data: selfAN
    });
  }

  /**
   * 取消 AI形势
   */
  function cancelDrawSelfAN() {
    SelfPu.saveCurrentData({
      type: 'situation',
      isSave: false
    });
    cxt10.clearRect(0, 0, BoardWidth, BoardWidth);
    // cxt10.draw();
    // SelfDraw();
  }

  this.SetQipuForResearch = SetQipuForResearch //cb加
  this.GetCurrentNodeInfo = GetCurrentNodeInfo //cb加
  this.GetKeyStrByStep = GetKeyStrByStep //cb加
  this.drawSuccessPoint = drawSuccessPoint //cb加
  this.InitBoard = InitBoard
  this.SelfDraw = SelfDraw
  this.getwh = getwh
  this.GotoPreStep = GotoPreStep
  this.GotoNextStep = GotoNextStep
  this.GotoFirstStep = GotoFirstStep
  this.GotoLastStep = GotoLastStep
  this.SetStartCount = SetStartCount
  this.GetCurrentStepPosition = GetCurrentStepPosition
  this.decideWriteCount = decideWriteCount
  this.setWriteCount = setWriteCount
  this.GetCurrentCol = GetCurrentCol
  this.LoadFromString = LoadFromString
  this.LoadFromString2 = LoadFromString2
  this.ClearAll = ClearAll
  this.SetBoardSize = SetBoardSize
  this.GetBoardSize = GetBoardSize
  this.ReaceiveAStep = ReaceiveAStep
  this.AddToLast = AddToLast
  this.drawBlock = drawBlock
  this.ReturnMainPu = ReturnMainPu
  this.AddVary = AddVary
  this.MarkPic = MarkPic
  this.GoInToVaryLast = GoInToVaryLast
  this.SetBoardType = SetBoardType
  this.GetBoardType = GetBoardType
  this.ReceiveACommentIn = ReceiveACommentIn
  this.GotoPreComment = GotoPreComment
  this.CountCommentIn = CountCommentIn
  this.GotoNextComment = GotoNextComment
  this.getStepComment = getStepComment
  this.DrawOutGraphic = DrawOutGraphic
  this.DrawOutGraphicVideo = DrawOutGraphicVideo
  this.ReceiveAudio = ReceiveAudio
  this.UndoRemoveAll = UndoRemoveAll
  this.SetCanStepIn = SetCanStepIn
  this.RegretAtep = RegretAtep
  this.SliderToCurrentStep = SliderToCurrentStep
  this.SaveToString = SaveToString
  this.GetToCurrent = GetToCurrent
  this.AssignJudgeData = AssignJudgeData
  this.ShowAnswer = ShowAnswer
  this.ShowErrorAction = ShowErrorAction
  this.CancelAnswer = CancelAnswer
  this.DeleteNowGraph = DeleteNowGraph
  this.DeleteNowParent = DeleteNowParent
  this.HasVoice = HasVoice
  this.GoToPreTenSteps = GoToPreTenSteps
  this.GoToNextTenSteps = GoToNextTenSteps
  this.FreeDrawpic = FreeDrawpic
  this.FreeDrawpicRemote = FreeDrawpicRemote
  this.DrawMu = DrawMu
  this.UnzipBoard = UnzipBoard
  this.DrawBoardFn = DrawBoardFn
  this.drawHelpStone = drawHelpStone
  this.drawEmotionBoard = drawEmotionBoard
  this.GetStepInfo = GetStepInfo
  this.GetZippedBoard = GetZippedBoard
  this.ShowIndicator = ShowIndicator
  this.SetCanShowIndicator = SetCanShowIndicator
  this.ReadFromStream = ReadFromStream
  this.SaveToFile = SaveToFile
  this.SaveToFileWithHead = SaveToFileWithHead
  this.changeScale = changeScale
  this.DrawMypic = DrawMypic
  this.DrawMypicRemote = DrawMypicRemote
  this.SetEndTypeByKeyStr = SetEndTypeByKeyStr
  this.setTitle = setTitle
  this.setShowScale = setShowScale

  this.SelfPu = SelfPu //qgl补
  this.GotoStepByKeyStr = GotoStepByKeyStr //qgl补
  this.GetCurrentNodeKeyStr = GetCurrentNodeKeyStr //qgl补
  this.clearCanvas = clearCanvas //qgl补
  this.revocationCanvas = revocationCanvas //qgl补
  this.closeCanvas = closeCanvas //qgl补
  this.changeMarkPic = changeMarkPic //qgl补
  this.setRectByData = setRectByData
  this.saveRectData = saveRectData
  // this.FormVaryPic = FormVaryPic;
  this.setJudgeBool = setJudgeBool
  this.setAiGiveStepBool = setAiGiveStepBool
  this.saveAiGiveStepData = saveAiGiveStepData
  this.setAiGiveStepData = setAiGiveStepData
  this.xyPos = xyPos
  this.clearCanvas5 = clearCanvas5
  this.GetCount = GetCount
  this.drawSelfAN = drawSelfAN; //qgl补
  this.cancelDrawSelfAN = cancelDrawSelfAN; //qgl补
  for (let i = 0; i < 19; i++) {
    Board[i] = new Array()
    NumbericBoard[i] = new Array()
    for (let j = 0; j < 19; j++) {
      Board[i][j] = 0
      NumbericBoard[i][j] = 0
    }
  }
  // this.drawArc = drawArc;
  // 测试棋谱
  //let testS = "B[QD];W[DD];B[QQ];W[DQ];B[CF];W[DF];B[CD];W[CE];B[BE];W[DE];B[CC];W[CG];B[BF];W[DC];B[DB];W[EB];B[CB];W[DA];B[CA];W[EA];B[CH];W[DG];B[BG];W[DH];B[BH];W[CJ];B[BJ];W[DJ];B[CO];W[DO];B[CQ];W[DP];B[CP];W[OR];B[CR];W[RR];B[QR];W[QP];B[RQ];W[SR];B[QS];W[SQ];B[SP];W[RP];B[TP];W[SO];B[TQ];W[TR];B[RS];W[PQ];B[SS];W[TO];B[TS];W[PR];B[PS];W[PT];B[QT];W[OT];B[OS];W[NT];B[NS];W[NR];B[MS];W[MR];B[LS];W[KR];B[MT];W[KS];B[LR];W[LQ];B[PP];W[PO];B[OP];W[NQ];B[NP];W[MP];B[NO];W[NN];B[MO];W[LN];B[LO];W[KO];B[LP];W[MQ];B[KP];W[KQ];B[JP];W[HQ];B[JQ];W[JR];B[HP];W[GP];B[MN];W[MM];B[KN];W[LM];B[JO];W[JN];B[JM];W[HN];B[HM];W[KM];B[KO];W[HO];B[GO];W[GN];B[FO];W[OO];B[WW];W[FP];B[EP];W[EO];B[FN];W[FM];B[GM];W[EQ];B[HR];W[HS];B[GQ];W[GS];B[GR];W[FR];B[KT];W[JS];B[HQ];W[FQ];B[LT];W[FS];B[EN];W[DN];B[DM];W[EM];B[CN];W[FL];B[JT];W[HN];B[HO];W[JN];B[NM];W[ON];B[NL];W[ML];B[MK];W[KL];B[JL];W[KK];B[HT];W[NK];B[GT];W[JK];B[FT];W[ES];B[DR];W[DS];B[CS];W[CT];B[BT];W[HL];B[DT];W[GL];B[GN];W[HN];B[JN];W[OQ];B[ET];W[OL];B[ER];W[HN];";
  //let testS = "B[DD]<V W[ED];B[FD]<V W[EC];B[EB];W[FB];B[GB];/V>;W[GD];B[HD];/V><V W[DE];B[DF];W[DG];B[DH];/V>;W[EE];B[FF];W[GG];";
  //SelfPu.ReadFromString(testS);
  //SelfPu.GotoLastStep();
  // 从AliasQipu里面把数组拷贝出来
  //SelfPu.CopyBoard(Board);
  this.getwh()
}

export default CreateABoard
