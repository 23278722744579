/*
    CommonVar.js  是用于存放该项目中需要用到的所有的公共变量。
	便于在项目中的任何地方进行引用和修改
*/
import { createStore } from 'vuex'

const XBStore = createStore({
  state() {
    return {
      UserName: '', // 用户名, xinboXXXX
      PassWord: '',
      AliasName: '', // 用户的中文名...
      UserID: 0, // 用户的ID号，纯数字
      SchoolID: 0, // 学校编号...
      SchoolName: '', // 学校的名字
      AllStudents: [], // 记录所有学生的数组，用于后面下棋对弈管理..
      StudentList: [], //记录所有学生的信息，AllStudents里面包括的有教师，这是纯学生
      TeaCherList: [], //记录所有教师的信息，AllStudents里面包括的有教师，这是纯教师
      LocalUserInfo: [], // 当前本地缓存里面的所有的用户...
      TotalUserNumArr: [0, 0, 0, 0, 0, 0, 0], // 记录每个级别分区总人数的数组...
      OnLineUserNumArr: [0, 0, 0, 0, 0, 0, 0], // 记录每个级别在线人数的数组..
      BattleNumArr: [0, 0, 0, 0, 0, 0, 0], // 记录每个级别正在下棋人数的数组...
      CurrentShowUserID: [], // 当前正在显示的用户ID列表  到时候判断是否需要刷新用户列表
      UserListStatusChanged: false, // 用户列表是否需要改变下
      SchoolGameCountToday: 0, // 本校今日对局数
      LookonServerID: 0, // 当前正在旁观对局的ServerID
      LookonGameID: 0, // 当前正在旁观的对局
      LookonStepInArr: [], // 旁观时候收到的落子信息
      LookOnResult: '', // 旁观的一盘对局的结果
      isAdmin: false, // 是否是学校管理员
      XBAdmin: 0,
      AdminRoleID: 0, // 用户的管理权限...  0 不能修改  1 可修改所有信息  2 只能修改级别
      AllClassIDs: [], // 这个教师的所有班级ID
      AllClassInfo: [], // 所有的班级信息
      /*ClassPeopleId:[],//用于存放班级所有人的Id
		ClassStuDate:[], //已存在班级里的学生
		ClassTeaDate:[], //已存在班级里的教师*/
      CurrentSocket: null, // 当前的webSocket连接
      CurrentHomeWork: null, // 用于记录当前正在布置作业的基本信息,方便后期提交
      TimuHandle: null, // 用于本地题目的管理
      BookData: null, // 布置作业时候的书本和类型的管理
      BookManager: null, // 书本的管理
      timuSelectedNum: 0, // 当前选中的题目数量
      UnSubmitHomeWork: [], // 当前本地未提交的作业
      XBLoading: false, //控制loading显示的变量
      qrsize: '0', //loading进度的数值
      SessionId: '',
      PageCountForWork: 0, // 用于控制作业里面目前进入的层次，方便退出
      UnsubmitWorkIndex: '',
      ModelType: '', //检查客户端是什么端口，0为安卓，1为IOS，2为其他开发工具
      CurrentJudgeItem: null, // 当前正在判棋的Item
      CommentSoundArray: [], // 记录当前评棋的声音
      NameSearchItem: '', //记录姓名搜索的用户详细信息
      FromLogin: '',
      SocketCloseType: false,
      StartUpdataKey: false,
      currentServe: '',
      ClassMoveOrAdd: true,
      AdminList: [], //存放当前管理员的列表
      appNotOut: false, //检测应用是否退出false退出,ture没退出
      SessionCount: 0, // 当前获取Session的次数
      localVersion: 95, //用于记录当前应用得版本，用于升级。每次升级都需要把版本+1
      isNew: '', //学校是否在新表里面
      FXschool: '',
      CheckTeaItems: [], //用于存放添加的教师，新版本添加班级的时候用
      FexId: '', //当前的分校id
      FexName: '', //当前的分校的名字
      StatisticList: [0, 0, 0, 0, 0, 0, 0, 0, 0], //统计用户点击频率的的数组
      CurrentFexClass: '', //把分校的数据存放在里面，每次切换都覆盖
      TaskReward: null, // 记录任务完成的所有奖励
      TaskList: [], //记录当前的任务
      commitID: '', //保存提交任务后获取的ID
      PushClientId: '', // 用于推送的ClientID
      Curlesson_id: '', //记录当前班级的课程id,课程id不为0的禁止班级的增删改查,
      Curlesson_name: '', //当前班级的课程名
      CurclsData: {}, //记录当前班级的信息，班级id和班级名和班级人数都放在里面
      AddRecordClass: [], //记上课里面添加补课学生放在这里面
      SelectedUserNameArr: [], // 当前选中的用户的新博号...
      DataChanged: false, // 当前的数据是不是改变了
      SelectedCalssCidArr: [], //当前选中的班级的id
      AllFx: [], //当前用户下的所有分校
      AllClass: [], //当前用户下所有班级
      CurRecord: [],
      shenpiItem: [],
      //后台和物品
      // NetAdress:"http://test.xinboduiyi.com/cngames/adminh/",
      // RootUrl: 'http://admin.xbkids.cn/admin_test/adminh', // 正式服测试环境路径 (用于之前的功能,因为部分新功能未同步过来,)
      // RootUrl: 'http://test.xinboduiyi.com/cngames/adminh',  // 测试服测试路径 (所有功能的测试)
      newRootUrl: 'http://admin.xbkids.cn/adminh', //正式服正式环境(以后要全部用这个)
      RootUrl: 'http://admin.xbkids.cn/adminh', //正式服正式环境(以后要全部用这个)
      classUserModefy: false, // 班级学员修改过
      XueyuanModefy: false,
      TeacherModefy: false,
      ClsModefy: false,
      addOrDelStu: false,
      duiHuanRen: [], //兑换人的名字
      NetAdress: 'http://admin.xbkids.cn/adminh/',
      duiHuanWuPin: [], //选择的物品
      WorkSessionId: '',
      duiHuanNum: 0, //兑换的物品数量
      CardName: '',
      duiHuanXiaofei: 0, //兑换消耗的积分
      duiHuanChanged: false, //兑换人和兑换物品数量是否变化
      sessionid: '', //后台登陆返回的id
      fxid: '', //后台分校id
      fxname: '', //后台分校名称
      classid: '', //后台班级
      is_new: '', //是否使用了新后台
      teaToclsArr: '', //选择教师到班级的时候的一个暂存
      checkedLesson: [], //暂存了个课程
      lessonName: '', //班级课程的名称
      lessonId: '', //班级课程的id
      weekStr: '', //上课时间对应周几的字符串
      weekArr: [], //上课时间对应周几的ID 数组 已经排好序
      clsRoom: '', //教室名
      clsId: '', //教室id
      addCls: false, //是否新增了教室
      teacher1: '', //上课老师
      teacherid1: '', //上课老师id
      teacher2: '', //助教
      teacherid2: '', //助教id
      g_isSchoolAdmin: 0, // 后台登录后返回是否超级管理员 (新登陆时拿到)
      g_roleId: 6, //当前登陆账号在对应的分校下的身份 (新登陆时拿到)
      admin_model: 1, //收费模式: 1收费版 2简易版  (新登陆时拿到)
      ishavelesson: false, //当前班级是否有课程 班级主页前往记上课页面时的记录
      FreelessonName: '', //课程名称 免费版记上课时用到
      FreelessonId: '',
      boardTitle: [], //选中的表头,内部是7个布尔值，true为选中显示
      clsdelstu: [], //记上课 删除
      clschaStu: [], //记上课 插班
      clsbuStu: [], //记上课 补课
      currentGuanlian: [], //当前关联请假记录的人
      currentStu: [],
      adminModefy: false,
      checkedNum: 0,
      // ------------------------
      CardId: 0,
      RewardList: [], //记录奖励的id和对应的奖励名字
      G_AudioPlayer: null, // 全局播放Down声音的Player
      xiaozhang: '', //校长
      province: '', //省份
      city: '', //城市
      community: '', //地区
      fenxiaoid: '', //暂存的分校id
      stuInfo: {}, //老版学生信息
      FXteacher: [], //分校老师列表
      FXclassroom: [], //分校教室列表,
      // 编辑课程用
      allcheck: false,
      zdfxid: [],
      zdfxname: [],
      zdfx: [],
      // 记上课用
      isEditTrue: false,
      // 新建比赛用
      CreatMatch: {},
      CreatTrue: false,
      stuData: '',
      restData: '',
      addData: '',
      stuIndex: '',
      mid: '',
      matchInfo: '',
      editMatch: {},
      upMatchResult: {},
      // 简易版 用
      isupdate: false,
      //语音课程
      updatePeople: false,
      checkedStudents: [], //语音课程指定学生
      checkedTeachers: [], //语音课程指定教师
      checkedStuAndTea: [], //语音课程指定学生和教师
      checkedyuCls: [], //语音课程指定班级
      // 发布任务用
      isSuccess: false
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})

// export function setupStore() {
//   store.dispatch('login/getStorageLogin')
// }

export default XBStore
