/*
   专门用于管理棋谱的部分
   Create By Xinlong
   2019-02-15
*/
import { XBStore } from './CommonVar.js'
import store from '../../../store/index'
/*
   TStepNode 用于记录单手落子
*/
function TStepNode() {
  var preNode = null // 上一手
  var NextNode = null // 下一手
  var parent = null // 父节点（一个分支） TSteps
  var x = -1
  var y = -1
  var color = 0
  var StepStr = ''
  var ShapeStr = '' // 形的内容
  var ShapeArr = new Array() // 形的内容所对应的数组，一次性计算好了，省的后面再计算
  var Children = new Array() // 所有的变化...
  var StepCount = 0 // 当前的手数....
  var CommentStr = '' // 当前的文字评论
  var AudioStr // 当前的声音评论
  var AudioLen = '' //当前声音的编号
  // var CommentList = new Array;
  var GraphStr = '' // 当前节点里面的需要画图的地方，在棋谱里面 <T GraphStr /T>  具体形式为：XAA  第一个为内容 0 圆圈， 1 三角，2 十字 3 斜十字 4 方块 a-z 字母
  var NodeEnd = 0 // 当前落子是否对应了答案  0 none 1 正解  2 参考  3 错解
  var TheGStr = '' // 画标记点的String, 用于作业里面
  var ReceiveGStr = '' // 当前已经收到的落子点...
  var KeyStr = '' // 当前落子的Key值，用于和棋谱树之间的交互...

  // 读取一步棋的详细内容
  function ReadFromString(inStr) {
    this.ReceiveGStr = ''
    this.TheGStr = ''
    if (inStr == '') {
      return
    }
    var S = inStr.charAt(0)
    if (S == 'B' || S == 'b') {
      this.color = 1
    } else if (S == 'W' || S == 'w') {
      this.color = 2
    } else {
      this.color = 3
    }
    inStr = inStr.slice(2)
    // 首先读取正常的内容，
    var II = inStr.indexOf(']')
    var S1 = inStr.substring(0, II)
    inStr = inStr.slice(II + 1)
    if (S1 == '0' || S1 == '00') {
      S1 = 'WW'
    }
    if (S1.length == 2) {
      // 普通的一步落子
      if (S1.charAt(0) == 'Z' || S1.charAt(0) == 'z') {
        // 让子棋
        this.x = -2
        this.y = Number(S1.charAt(1))
      } else {
        this.x = TransferCToD(S1.charAt(0))
        this.y = TransferCToD(S1.charAt(1))
      }
      this.StepStr = S1
    } else {
      // 第一手的形，这里面包含了多个落子...
      this.ShapeStr = S + S1
      this.x = -3
      this.ShapeArr = new Array()
      var LineArray = new Array()
      var cc = this.color
      var xx = TransferCToD(S1.charAt(0))
      var yy = TransferCToD(S1.charAt(1))
      var _Len = 2
      var theCount = 0
      this.color = 4
      LineArray = [cc, xx, yy]
      this.ShapeArr.push(LineArray)
      while (_Len < S1.length) {
        if (S1.charAt(_Len) == 'B' || S1.charAt(_Len) == 'b') {
          cc = 1
        } else {
          cc = 2
        }
        xx = TransferCToD(S1.charAt(_Len + 1))
        yy = TransferCToD(S1.charAt(_Len + 2))
        LineArray = [cc, xx, yy]
        this.ShapeArr.push(LineArray)
        this.ReceiveGStr = ''
        _Len = _Len + 3
      }
    }
    var ASubSteps
    while (inStr != '') {
      // 如果剩下的内容还有的话，我们需要继续解析
      if (inStr.indexOf('<V') == 0) {
        // 一个变化,这里比较复杂的是有可能变化里面还有变化
        // 从当前位置开始搜索，找到一个完整的变化，可能里面还包含变化的
        theCount = 1
        II = 0
        S = ''
        for (var i = 2; i < inStr.length; i++) {
          if (inStr.charAt(i) == '<' && inStr.charAt(i + 1) == 'V') {
            theCount++
          } else if (
            inStr.charAt(i) == '/' &&
            inStr.charAt(i + 1) == 'V' &&
            inStr.charAt(i + 2) == '>'
          ) {
            theCount--
          }
          if (theCount == 0) {
            S = inStr.substring(2, i)
            inStr = inStr.slice(i + 3)
            S = S.trim()
            ASubSteps = new TSteps()
            ASubSteps.KeyStr = this.KeyStr + '_' + (Children.length + 1)
            ASubSteps.ReadFromString(S)
            ASubSteps.parentNode = this
            Children.push(ASubSteps)
            break
          }
        }
      } else if (inStr.indexOf('<C') == 0) {
        // 一个评论，评论里面不可能再包含新的评论了，直接读取尾巴
        II = inStr.indexOf('/C>')
        if (II < 0) {
          // something error
          return
        }
        S = inStr.substring(2, II)
        inStr = inStr.slice(II + 3)
        // CommentList.push(S);
        this.CommentStr = S
      } else if (inStr.indexOf('<A') == 0) {
        // 一段录音
        II = inStr.indexOf('/A>')
        if (II < 0) {
          // something error
          return
        }
        S = inStr.substring(2, II)
        inStr = inStr.slice(II + 3)
        S = S.trim()
        var Arr = S.split(' ')
        this.AudioStr = Arr[0]
        this.AudioLen = Arr[1]
        // XBStore.state.CommentSoundArray.push(this.AudioStr);
        store.state.CommentSoundArray.push(this.AudioStr) //qgl改
      } else if (inStr.indexOf('<T') == 0) {
        // 需要画图的部分
        II = inStr.indexOf('/T>')
        if (II < 0) {
          // something error
          return
        }
        S = inStr.substring(2, II)
        inStr = inStr.slice(II + 3)
        this.GraphStr = S
      } else if (inStr.indexOf('<E') == 0) {
        // 当前落子是否未正解或者错解
        II = inStr.indexOf('/E>')
        if (II < 0) {
          // something error
          return
        }
        S = inStr.substring(2, II)
        inStr = inStr.slice(II + 3)
        this.NodeEnd = Number(S)
      } else if (inStr.indexOf('<G') == 0) {
        II = inStr.indexOf('/G>')
        if (II < 0) {
          // something error
          return
        }
        S = inStr.substring(2, II)
        inStr = inStr.slice(II + 3)
        this.TheGStr = S.trim()
      } else {
        return
      }
    }
  }
  //生成棋谱String
  function SaveToString(forwork) {
    var tmpStr = ''
    if (this.color == 0) {
      return ''
    } else if (this.color == 1) {
      // console.log('this.Shaper:' + this.ShapeStr)
      if (this.ShapeStr != null && this.ShapeStr != '') {
        tmpStr = tmpStr + 'B' + '[' + this.ShapeStr.substring(1) + ']'
      } else {
        tmpStr = tmpStr + 'B' + '[' + this.StepStr + ']'
      }
    } else if (this.color == 2) {
      tmpStr = tmpStr + 'W' + '[' + this.StepStr + ']'
    } else if (this.color == 4) {
      tmpStr = tmpStr + this.ShapeStr.substr(0, 1) + '[' + this.ShapeStr.substring(1) + ']'
    }
    if (this.Children.length > 0) {
      for (var i = 0; i < this.Children.length; i++) {
        tmpStr = tmpStr + '<V ' + this.Children[i].SaveToString(forwork) + '/V>'
      }
    }
    if (this.GraphStr != null) {
      tmpStr = tmpStr + '<T ' + this.GraphStr + ' /T>'
      // this.GraphStr = tmpStr;
    }
    if (this.CommentStr != null) {
      tmpStr = tmpStr + '<C ' + this.CommentStr + ' /C>'
      // this.CommentList.push(tmpStr);
      // this.CommentStr = tmpStr;
    }
    if (this.AudioStr != null) {
      tmpStr = tmpStr + '<A ' + this.AudioStr + ' ' + this.AudioLen + '/A>'
    }
    if (this.NodeEnd != null) {
      tmpStr = tmpStr + '<E ' + this.NodeEnd + ' /E>'
    } else {
      if (forwork && this.NextNode == null && this.color != 4) {
        // 自动标注为错解...
        tmpStr = tmpStr + '<E 3 /E>'
      }
    }
    tmpStr = tmpStr + ';'
    // console.log('2222222', tmpStr)
    return tmpStr
  }

  /*
	  生成简单的string模式
	*/
  function SaveToString_Simple() {
    if (this.color == 0) {
      return ''
    } else if (this.color == 1) {
      if (this.x == -3) {
        return 'B' + this.ShapeStr
      } else {
        return 'B' + this.StepStr
      }
    } else if (this.color == 2) {
      if (this.x == -3) {
        return 'W' + this.ShapeStr
      } else {
        return 'W' + this.StepStr
      }
    } else if (this.color == 4) {
      return this.ShapeStr
    }
  }

  /**
   * 为后面的棋谱树生成String....
   */
  function SaveToString_ForTree() {
    if (this.color == 0) {
      return ''
    } else if (this.color == 1) {
      if (this.x == -3) {
        return 'B[' + this.ShapeStr + ']'
      } else {
        return 'B[' + this.StepStr + '];'
      }
    } else if (this.color == 2) {
      if (this.x == -3) {
        return 'W[' + this.ShapeStr + '];'
      } else {
        return 'W[' + this.StepStr + '];'
      }
    } else if (this.color == 4) {
      return 'B' + '[' + this.ShapeStr.substring(1) + ']'
    }
  }
  //计算评论总数
  function CountComment() {
    var CountNum = 0

    if (this.CommentStr != null && this.Children.length > 0 && this.AudioStr != null) {
      //一手棋同时有变化和评论就算一个
      CountNum = CountNum + 1
      // console.log(this.CommentStr)
    } else if (this.CommentStr != null) {
      // console.log(this.CommentStr)
      CountNum = CountNum + 1
    } else if (this.AudioStr != null) {
      // console.log(this.CommentStr)
      CountNum = CountNum + 1
    } else if (this.Children.length > 0) {
      if (this.CommentStr == null && this.AudioStr == null) {
        //有变化的这一点，前面没加过变化数
        CountNum = CountNum + 1
      }
      for (var i = 0; i < this.Children.length; i++) {
        CountNum = CountNum + this.Children[i].CountComment()
        // this.CountNum = CountNum;
      }
    }
    return CountNum
  }
  //分析当前棋子是否有评论，返回true或者null;
  function AnalyseNode() {
    if (this.Children.length > 0) {
      return true
    } else if (this.CommentStr != null) {
      return true
    } else if (this.AudioStr != null) {
      return true
    }
  }

  /**
   * 当前收到一个标记点...
   * @param {*} inS
   * return -1 错误的点  1 正确的点  2 重复的点
   */
  function ReceiveAGraph(inS) {
    // 首先判断下是不是在当前的落子点里面...
    var S = this.TheGStr.toUpperCase()
    var S1 = inS.toUpperCase()
    var kk = 0
    var findIt = false
    while (kk < S.length) {
      var SS = S.substring(kk, kk + 2)
      if (SS == S1) {
        findIt = true
        break
      }
      kk = kk + 2
    }
    if (!findIt) {
      return -1
    }
    // 然后检查下是不是在已经骡子的点里面..
    kk = 0
    var alreadyIn = false
    while (kk < this.ReceiveGStr.length) {
      let SS = this.ReceiveGStr.substring(kk, kk + 2)
      if (SS == S1) {
        alreadyIn = true
        break
      }
      kk = kk + 2
    }
    if (alreadyIn) {
      return 2
    }
    this.ReceiveGStr = this.ReceiveGStr + S1
    return 1
  }

  /**
   * 单个节点生成棋谱数所需要的数据...
   */
  function GenerateTreeData() {
    var row = {}
    row.Str = this.SaveToString_ForTree()
    row.color = this.color
    row.StepCount = this.StepCount
    row.KeyStr = this.KeyStr
    if (this.PreNode != null) {
      row.isFirtNode = false
    } else {
      row.isFirtNode = true
    }
    row.NodeEnd = this.NodeEnd
    if (this.parent.parentNode != null) {
      row.parentStr = this.parent.parentNode.KeyStr
    } else {
      row.parentStr = ''
    }

    if (this.Children.length > 0) {
      // 有子节点...
      row.children = []
      for (var i = 0; i < this.Children.length; i++) {
        var row1 = this.Children[i].GenerateTreeData()
        row.children.push(row1)
      }
      return row
    } else {
      // 没有子节点...

      return row
    }
  }

  this.AnalyseNode = AnalyseNode
  this.CountComment = CountComment
  this.SaveToString = SaveToString
  this.SaveToString_Simple = SaveToString_Simple
  this.ReadFromString = ReadFromString
  this.Children = Children
  this.ReceiveAGraph = ReceiveAGraph
  this.GenerateTreeData = GenerateTreeData
  this.SaveToString_ForTree = SaveToString_ForTree
}

/*
   TSteps 单个分支的管理，里面包含若干TStepNode
*/
function TSteps() {
  var parentNode = null
  var CurrentNode = null
  var FirstNode = null // 第一手
  var Count = 0 // 总共的步数(该分支)
  var TheAnswer = ''
  var KeyStr = ''

  // 清除所有的内容
  function Clear() {
    FirstNode = null
    parentNode = null
    Count = 0
    this.CurrentNode = null
  }
  //
  function SaveToString(forwork) {
    var tmpNode
    var tmpStr = ''

    tmpNode = this.FirstNode
    // console.log(forwork)
    while (tmpNode != null) {
      if (typeof forwork == 'undefined') {
        //cb改
        tmpStr = tmpStr + tmpNode.SaveToString(forwork)
        tmpNode = tmpNode.NextNode
      } else {
        //qgl 改
        if (
          forwork &&
          Number(forwork[forwork.length - 1]) >= Number(tmpNode.KeyStr[tmpNode.KeyStr.length - 1])
        ) {
          tmpStr = tmpStr + tmpNode.SaveToString(forwork)
          tmpNode = tmpNode.NextNode
        } else {
          tmpNode = null
        }
      }
      // 以前的
      // tmpStr = tmpStr + tmpNode.SaveToString(forwork);
      // tmpNode = tmpNode.NextNode;
    }
    return tmpStr
  }
  //
  function CountComment() {
    var tmpNode
    var CountNum = 0
    tmpNode = this.FirstNode

    while (tmpNode != null) {
      // CountNum = CountNum + 1;
      CountNum = CountNum + tmpNode.CountComment()
      if (tmpNode.NextNode != null) {
        tmpNode = tmpNode.NextNode
      } else {
        tmpNode = tmpNode.NextNode
      }

      // console.log("this.FirstNode.StepCoun:")
      // console.log(tmpNode.StepCount)
    }
    return CountNum
  }

  // 从String中载入
  function ReadFromString(inStr) {
    var S = ''
    var S1 = ''
    var ii = 0
    var S2 = ''
    var tmpNode
    while (inStr != '') {
      inStr = inStr.trim()
      // modified by xinlong 2019-03-07
      // 遇到一些特殊的情况，需要重新处理下，有可能开头遇到<M  这个是对应的整个棋谱的正解部分
      S2 = this.KeyStr + '_' + (Count + 1)
      if (inStr.indexOf('<M') == 0) {
        ii = inStr.indexOf('/M>')
        if (ii < 0) {
          // something error
          return
        }
        S = inStr.substring(2, ii)
        inStr = inStr.slice(ii + 3)
        this.TheAnswer = S
        continue
      } else if (inStr.indexOf('<R') == 0) {
        ii = inStr.indexOf('/R>')
        if (ii < 0) {
          return
        }
        S = inStr.substring(2, ii)
        inStr = inStr.slice(ii + 3)
        continue
      }
      ii = inStr.indexOf(']')
      S = inStr.substring(0, ii + 1)
      S1 = inStr.substring(ii + 1, ii + 2)
      if (S1 == ';') {
        // 一手完整的落子,也可能是形
        tmpNode = new TStepNode()
        tmpNode.KeyStr = S2
        tmpNode.ReadFromString(S)
      } else if (S1 == '<') {
        // 后面带有变化，评论等
        // 这里面可能会带有多个评论和变化
        var I1 = 1
        var theLen = 0
        for (var i = ii + 2; i < inStr.length; i++) {
          if (inStr.charAt(i) == '<') {
            I1++
          } else if (inStr.charAt(i) == '>') {
            I1--
            if (I1 == 0 && inStr.charAt(i + 1) == ';') {
              theLen = i
              break
            }
          }
        }
        if (I1 != 0) {
          // some thing error
          return
        }
        ii = theLen
        S = inStr.substring(0, ii + 1)
        tmpNode = new TStepNode()
        tmpNode.KeyStr = S2
        tmpNode.ReadFromString(S)
      }
      inStr = inStr.slice(ii + 2)
      if (this.CurrentNode == null) {
        this.CurrentNode = tmpNode
        this.FirstNode = tmpNode
      } else {
        // 建立双向链表...
        this.CurrentNode.NextNode = tmpNode
        tmpNode.PreNode = this.CurrentNode
        this.CurrentNode = tmpNode
      }
      Count++
      tmpNode.StepCount = Count
      tmpNode.parent = this
    }
  }

  /*
	   获取总手数
	*/
  function GetTotalCount() {
    return Count
  }

  /*
	  incCount
	*/
  function IncCount() {
    Count++
  }

  function SetCount(inValue) {
    Count = inValue
  }

  /**
   * 生成棋谱数的数据...
   */
  function GenerateTreeData() {
    var tmpNode
    tmpNode = this.FirstNode
    var resArr = []
    while (tmpNode != null) {
      var row = tmpNode.GenerateTreeData()
      resArr.push(row)
      tmpNode = tmpNode.NextNode
    }
    return resArr
  }

  /**
   * 通过KeyStr来获取一个节点...
   * @param {*} inKey
   */
  function GetANodeByKeyStr(inKey) {
    var tmpNode
    var tmpNode1
    tmpNode = this.FirstNode
    while (tmpNode != null) {
      if (tmpNode.KeyStr == inKey) {
        return tmpNode
      }
      if (tmpNode.Children.length > 0) {
        for (var i = 0; i < tmpNode.Children.length; i++) {
          tmpNode1 = tmpNode.Children[i].GetANodeByKeyStr(inKey)
          if (tmpNode1 != null) {
            return tmpNode1
          }
        }
      }
      tmpNode = tmpNode.NextNode
    }
  }

  this.SaveToString = SaveToString
  this.CountComment = CountComment
  this.Clear = Clear
  this.ReadFromString = ReadFromString
  this.GetTotalCount = GetTotalCount
  this.IncCount = IncCount
  this.SetCount = SetCount
  this.GenerateTreeData = GenerateTreeData
  this.GetANodeByKeyStr = GetANodeByKeyStr
}

/*
  TAliasQipu  单个棋谱的管理
*/
function TAliasQipu() {
  var content = new TSteps()
  var StepCount = 0 // 总共的手数
  // var StepComment = 0;	   //当前评论数
  var CurrentColor = 0 // 当前颜色值
  var CurrentStep = null // 当前落子信息
  var BlackName = '' // 黑方姓名
  var WhiteName = '' // 白方姓名
  var MatchResult = '' // 对局结果
  var BoardSize = 19 // 棋盘的路数
  var PuStr = '' // 棋谱的具体内容
  var Board = new Array() // 最终输出的棋盘
  var DeadString = '' // 用于记录死棋的
  var NumbericBoard = new Array() // 最终输出的数字棋盘
  var NumbericBoardForWork = new Array() // 作业时候的数字棋盘...
  var DeadBoard = new Array()
  var PrintBoard = new Array()
  var overLapArr = new Array() // 重叠的数据...
  var rescult = 0
  var BlackDead = 0 //黑子死棋的数量
  var WhiteDead = 0 //白子死棋的数量
  var CanStepIn = false //当前是否可以落子
  var JumpPoints = new Array() // 用于记录分支的跳转点，一般用于当前点在分支的时候
  var NowInSubSteps = false // 当前棋谱是否在分支中
  var PreBoardArr = new Array() // 用于记录上一手棋盘的数据的数组，用于判断打劫
  var MostLeft = 0 // 用于记录当前棋盘的最左端
  var MostTop = 0 // 用于记录当前棋盘的最上端
  var MostRight = 18 // 用于记录当前棋盘的最右端
  var MostBottom = 18 // 用于记录当前棋盘的最下断
  var CurDeadString = '' // 用于标记死子时候的string
  var BoardString = '' // 用于计算目的变量
  var MuDeadString = '' // 用于计算目的变量
  var RemovedStringArr = new Array() // 记录当前手动提掉的死子
  var BlackMu = 0 // 记录黑方的目
  var WhiteMu = 0 // 记录白方的目
  var sBoard = new Array() // 用于计算双方目数的数组
  var AudioNum = 0
  var WriteStart = 0
  var NowShowAnswer = false
  var OriString = ''
  var StepInCountForWork = 0
  var c_pos = 0 // 解析从本地加载的棋谱时的偏移量

  content.Count = 0
  content.KeyStr = 'xinboqipu'

  // 从String中载入一盘对局
  function ReadFromString(inStr) {
    ClearAll()
    OriString = inStr
    //XBStore.state.CommentSoundArray = [];
    content.ReadFromString(inStr)
  }
  /**
   * 从文件流中载入一盘对局
   * @param {数据流} inData
   */
  function ReadFromStream(inData) {
    var buf = Buffer.from(inData)
    var pos = 0
    var size = buf.readUInt32LE(pos)
    pos += 4
    // console.log('size', size)
    switch (size) {
      case 3000:
        ReadFromStream_3000(buf, pos)
        break
      case 3500:
        ReadFromStream_3500(buf, pos)
        break
      case 2000:
      case 4000:
        ReadFromStream_2000(buf, pos)
        break
      default:
      // console.log('not supported ', size)
    }
    // console.log('content.FirstNode', content.FirstNode)
  }
  /**
   * 解析类型标记为2000的棋谱
   * @param {uinit8array} buf
   * @param {偏移量} pos
   */
  function ReadFromStream_2000(buf, pos) {
    var draw_data_len = buf.readUInt32LE(pos)
    pos += 4
    var draw_data
    if (draw_data_len > 0) {
      draw_data = Buffer.alloc(draw_data_len)
      buf.copy(draw_data, 0, pos, pos + draw_data_len)
      pos += draw_data_len
    }
    var content_data_len = buf.length - 2 * 4 - draw_data_len
    var content_data
    var content_str = ''
    if (content_data_len > 0) {
      content_data = Buffer.alloc(content_data_len)
      buf.copy(content_data, 0, pos, pos + content_data_len)
      pos += content_data_len
      if (
        (content_data[0] === 0xef && content_data[1] === 0xbb) ||
        (content_data[0] === 0xfe && content_data[1] === 0xff) ||
        (content_data[0] === 0xff && content_data[1] === 0xfe)
      ) {
        content_str = content_data.toString('utf8')
      } else {
        var iconv = require('iconv-lite')
        content_data = iconv.decode(content_data, 'gbk')
        content_str = '\ufeff' + content_data.toString('utf8')
      }

      c_pos = 0
      ParseContent(content_str)
    }
  }
  /**
   * 解析类型标记为3000的棋谱
   * @param {*} buf
   * @param {*} pos
   */
  function ReadFromStream_3000(buf, pos) {
    var draw_data_len = buf.readUInt32LE(pos)
    pos += 4
    var draw_data
    if (draw_data_len > 0) {
      draw_data = Buffer.alloc(draw_data_len)
      buf.copy(draw_data, 0, pos, pos + draw_data_len)
      pos += draw_data_len
    }
    var content_data
    var content_data_len = buf.readUInt32LE(pos)
    pos = pos + 4
    var content_str = ''
    if (content_data_len > 0) {
      content_data = Buffer.alloc(content_data_len)
      buf.copy(content_data, 0, pos, pos + content_data_len)
      pos += content_data_len
      for (var i = 0; i < content_data_len; i++) {
        content_str += String.fromCharCode(content_data[i])
      }
      content_str = Buffer.from(content_str, 'base64').toString() //new Buffer(content_str, 'base64').toString();
      // console.log('content_str = ', content_str)
      c_pos = 0
      ParseContent(content_str)
    }
  }
  /**
   * 解析类型标记为3500的棋谱（未完全实现）
   * @param {*} buf
   * @param {*} pos
   */
  function ReadFromStream_3500(buf, pos) {
    ReadFromStream_3000(buf, pos)
    // Load Audio Data
    // Load Emotion Data
    // Load AIStep Data
    // Load AIAN Data
  }
  /**
   * 解析标签和数据
   * @param {*} content
   */
  function ParseContent(content) {
    var token, key, subcontent
    while (c_pos < content.length) {
      token = NextToken(content)
      if (token == '<') {
        key = NextToken(content)
        switch (key) {
          case 'Basic_info':
            subcontent = CopyToToken(content, '/>')
            ParseBasic_info(subcontent)
            break
          case 'Board':
            subcontent = CopyToToken(content, '/>')
            ParseBoard(subcontent)
            break
          case 'RefDataURI':
            subcontent = CopyToToken(content, '/>')
            ParseRefDataURI(subcontent)
            break
          case 'Init':
            subcontent = CopyToToken(content, '/Init')
            ParseInit(subcontent)
            break
          case 'Steps':
            subcontent = CopyToToken(content, '/Steps')
            ParseSteps(subcontent)
            break
        }
      }
    }
  }
  /**
   * 获取标记位
   * @param {*} str
   */
  function NextToken(str) {
    if (str == '') {
      return ''
    }
    var s1
    while (1) {
      s1 = str.charCodeAt(c_pos)
      if (s1 == 13 || s1 == 10 || s1 == 32 || s1 == 9) {
        c_pos++
      } else {
        break
      }
    }
    if (c_pos >= str.length) {
      return ''
    }
    s1 = str.charAt(c_pos)
    if (s1 == '<' || s1 == '!' || s1 == '[' || s1 == '/' || s1 == '>') {
      c_pos++
      return s1
    }
    var res = ''
    while (1) {
      if (c_pos >= str.length) {
        return res
      }
      s1 = str.charCodeAt(c_pos)
      if (
        !(
          s1 == 0 ||
          s1 == 9 ||
          s1 == 10 ||
          s1 == 13 ||
          s1 == 32 ||
          s1 == 47 ||
          s1 == 60 ||
          s1 == 62
        )
      ) {
        res += str.charAt(c_pos)
        c_pos++
      } else {
        return res
      }
    }
  }
  /**
   * 截取从标记位到结束位之间的字符串
   * @param {*} content
   * @param {*} token
   */
  function CopyToToken(content, token) {
    var end = content.indexOf(token, c_pos)
    var res = content.substring(c_pos, end)
    c_pos += res.length
    c_pos += token.length
    return res
  }
  /**
   * 解析"="两边的内容
   * @param {*} content
   * @param {*} callback
   */
  function ParseEqualData(content, callback) {
    var key, value
    var equalPos = content.indexOf('=')
    var leftCommaPos, rightCommaPos
    while (equalPos >= 0) {
      key = content.substring(0, equalPos).trim()
      content = content.slice(equalPos + 1)
      leftCommaPos = content.indexOf('"')
      rightCommaPos = content.indexOf('"', leftCommaPos + 1)
      value = content.substring(leftCommaPos + 1, rightCommaPos).trim()
      content = content.slice(rightCommaPos + 1)
      if (callback != null) {
        callback(key, value)
      }

      equalPos = content.indexOf('=')
    }
  }
  /**
   * 解析Board标签内容
   * @param {*} content
   */
  function ParseBoard(content) {
    ParseEqualData(content, (key, value) => {
      // console.log(value);
      switch (key) {
        case 'size':
          BoardSize = Number(value)
          break
        case 'type': // qipu type
          break
      }
    })
  }
  /**
   * 解析RefDataURI标签内容
   * @param {*} content
   */
  function ParseRefDataURI(content) {
    ParseEqualData(content, (key, value) => {
      // console.log(value);
      // if (key == 'link') {
      // }
    })
  }
  /**
   * 解析Basic_info标签内容
   * @param {*} content
   */
  function ParseBasic_info(content) {
    ParseEqualData(content, (key, value) => {
      //    console.log("key:" + key + "; value:" + value);
      switch (key) {
        case 'WhitePlayer':
          WhiteName = value
          break
        case 'BlackPlayer':
          BlackName = value
          break
        case 'CopyRight':
          break
        case 'CommentBy':
          break
        case 'MainVersion':
          break
        case 'MinorVersion':
          break
        case 'MatchName':
          break
        case 'MatchResult':
          MatchResult = value
          break
        case 'MatchLocation':
          break
        case 'MatchTime':
          break
      }
    })
  }
  /**
   * 解析Steps标签内容
   * @param {*} content
   */
  function ParseSteps(content) {
    var token = '[CDATA['
    var cdataPos = content.indexOf(token)
    if (cdataPos >= 0) {
      cdataPos += token.length
      var token1 = ']]>'
      var endPos = content.indexOf(token1)
      var subc = content.substring(cdataPos + 1, endPos).trim()
      if (subc == '') {
        return
      }
      ReadFromString(subc)
    }
  }
  /**
   * 解析Init标签内容
   * @param {*} content
   */
  function ParseInit(content) {
    if (content.indexOf('<![') >= 0) {
      var token = '[CDATA['
      var cddataPos = content.indexOf(token)
      if (cddataPos >= 0) {
        cddataPos += token.length
        var token1 = ']]>'
        var endPos = content.indexOf(token1)
        var subc = content.substring(cddataPos + 1, endPos).trim()
        if (subc.charAt(0) == 'I') {
          if (subc.charAt(4) != ']') {
            var end1 = subc.indexOf(']')
            var shapteStr = subc.substring(4, end1)
            subc = subc.slice(end1 + 1)

            var lessPos = subc.indexOf('<')
            var flag, eToken, prop
            var eTokenPos
            while (lessPos >= 0) {
              flag = subc.charAt(lessPos + 1)
              eToken = '/' + flag + '>'
              eTokenPos = subc.indexOf(eToken)
              if (eTokenPos < 0) {
                return
              }
              prop = subc.substring(lessPos + 2, eTokenPos).trim()
              subc = subc.slice(eTokenPos + 1)
              // not implementation
              switch (flag) {
                case 'C': // Comment
                  break
                case 'T': // GraphStr
                  break
                case 'A': // AudioStr
                  break
                case 'J': // EmotionStr
                  break
                case 'P': // AIStepStr
                  break
                case 'N': // AIANStr
                  break
                case 'D': // DrawThingsURI
                  break
              }
              lessPos = subc.indexOf('<')
            }
          }
        }
      }
    }
  }
  //将摆出变化的棋谱遍历生成一串字符串
  function SaveToString(forwork) {
    var tmpStr = ''
    var tmpNode
    if (content == null) {
      return null
    } else {
      tmpStr = tmpStr + content.SaveToString(forwork)
    }
    // console.log('生成的棋谱字符串为：' + tmpStr)
    return tmpStr
  }
  function SetBoardSize(BS) {
    BoardSize = BS
  }

  // 清除现有的所有的棋谱内容
  function ClearAll() {
    if (content == null) {
      content = new TSteps()
      content.Count = 0
      content.CurrentNode = null
    } else {
      content.Clear()
    }
    WriteStart = 0
    for (var i = 0; i < 19; i++) {
      // 			Board[i] = new Array;
      // 			NumbericBoard[i] = new Array;
      for (var j = 0; j < 19; j++) {
        Board[i][j] = 0
        NumbericBoard[i][j] = 0
        NumbericBoardForWork[i][j] = 0
      }
    }
  }

  /*
	  清空当前的数字棋盘
	*/
  function ClearNumbericBoard() {
    for (var i = 0; i < 19; i++) {
      for (var j = 0; j < 19; j++) {
        NumbericBoard[i][j] = 0
      }
    }
  }

  /*
	   回到第一手
	*/
  function GotoFirstStep() {
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    while (content.CurrentNode.PreNode != null) {
      content.CurrentNode = content.CurrentNode.PreNode
    }
    for (var i = 0; i <= 18; i++) {
      for (var j = 0; j <= 18; j++) {
        PreBoardArr[i][j] = 0
      }
    }
    // console.log('llllllllllllllllllllll')
    // debugger
    GenerateDrawArray()
  }

  /*
	   到上一手
	*/
  function GotoPreStep() {
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    if (content.CurrentNode.PreNode != null) {
      content.CurrentNode = content.CurrentNode.PreNode
    } else if (content.CurrentNode.parent.parentNode != null) {
      content.CurrentNode = content.CurrentNode.parent.parentNode
      // console.log('hskdjha')
    }
    // console.log('mmmmmmmmmmmmmmmmmmmmmm')
    // debugger
    GenerateDrawArray()
  }

  /*
	   到下一手
	*/
  function GotoNextStep() {
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }

    if (content.CurrentNode.NextNode != null) {
      content.CurrentNode = content.CurrentNode.NextNode
      if (StepInCountForWork > 0) {
        StepInCountForWork++
        NumbericBoardForWork[content.CurrentNode.x][content.CurrentNode.y] = StepInCountForWork
      }
      if (content.CurrentNode.NodeEnd != null) {
        // console.log('nnnnnnnnnnnnnnnnnnnnnn')
        // debugger
        GenerateDrawArray()
        return content.CurrentNode.NodeEnd
      }
    }
    // console.log('oooooooooooooooooooooo')
    // debugger
    GenerateDrawArray()
  }
  /*
		前进十手棋
	*/
  function GoToNextTenSteps() {
    var tmpNode
    // console.log('here is GoToNextTenSteps')
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    var col = 1
    while (content.CurrentNode.NextNode != null) {
      tmpNode = content.CurrentNode.NextNode
      col = col + 1
      content.CurrentNode = tmpNode
      if (col > 10) {
        // console.log('到第十手棋break')
        break
      }
    }
    // console.log('ppppppppppppppppppppp')
    // debugger
    GenerateDrawArray()
  }
  /*
		后退十手棋
	*/
  function GoToPreTenSteps() {
    // console.log('here is GoToPreTenSteps')
    // console.log(content)
    // console.log(content.CurrentNode)
    var tmpNode
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    var col = 1
    while (content.CurrentNode.PreNode != null) {
      tmpNode = content.CurrentNode.PreNode
      col = col + 1
      content.CurrentNode = tmpNode
      if (col > 10) {
        // console.log('到第十手棋break')
        break
      }
    }
    // console.log('qqqqqqqqqqqqqqqqqqqqqqq')
    // debugger
    GenerateDrawArray()
  }
  /*
	   到最后一手
	*/
  function GotoLastStep() {
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    /*  用于前期测试的代码
		content.CurrentNode = content.FirstNode;
		content.CurrentNode = content.CurrentNode.Children[0].FirstNode;
		content.CurrentNode = content.CurrentNode.NextNode;
		console.log(content.CurrentNode);
		GenerateDrawArray();

		return;
		*/
    while (content.CurrentNode.NextNode != null) {
      content.CurrentNode = content.CurrentNode.NextNode
    }
    // console.log('rrrrrrrrrrrrrrrrrrrrr')
    // debugger
    GenerateDrawArray()
  }

  /*
	  生成最终画棋盘时候的数组
	 */
  function GenerateDrawArray() {
    // 首先清空数组
    // console.log('清空数组')
    for (var i = 0; i < 19; i++) {
      for (var j = 0; j < 19; j++) {
        Board[i][j] = 0
        NumbericBoard[i][j] = 0
        PreBoardArr[i][j] = 0
      }
    }
    if (content == null) {
      return
    }
    // 清空JumpPoints
    JumpPoints = new Array()
    var tmpNode
    tmpNode = content.CurrentNode
    if (tmpNode == null) {
      return
    }

    while (true) {
      if (tmpNode.parent != null && tmpNode.parent.parentNode != null) {
        JumpPoints.unshift([tmpNode.parent.parentNode, tmpNode.parent.FirstNode])
        tmpNode = tmpNode.parent.parentNode
      } else {
        break
      }
    }

    if (JumpPoints.length > 0) {
      // console.log('set true')
      NowInSubSteps = true
    } else {
      NowInSubSteps = false
    }

    BlackDead = 0
    WhiteDead = 0
    MostLeft = 999
    MostRight = -1
    MostTop = 999
    MostBottom = -1
    tmpNode = content.FirstNode
    // console.log(tmpNode.x)
    while (true) {
      if (tmpNode.x == -2) {
        // 让子棋
        if (BoardSize == 19) {
          switch (tmpNode.y) {
            case 2:
              Board[3][3] = 1
              Board[15][15] = 1
              break
            case 3:
              Board[3][3] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              break
            case 4:
              Board[3][3] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              break
            case 5:
              Board[3][3] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              Board[9][9] = 1
              break
            case 6:
              Board[3][3] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              Board[3][9] = 1
              Board[15][9] = 1
              break
            case 7:
              Board[3][3] = 1
              Board[15][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[15][9] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              break
            case 8:
              Board[3][3] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              Board[3][9] = 1
              Board[15][9] = 1
              Board[9][3] = 1
              Board[9][15] = 1
              break
            case 9:
              Board[3][3] = 1
              Board[3][15] = 1
              Board[15][15] = 1
              Board[15][3] = 1
              Board[3][9] = 1
              Board[15][9] = 1
              Board[9][3] = 1
              Board[9][15] = 1
              Board[9][9] = 1
              break
          }
        } else if (BoardSize == 13) {
          switch (tmpNode.y) {
            case 2:
              Board[3][3] = 1
              Board[9][9] = 1
              break
            case 3:
              Board[3][3] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              break
            case 4:
              Board[3][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              break
            case 5:
              Board[3][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              Board[6][6] = 1
              break
            case 6:
              Board[3][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              Board[3][6] = 1
              Board[9][6] = 1
              break
            case 7:
              Board[3][3] = 1
              Board[9][3] = 1
              Board[3][6] = 1
              Board[6][6] = 1
              Board[9][6] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              break
            case 8:
              Board[3][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              Board[3][6] = 1
              Board[9][6] = 1
              Board[6][3] = 1
              Board[6][9] = 1
              break
            case 9:
              Board[3][3] = 1
              Board[3][9] = 1
              Board[9][9] = 1
              Board[9][3] = 1
              Board[3][6] = 1
              Board[9][6] = 1
              Board[6][3] = 1
              Board[6][9] = 1
              Board[6][6] = 1
              break
          }
        }
        MostLeft = 3
        MostTop = 3
        MostRight = 15
        MostBottom = 15
      } else if (tmpNode.x == -3) {
        // 一手形
        for (let i = 0; i < tmpNode.ShapeArr.length; i++) {
          var LineArr = tmpNode.ShapeArr[i]
          Board[LineArr[1]][LineArr[2]] = LineArr[0]
          if (LineArr[1] < MostLeft) {
            MostLeft = LineArr[1]
          }
          if (LineArr[1] > MostRight) {
            MostRight = LineArr[1]
          }
          if (LineArr[2] < MostTop) {
            MostTop = LineArr[2]
          }
          if (LineArr[2] > MostBottom) {
            MostBottom = LineArr[2]
          }
        }
      } else {
        if (tmpNode.x != -1) {
          //Board[tmpNode.x][tmpNode.y] = tmpNode.color;
          NumbericBoard[tmpNode.x][tmpNode.y] = tmpNode.StepCount - WriteStart
          // console.log(tmpNode.color)
          StepIn(tmpNode.color, tmpNode.x, tmpNode.y)
          if (tmpNode.x < MostLeft) {
            MostLeft = tmpNode.x
          }
          if (tmpNode.x > MostRight) {
            MostRight = tmpNode.x
          }
          if (tmpNode.y < MostTop) {
            MostTop = tmpNode.y
          }
          if (tmpNode.y > MostBottom) {
            MostBottom = tmpNode.y
          }
        }
      }
      if (tmpNode == content.CurrentNode) {
        break
      }
      // 找打下一个节点，这里需要注意下是否在分支里面
      if (JumpPoints.length > 0) {
        if (JumpPoints[0][0] == tmpNode) {
          // 如果当前节点下面要进入到分支的话，就进入
          tmpNode = JumpPoints[0][1]
          // 同时把数字棋盘清空
          ClearNumbericBoard()
          // debugger
          JumpPoints.shift()
        } else {
          tmpNode = tmpNode.NextNode
        }
      } else {
        tmpNode = tmpNode.NextNode
      }
    }
  }

  /*
	   插入一个新的落子，同时需要对当前的死子进行计算
	*/
  function StepIn(tmpColor, x, y, forJudge) {
    // console.log('插入新的落子')
    // console.log(tmpColor)
    var a = x
    var b = y
    var cColor = 0
    var hastizi = 0
    var tmpdeadstring = ''
    if (tmpColor == 1) {
      cColor = 2
    } else if (tmpColor == 2) {
      cColor = 1
    } else {
      return false
    }

    // 如果不是检查能不能落子的话，那么就直接落子

    if (forJudge !== true) {
      //DeepCopy(PreBoardArr, Board, 18);
      for (var i = 0; i <= 18; i++) {
        for (var j = 0; j <= 18; j++) {
          PreBoardArr[i][j] = Board[i][j]
        }
      }
    }

    Board[x][y] = tmpColor

    if (a < BoardSize - 1) {
      DeadString = ''
      rescult = 0
      if (Board[a + 1][b] == cColor) {
        getqi(a + 1, b)
        if (rescult == 0) {
          hastizi = 1
          tmpdeadstring = tmpdeadstring + DeadString
          DeleteDeadStone(cColor)
        }
      }
    }
    if (a > 0) {
      DeadString = ''
      rescult = 0
      if (Board[a - 1][b] == cColor) {
        getqi(a - 1, b)
        if (rescult == 0) {
          hastizi = 1
          tmpdeadstring = tmpdeadstring + DeadString
          DeleteDeadStone(cColor)
        }
      }
    }
    if (b < BoardSize - 1) {
      DeadString = ''
      rescult = 0
      if (Board[a][b + 1] == cColor) {
        getqi(a, b + 1)
        if (rescult == 0) {
          hastizi = 1
          tmpdeadstring = tmpdeadstring + DeadString
          DeleteDeadStone(cColor)
        }
      }
    }
    if (b > 0) {
      DeadString = ''
      rescult = 0
      if (Board[a][b - 1] == cColor) {
        getqi(a, b - 1)
        if (rescult == 0) {
          hastizi = 1
          tmpdeadstring = tmpdeadstring + DeadString
          DeleteDeadStone(cColor)
        }
      }
    }

    if (forJudge === true) {
      if (hastizi == 0) {
        rescult = 0
        DeadString = ''
        getqi(a, b)
        if (rescult != 0) {
          return true
        } else {
          return false
        }
      } else if (hastizi == 1) {
        if (tmpdeadstring.length == 3) {
          if (comparearray(PreBoardArr, Board, 18, 18) == true) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }
  }

  /*
	   计算一个点的气
	*/
  function getqi(a, b) {
    var tmpStr = TransferDToC(a) + TransferDToC(b) + ';'
    var qi = 0
    var ncolor = Board[a][b]
    var nextstr = ''
    if (ncolor == 0) {
      qi = qi + 1
      rescult = qi
      return rescult
    }
    if (b !== 0) {
      if (Board[a][b - 1] == 0) {
        qi = qi + 1
        rescult = qi
        return rescult
      } else if (Board[a][b - 1] == ncolor) {
        nextstr = TransferDToC(a) + TransferDToC(b - 1) + ';'
        if (DeadString.indexOf(tmpStr) == -1) {
          DeadString = DeadString + tmpStr
        }
        if (DeadString.indexOf(nextstr) == -1) {
          DeadString = DeadString + nextstr
          qi = qi + getqi(a, b - 1)
          qi = 0
        }
        if (qi !== 0) {
          rescult = qi
          DeadString = ''
          return rescult
        }
      }
    }
    if (a !== 0) {
      if (Board[a - 1][b] == 0) {
        qi = qi + 1
        rescult = qi
        return rescult
      } else if (Board[a - 1][b] == ncolor) {
        nextstr = TransferDToC(a - 1) + TransferDToC(b) + ';'
        if (DeadString.indexOf(tmpStr) == -1) {
          DeadString = DeadString + tmpStr
        }
        if (DeadString.indexOf(nextstr) == -1) {
          DeadString = DeadString + nextstr
          qi = qi + getqi(a - 1, b)
          qi = 0
        }
        if (qi !== 0) {
          rescult = qi
          DeadString = ''
          return rescult
        }
      }
    }
    if (b !== BoardSize - 1) {
      if (Board[a][b + 1] == 0) {
        qi = qi + 1
        rescult = qi
        return rescult
      } else if (Board[a][b + 1] == ncolor) {
        nextstr = TransferDToC(a) + TransferDToC(b + 1) + ';'
        if (DeadString.indexOf(tmpStr) == -1) {
          DeadString = DeadString + tmpStr
        }
        if (DeadString.indexOf(nextstr) == -1) {
          DeadString = DeadString + nextstr
          qi = qi + getqi(a, b + 1)
          qi = 0
        }
        if (qi !== 0) {
          rescult = qi
          DeadString = ''
          return rescult
        }
      }
    }
    if (a !== BoardSize - 1) {
      if (Board[a + 1][b] == 0) {
        qi = qi + 1
        rescult = qi
        return rescult
      } else if (Board[a + 1][b] == ncolor) {
        nextstr = TransferDToC(a + 1) + TransferDToC(b) + ';'
        if (DeadString.indexOf(tmpStr) == -1) {
          DeadString = DeadString + tmpStr
        }
        if (DeadString.indexOf(nextstr) == -1) {
          DeadString = DeadString + nextstr
          qi = qi + getqi(a + 1, b)
          qi = 0
        }
        if (qi !== 0) {
          rescult = qi
          DeadString = ''
          return rescult
        }
      }
    }
    if (qi == 0 && DeadString.indexOf(tmpStr) == -1) {
      rescult = qi
      DeadString = DeadString + tmpStr
    }

    if (qi != 0) {
      DeadString = ''
    }
  }

  /*
	  删除掉死子，顺便计算下当前的死棋数量
	*/

  function DeleteDeadStone(tmpColor) {
    var i = DeadString.length / 3
    if (tmpColor == 1) {
      BlackDead = BlackDead + i
    } else {
      WhiteDead = WhiteDead + i
    }
    var m = ''
    var n = ''
    var xx = 0
    var yy = 0
    for (var k = 0; k < i; k++) {
      m = DeadString.charAt(0 + 3 * k)
      n = DeadString.charAt(1 + 3 * k)
      xx = TransferCToD(m)
      yy = TransferCToD(n)
      Board[xx][yy] = 0
      NumbericBoard[xx][yy] = 0
    }
  }

  /*
	   将Board数组拷贝出来
	*/
  function CopyBoard(inBoard) {
    // console.log(inBoard)
    // debugger
    for (var i = 0; i < BoardSize; i++) {
      for (var j = 0; j < BoardSize; j++) {
        inBoard[i][j] = Board[i][j]
      }
    }
    // console.log(inBoard)
    //    debugger
  }

  /*
	  把数字棋盘的数组拷贝出来
	*/
  function CopyNumbericBoard(inBoard) {
    // console.log(inBoard)
    for (var i = 0; i < BoardSize; i++) {
      for (var j = 0; j < BoardSize; j++) {
        inBoard[i][j] = NumbericBoard[i][j]
      }
    }
    // debugger
  }

  /*
	   把作业时候的棋盘上的数字拷贝出来
	*/
  function CopyNumbericForWork(inBoard) {
    for (var i = 0; i < BoardSize; i++) {
      for (var j = 0; j < BoardSize; j++) {
        if (Board[i][j] == 0) {
          inBoard[i][j] = 0
        } else {
          inBoard[i][j] = NumbericBoardForWork[i][j]
        }
      }
    }
  }

  /*
	  把死棋的棋盘拷贝出来
	*/
  function CopyDeadBoard(inBoard) {
    for (var i = 0; i < BoardSize; i++) {
      for (var j = 0; j < BoardSize; j++) {
        inBoard[i][j] = DeadBoard[i][j]
      }
    }
    //console.log(DeadBoard);
  }

  /*
      获取最后一手的坐标
    */
  function GetCurrentStepPosition() {
    if (content == null) {
      //    debugger
      return [-1, -1, 0]
    }
    var tmpNode = content.CurrentNode
    // console.log(content)
    //    debugger
    if (tmpNode == null) {
      //    debugger
      return [-1, -1, 0]
    }
    if (tmpNode.y < 0) {
      //    debugger
      return [-1, -1, 0]
    }
    return [tmpNode.x, tmpNode.y, tmpNode.StepCount]
  }

  /*
	  AddStep  添加一手棋，如果当前节点不是最后一手，就当新增分支处理...
	  inStr 的格式为 B[AA];
	*/
  function AddStep(inStr) {
    if (content == null) {
      return
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      if (content.Count == 0) {
        // console.log('1111111111111111111111111111111111111')
        // 如果是一个空棋盘的话，那么就加入到第一手去
        content.ReadFromString(inStr)
        // debugger
        GenerateDrawArray()
        return
      } else {
        // something error
        return
      }
    }
    var ANewNode
    if (tmpNode.NextNode == null) {
      ANewNode = new TStepNode()
      if (tmpNode.parent != null) {
        ANewNode.KeyStr = tmpNode.parent.KeyStr + '_' + (tmpNode.StepCount + 1)
      } else {
        ANewNode.KeyStr = tmpNode.StepCount + 1
      }
      ANewNode.ReadFromString(inStr)
      ANewNode.StepCount = tmpNode.StepCount + 1
      tmpNode.NextNode = ANewNode
      ANewNode.PreNode = tmpNode
      ANewNode.parent = tmpNode.parent
      content.CurrentNode = ANewNode
      GotoLastStep()
    } else {
      // 添加为子节点
      var tmpSub = new TSteps()
      tmpSub.KeyStr = tmpNode.KeyStr + '_' + (tmpNode.Children.length + 1)
      tmpSub.ReadFromString(inStr)
      tmpNode.Children.push(tmpSub)
      // console.log('Children:' + tmpNode.Children)
      tmpSub.parentNode = tmpNode
      content.CurrentNode = tmpSub.FirstNode
      // console.log('2222222222222222222222222')
      // debugger
      GenerateDrawArray()
    }
  }
  /*
	   AddStepToLast  添加一手棋到最后一步,用于看棋和下棋
	*/
  function AddStepToLast(inStr) {
    if (content == null) {
      return
    }

    // console.log('Add to Last now ', inStr)

    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      // 当前棋谱为空
      let ANewNode = new TStepNode()
      ANewNode.ReadFromString(inStr)
      ANewNode.StepCount = 1
      content.CurrentNode = ANewNode
      content.FirstNode = ANewNode
      // console.log('444444444444444444444444444444')
      // debugger
      GenerateDrawArray()
      return true
    }
    // 检查下是不是在分支中..
    while (1) {
      if (tmpNode == null) {
        return
      }
      if (tmpNode.parent == null || tmpNode.parent == content) {
        break
      }
      if (tmpNode.parent.parentNode == null) {
        break
      }
      tmpNode = tmpNode.parent.parentNode
    }
    while (tmpNode.NextNode != null) {
      tmpNode = tmpNode.NextNode
    }
    let ANewNode = new TStepNode()
    ANewNode.ReadFromString(inStr)

    tmpNode.NextNode = ANewNode
    ANewNode.PreNode = tmpNode
    ANewNode.StepCount = tmpNode.StepCount + 1
    content.IncCount()
    if (tmpNode == content.CurrentNode) {
      content.CurrentNode = ANewNode
      // console.log('5555555555555555555555555555555555')
      // debugger
      GenerateDrawArray()
      return true
    } else {
      return false
    }
  }
  /*
		处理收到的评论
	*/
  function ReceiveACommentIn(strr) {
    //1.不能有特殊字符：“<>/[];”
    // var pattern = new RegExp(strr);
    var pattern = new RegExp('[<>/[];]/')
    // console.log("pattern:" + pattern.test(strr));
    if (pattern.test('das>')) {
      // console.log('输入了特殊字符')
      return Error
    } else {
      // strr = "<C" + strr + "/C>"
      AddComment(strr)
    }
  }
  /*
   		返回一个当前声音的编号
   */
  function AddAudioNum() {
    var tmpNode
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    if (tmpNode.AudioStr == null) {
      // console.log('AudioStr=============================' + tmpNode.AudioStr)
      // console.log('AudioNum=============================' + AudioNum)
      AudioNum = AudioNum + 1
      // console.log('AudioNum返回：' + AudioNum)
      return String(AudioNum)
    } else if (tmpNode.AudioStr != null) {
      return String(AudioNum)
    }
  }
  /*
		添加声音到当前节点
	*/
  function AddAudio(str, num) {
    var tmpNode
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    if (str == 'Delete') {
      AudioNum = AudioNum - 1
      tmpNode.AudioLen = null
      return AudioNum
    }
    if (str == 'DeletVoice') {
      //qgl改
      // console.log('删除该节点声音记录')
      tmpNode.AudioLen = null
      tmpNode.AudioStr = null
    }
    //返回的是重新录的编号
    if (str == 'NowVoice') {
      // console.log('声音')
      if (tmpNode.AudioStr != null) {
        // console.log('声音字段为：' + tmpNode.AudioStr)
        return tmpNode.AudioStr
      }
    }
    if (str == 'GetFileLength') {
      if (tmpNode.AudioStr == null) {
        tmpNode.AudioLen = num
        // return
      }
    }
    //f返回的是正常录的编号
    if (str == 'GetFileNum') {
      if (tmpNode.AudioStr == null) {
        AudioNum = AudioNum + 1
        return AudioNum
      } else if (tmpNode.AudioStr != null) {
        return AudioNum
      }
    }

    if (str == 'AddVoice') {
      tmpNode.AudioStr = num
      // console.log('声音文件路径为' + tmpNode.AudioStr)
    }
  }
  //改变录音编号
  function changeAudioNum(num) {
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    // console.log('改变前的AdudioNum值：' + AudioNum)
    AudioNum = Number(num)
  }
  /*
		返回当前节点声音
   */
  function getCurrentVoice() {
    var tmpNode
    var str = ''
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode

    if (tmpNode.AudioStr == null) {
      // console.log(tmpNode)
      return
    } else {
      str = tmpNode.AudioStr
      // console.log('444===' + str)
      return str
    }
  }
  /*
		判断当前手是否有声音
	*/
  function HasVoice() {
    var tmpNode
    var str = ''
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    if (tmpNode.AudioStr == null) {
      return null
    } else if (tmpNode.AudioStr != null) {
      return 'notnull'
    }
  }
  /*
		添加评论到当前节点
	*/
  function AddComment(strr) {
    var tmpNode
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    tmpNode.CommentStr = strr
    // debugger
    if (strr == '') {
      tmpNode.CommentStr = null
      // debugger
    }
    // tmpNode.StepComment = StepComment + 1;
    // CommentList.push(tmpNode);
  }
  /*
		添加图形点到当前节点（数组形式）
	*/
  function AddGraphic(str, cover) {
    var covers = cover || ''
    var tmpNode
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    if (tmpNode.GraphStr == null) {
      tmpNode.GraphStr = str
    } else if (tmpNode.GraphStr != null) {
      var tmpStr = ''
      var tmpArr = []
      var tmpStr1 = ''
      var tmpType = ''
      for (var i = 0; i < tmpNode.GraphStr.length; i = i + 3) {
        tmpStr = tmpNode.GraphStr.slice(i, i + 3)
        tmpStr = tmpStr.trim()
        tmpArr.push(tmpStr)
      }
      tmpType = str.substr(0, 1)
      tmpStr = str.slice(1)
      var ok = false
      // 这里修改下，如果有标记点，判断下是否是相同的标记点，相同的就删除，否则就替换...
      for (let i = 0; i < tmpArr.length; i++) {
        var SS1 = tmpArr[i]
        var S1 = SS1.substr(0, 1)
        var S2 = SS1.slice(1)
        if (S2 == tmpStr) {
          // 相同的点...
          ok = true
          if (S1 == tmpType) {
            // 相同的标记, 删除...
            if (!covers) {
              tmpArr.splice(i, 1)
            }
            break
          } else {
            // 不同的标记, 替换...
            if (!covers) {
              tmpArr[i] = tmpType + tmpStr
            }
            break
          }
        }
      }
      // 重新生成GraphStr...
      if (ok) {
        tmpNode.GraphStr = ''
        for (let i = 0; i < tmpArr.length; i++) {
          tmpNode.GraphStr = tmpNode.GraphStr + tmpArr[i]
        }
        if (covers) {
          return true
        }
      } else {
        tmpNode.GraphStr = tmpNode.GraphStr + tmpType + tmpStr
      }
    }
  }

  /*
		获取当前评论是第几个评论
	*/
  function getStepComment(tmp) {
    //1.首先显示当前评论是第几个（评论）:从上往下查，看是第几个，直接显示（如果当前手没有评论就显示其上个评论,通过点击评论来获得，传个参数）
    //2.然后再看点击后是第几个评论：从上往下查看在第几个，再显示。
    var tmpNode
    var notReturn = null
    var StepComment = 0
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    if (tmp == true) {
      if (
        content.CurrentNode.Children.length > 0 ||
        content.CurrentNode.CommentStr != null ||
        content.CurrentNode.AudioStr
      ) {
        StepComment = StepComment + 1
      }
      while (1) {
        //先判断当前节点是不是在主谱里
        if (tmpNode.parent.parentNode == null) {
          if (tmpNode.PreNode == null) {
            return StepComment
          }
          tmpNode = tmpNode.PreNode
          notReturn = null
          if (tmpNode.Children.length > 0) {
            //看这个节点有几个孩子，从最后一个孩子的最后一个点开始查
            let num = tmpNode.Children.length
            tmpNode = tmpNode.Children[num - 1].FirstNode
            while (tmpNode.NextNode != null) {
              tmpNode = tmpNode.NextNode
            }
          }
        } else if (tmpNode.PreNode != null) {
          tmpNode = tmpNode.PreNode
          //如果是上一手有变化，需要进入到当前变化的最后一个孩子
          if (tmpNode.Children.length > 0) {
            notReturn = true
          }
        } else if (tmpNode.parent == null) {
          return
        } else if (tmpNode.parent.parentNode != null) {
          // tmpNode = tmpNode.parent.parentNode;
          if (tmpNode.parent.parentNode.Children.length == 1) {
            notReturn = null
            tmpNode = tmpNode.parent.parentNode
          } else {
            for (var i = 0; i < tmpNode.parent.parentNode.Children.length; i++) {
              if (tmpNode == tmpNode.parent.parentNode.Children[i].FirstNode) {
                if (i == 0) {
                  tmpNode = tmpNode.parent.parentNode
                  notReturn = null
                  break
                } else {
                  tmpNode = tmpNode.parent.parentNode.Children[i - 1].FirstNode
                  while (tmpNode.NextNode != null) {
                    tmpNode = tmpNode.NextNode
                  }
                }
              }
            }
          }
        }
        if (tmpNode.Children.length > 1 && notReturn != null) {
          //看这个节点有几个孩子，从最后一个孩子的最后一个点开始查
          let num = tmpNode.Children.length
          tmpNode = tmpNode.Children[num - 1].FirstNode
          while (tmpNode.NextNode != null) {
            tmpNode = tmpNode.NextNode
          }
        }
        var a = tmpNode.AnalyseNode()
        if (a != null) {
          // content.CurrentNode = tmpNode;
          StepComment = StepComment + 1
          // console.log('上一步的棋子是：' + content.CurrentNode.StepStr)
          // break;
        }
      }
    }
  }
  /*
		返回当前节点评论
	*/
  function getCurrentComment() {
    var tmpNode
    var str = ''
    if (content == null) {
      // debugger
      return
    }
    if (content.CurrentNode == null) {
      // debugger
      return
    }
    tmpNode = content.CurrentNode
    // debugger
    if (tmpNode.CommentStr == null) {
      // debugger
      return
    } else {
      str = tmpNode.CommentStr
      // debugger
      return str
    }
  }
  /*
	  返回主谱
	*/
  function JumpToMain(reDraw) {
    if (content == null) {
      return
    }
    var tmpNode = content.CurrentNode
    var ok = false
    while (1) {
      if (tmpNode == null) {
        return
      }
      if (tmpNode.parent == null) {
        break
      }
      if (tmpNode.parent.parentNode != null) {
        tmpNode = tmpNode.parent.parentNode
        ok = true
      } else {
        break
      }
    }
    if (reDraw == true) {
      if (ok == true) {
        content.CurrentNode = tmpNode
        // console.log('6666666666666666666666666666')
        //    debugger
        GenerateDrawArray()
      }
    } else {
      return tmpNode
    }
  }
  /*
		显示当前手的变化图
	*/
  function GoInToVaryLast(str) {
    var tmpStr = str
    var nowChild = 0
    var arr = tmpStr.split('-')
    nowChild = parseInt(arr[arr.length - 1]) - 1
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    if (content.CurrentNode.Children.length <= 0) {
      return
    }
    // var arr = FormCurrentVary();
    if (nowChild >= content.CurrentNode.Children.length) {
      return
    }
    content.CurrentNode = content.CurrentNode.Children[nowChild].CurrentNode
    //根据当前是第几个孩子来判断
    while (content.CurrentNode.NextNode != null) {
      content.CurrentNode = content.CurrentNode.NextNode
    }
    // console.log('77777777777777777777777777777777')
    // debugger
    GenerateDrawArray()
  }
  function DrawNowSteps() {
    content.CurrentNode.Children
  }
  /*
		返回当前手的变化图
	*/
  function FormCurrentVary() {
    var tmpNode
    var arr = []
    var str = ''
    var tmpArr = []
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    if (content.CurrentNode.Children.length <= 0) {
      return
    } else if (content.CurrentNode.Children.length > 0) {
      for (let i = 1; i <= content.CurrentNode.Children.length; i++) {
        str = ''
        str = i
        tmpArr.push(str)
      }
      str = content.CurrentNode.StepCount + '-'
      // Need modify,
      // content.CurrentNode.parent == null  ?
      if (content.CurrentNode.parent == null) {
        return
      }
      tmpNode = content.CurrentNode.parent.parentNode
      while (tmpNode != null) {
        str = tmpNode.StepCount + '-' + str
        if (tmpNode.parent != null) {
          tmpNode = tmpNode.parent.parentNode
        }
      }
      for (let i = 0; i < tmpArr.length; i++) {
        var tmpStr = ''
        tmpStr = str + tmpArr[i]
        arr.push(tmpStr)
      }
      return arr
    }
  }
  /*
		CountComment
		计算当前评论数量
	*/
  function CountComment() {
    var tmpNode
    var CountNum = 0
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    // CountNum = CountNum + content.CountComment();
    CountNum = content.CountComment()
    // console.log('评论和变化的总数是：' + CountNum)
    return CountNum
  }
  /*
		GotoPreComment
		获取上一步评论并返回
		返回的是某个棋子的评论（包括评论或变化）
	*/
  function GotoPreComment() {
    var tmpNode
    var notReturn = null
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    while (1) {
      //先判断当前节点是不是在主谱里
      if (tmpNode.parent.parentNode == null) {
        if (tmpNode.PreNode == null) {
          return
        }
        tmpNode = tmpNode.PreNode
        notReturn = true
        if (tmpNode.Children.length > 0) {
          //看这个节点有几个孩子，从最后一个孩子的最后一个点开始查
          let num = tmpNode.Children.length
          tmpNode = tmpNode.Children[num - 1].FirstNode
          while (tmpNode.NextNode != null) {
            tmpNode = tmpNode.NextNode
          }
        }
      } else if (tmpNode.PreNode != null) {
        tmpNode = tmpNode.PreNode
        //如果是上一手有变化，需要进入到当前变化的最后一个孩子
        if (tmpNode.Children.length > 0) {
          notReturn = true
        }
      } else if (tmpNode.parent == null) {
        return
      } else if (tmpNode.parent.parentNode != null) {
        // tmpNode = tmpNode.parent.parentNode;
        if (tmpNode.parent.parentNode.Children.length == 1) {
          notReturn = true
          tmpNode = tmpNode.parent.parentNode
        } else {
          for (var i = 0; i < tmpNode.parent.parentNode.Children.length; i++) {
            if (tmpNode == tmpNode.parent.parentNode.Children[i].FirstNode) {
              if (i == 0) {
                tmpNode = tmpNode.parent.parentNode
                break
              } else {
                tmpNode = tmpNode.parent.parentNode.Children[i - 1].FirstNode
                while (tmpNode.NextNode != null) {
                  tmpNode = tmpNode.NextNode
                }
              }
            }
          }
        }
      }
      if (tmpNode.Children.length > 1 && notReturn != null) {
        //看这个节点有几个孩子，从最后一个孩子的最后一个点开始查
        let num = tmpNode.Children.length
        tmpNode = tmpNode.Children[num - 1].FirstNode
        while (tmpNode.NextNode != null) {
          tmpNode = tmpNode.NextNode
        }
      }
      var a = tmpNode.AnalyseNode()
      if (a != null) {
        content.CurrentNode = tmpNode
        // console.log('上一步的棋子是：' + content.CurrentNode.StepStr)
        break
      }
    }
    // console.log('8888888888888888888888888888')
    // debugger
    GenerateDrawArray()
    // }
  }
  /*
		GotoNextComment
		获取下一步评论并返回
		返回的是某个棋子的评论（包括评论或变化）
	*/
  function GotoNextComment() {
    var tmpNode
    var con = 1
    var currentParent = 0
    var currentParent1 = 0
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    tmpNode = content.CurrentNode
    while (1) {
      if (tmpNode == null) {
        return false
      }
      if (con > 1) {
        var a = tmpNode.AnalyseNode()
        if (a != null) {
          content.CurrentNode = tmpNode
          break
        }
      }
      con++
      if (tmpNode.Children.length > 0) {
        tmpNode = tmpNode.Children[0].FirstNode
      } else {
        if (tmpNode.NextNode == null) {
          if (tmpNode.parent.parentNode == null) {
            //如果到最后一步，后面没有了，就退出
            break
          }
          //这里开始找当前节点在哪个分支里面
          var num = tmpNode.parent.parentNode.Children.length - 1 //孩子总个数
          var tmpNode1 = tmpNode.parent.parentNode.Children[currentParent].FirstNode
          while (tmpNode1 != tmpNode) {
            if (tmpNode1.NextNode != null) {
              tmpNode1 = tmpNode1.NextNode
            } else {
              currentParent1 = currentParent1 + 1 //当前分支数加一
              if (currentParent1 > num) {
                currentParent1 = num
              }
              tmpNode1 = tmpNode.parent.parentNode.Children[currentParent1].FirstNode //进入下一分支的头结点
            }
          }
          //找到以后
          var tmpS1 = tmpNode.parent
          if (tmpS1 == null) {
            return false
          }
          //如果还有下一分支，就进下一分支查
          if (num > currentParent1) {
            tmpNode = tmpNode.parent.parentNode.Children[currentParent1 + 1].FirstNode
            currentParent = currentParent + 1
          } else {
            tmpNode = tmpNode.parent.parentNode
            if (tmpNode != null) {
              tmpNode = tmpNode.NextNode
            }
          }
        } else {
          tmpNode = tmpNode.NextNode
        }
      }
    }
    // console.log('999999999999999999999999999999999')
    // debugger
    GenerateDrawArray()
  }

  /**
   * 进入到下一个变化去..
   */
  function GotoNextSub() {
    var con = 1
    var currentParent = 0
    var currentParent1 = 0
    if (content == null) {
      return
    }

    var tmpNode = content.CurrentNode
    if (!tmpNode) {
      return
    }
    while (1) {
      if (tmpNode == null) {
        return false
      }
      if (con > 1) {
        var a = tmpNode.children
        if (a != null && a.length > 0) {
          content.CurrentNode = tmpNode
          break
        }
      }
      con++
      if (tmpNode.Children.length > 0) {
        tmpNode = tmpNode.Children[0].FirstNode
      } else {
        if (tmpNode.NextNode == null) {
          if (tmpNode.parent.parentNode == null) {
            //如果到最后一步，后面没有了，就退出
            break
          }
          //这里开始找当前节点在哪个分支里面
          var num = tmpNode.parent.parentNode.Children.length - 1 //孩子总个数
          var tmpNode1 = tmpNode.parent.parentNode.Children[currentParent].FirstNode
          while (tmpNode1 != tmpNode) {
            if (tmpNode1.NextNode != null) {
              tmpNode1 = tmpNode1.NextNode
            } else {
              currentParent1 = currentParent1 + 1 //当前分支数加一
              if (currentParent1 > num) {
                currentParent1 = num
              }
              tmpNode1 = tmpNode.parent.parentNode.Children[currentParent1].FirstNode //进入下一分支的头结点
            }
          }
          //找到以后
          var tmpS1 = tmpNode.parent
          if (tmpS1 == null) {
            return false
          }
          //如果还有下一分支，就进下一分支查
          if (num > currentParent1) {
            tmpNode = tmpNode.parent.parentNode.Children[currentParent1 + 1].FirstNode
            currentParent = currentParent + 1
          } else {
            tmpNode = tmpNode.parent.parentNode
            if (tmpNode != null) {
              tmpNode = tmpNode.NextNode
            }
          }
        } else {
          tmpNode = tmpNode.NextNode
        }
      }
    }
    // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    // debugger
    GenerateDrawArray()
  }

  /*
	   GetCurrentSub
	   获取当前节点的分支信息，为棋盘上呈现做准备
	   返回的时候是一个二维数组
	*/
  function GetCurrentSub() {
    if (content == null) {
      return null
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      return null
    }
    if (tmpNode.Children == null) {
      return null
    }
    if (tmpNode.Children.length <= 0) {
      return null
    }
    if (tmpNode.NextNode == null) {
      return null
    }
    var RArr = new Array()
    var tmpNode1
    RArr.push([tmpNode.NextNode.x, tmpNode.NextNode.y])
    for (var i = 0; i < tmpNode.Children.length; i++) {
      tmpNode1 = tmpNode.Children[i].FirstNode
      if (tmpNode1 == null) {
        continue
      }
      RArr.push([tmpNode1.x, tmpNode1.y])
    }
    return RArr
  }

  /*
	   检查下当前是否在分支中
	*/
  function CheckInSub() {
    return NowInSubSteps
  }

  /*
	   获取当前的手数信息,包括黑白子的提子信息
	   返回一个数组  [当前手数, 总手数, BlackDead, WhiteDead]
	*/
  function GetStepInfo() {
    if (content == null) {
      // console.log('1111111111111111111111')
      return null
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      return null
    }
    if (tmpNode.parent != null) {
      return [tmpNode.StepCount, tmpNode.parent.GetTotalCount(), BlackDead, WhiteDead]
    } else {
      return [tmpNode.StepCount, content.GetTotalCount(), BlackDead, WhiteDead]
    }
  }

  /**
   * 获取当前的总手数
   */
  function GetCount() {
    if (content == null) {
      return
    }
    var Count = content.GetTotalCount()
    return Count
  }

  /*
	   获取当前的颜色值
	   Return {1 黑子}{2 白子}{10 标记气的落子}
	*/
  function GetCurrentColor() {
    if (content == null) {
      return 0
    }
    if (content.CurrentNode == null) {
      return 0
    }
    if (content.CurrentNode.TheGStr && content.CurrentNode.TheGStr != '') {
      return 10
    }
    return content.CurrentNode.color
  }

  /**
   * 获取下最后一个落子的颜色，包含形的最后一手...
   */
  function GetLastStepColor() {
    if (content == null) {
      return 0
    }
    if (content.CurrentNode == null) {
      return 0
    }
    var tmpNode = content.CurrentNode
    if (tmpNode.x == -3) {
      return tmpNode.ShapeArr[tmpNode.ShapeArr.length - 1][0]
    } else {
      return tmpNode.color
    }
  }

  /*
	   收到一颗落子
	   参数：2个落子的位置坐标，其他的都有里面来算
	*/
  function ReceiveAStepIn(x, y) {
    if (x < 0 || x >= BoardSize) {
      return false
    }
    if (y < 0 || y >= BoardSize) {
      return false
    }
    var S = TransferDToC(x) + TransferDToC(y)
    var ii = GetCurrentColor()
    if (ii == 0 || ii == 2) {
      S = 'B[' + S + '];'
    } else {
      S = 'W[' + S + '];'
    }
    if (CheckNextNode(x, y) == false) {
      // console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
      // debugger
      GenerateDrawArray()
    } else {
      AddStep(S)
    }
    return S
  }

  /**
   * 收到一颗带颜色的落子...
   */
  function ReceiveAStepInWithColor(x, y, color) {
    if (x < 0 || x >= BoardSize) {
      return false
    }
    if (y < 0 || y >= BoardSize) {
      return false
    }
    var S = TransferDToC(x) + TransferDToC(y)
    if ((color = 1)) {
      S = 'B[' + S + '];'
    } else {
      S = 'W[' + S + '];'
    }
    if (CheckNextNode(x, y) == false) {
      // console.log('CCCCCCCCCCCCCCCCCCCCCCC')
      // debugger
      GenerateDrawArray()
    } else {
      AddStep(S)
    }
  }

  /*
	   检查下当前落子点是不是下一步落子，或者是不是分支中的某一个head
	*/
  function CheckNextNode(x, y) {
    // console.log('CheckNextNode')
    if (content == null) {
      // debugger
      return true
    }
    if (content.CurrentNode == null) {
      // debugger
      return true
    }
    var tmpNode = content.CurrentNode
    if (tmpNode.NextNode != null) {
      let tmpNode1 = tmpNode.NextNode
      // debugger
      if (tmpNode1.x == x && tmpNode1.y == y) {
        content.CurrentNode = tmpNode1
        // debugger
        return false
      }
    }
    // console.log(tmpNode.Children.length)
    if (tmpNode.Children != null) {
      // debugger
      for (var i = 0; i < tmpNode.Children.length; i++) {
        if (tmpNode.Children[i] != null) {
          let tmpNode1 = tmpNode.Children[i].FirstNode
          // debugger
          if (tmpNode1 != null) {
            if (tmpNode1.x == x && tmpNode1.y == y) {
              // console.log('ok find the node')
              content.CurrentNode = tmpNode1
              // debugger
              return false
            }
          }
        }
      }
    }
    return true
  }

  /**
   * 作业棋谱，收到了一颗落子...
   * @param {*int} x
   * @param {*int} y
   */
  function ReceiveAStepInForWork(x, y, cc) {
    if (x < 0 || x >= BoardSize) {
      return -1
    }
    if (y < 0 || y >= BoardSize) {
      return -1
    }
    var S = TransferDToC(x) + TransferDToC(y)
    var ii = GetCurrentColor()
    // 如果当前手存在标记点，那么整个作业就是标记...
    if (content.CurrentNode && content.CurrentNode.TheGStr != '') {
      // 进入到标记模式..
      var rr = content.CurrentNode.ReceiveAGraph(S)
      if (rr == -1) {
        return -1
      } else if (rr == 1) {
        if (content.CurrentNode.TheGStr.length == content.CurrentNode.ReceiveGStr.length) {
          return 1
        } else {
          return 4
        }
      } else if (rr == 2) {
        return 5
      }
    }
    var cStr = ''
    var cok = false
    // console.log(cc)

    if (cc == 1) {
      cok = true
      cStr = 'B'
    } else if (cc == 2) {
      cok = true
      cStr = 'W'
    }
    if (ii == 4) {
      // 特殊处理下第一手为形的情况...
      StepInCountForWork = 1
      var tmpNode = content.CurrentNode.NextNode
      if (tmpNode != null) {
        if (cok) {
          S = cStr + '[' + S + '];'
        } else {
          if (tmpNode.color == 1) {
            S = 'B[' + S + '];'
          } else {
            S = 'W[' + S + '];'
          }
        }
      } else {
        if (cok) {
          S = cStr + '[' + S + '];'
        } else {
          if (this.FirstStepColor == 1) {
            S = 'B[' + S + '];'
          } else {
            S = 'W[' + S + '];'
          }
        }
      }
    } else if (ii == 0 || ii == 2) {
      if (cok) {
        S = cStr + '[' + S + '];'
      } else {
        S = 'B[' + S + '];'
      }
      StepInCountForWork++
    } else {
      if (cok) {
        S = cStr + '[' + S + '];'
      } else {
        S = 'W[' + S + '];'
      }
      StepInCountForWork++
    }
    NumbericBoardForWork[x][y] = StepInCountForWork
    // console.log(content.CurrentNode.NodeEnd)
    // console.log(content.CurrentNode.NextNode)
    if (CheckNextNode(x, y) == false) {
      // console.log('dddddddddddddddddddddddddddddd')
      // debugger
      GenerateDrawArray()
      if (content.CurrentNode.NodeEnd != null) {
        return content.CurrentNode.NodeEnd
      } else if (content.CurrentNode.NextNode != null) {
        return 2
      } else {
        return -1
      }
    } else {
      AddStep(S)
      // debugger;
      return -1
    }
  }

  /**
   * 用户按住了Ctrl或者是Shift的时候，去生成第一手的形...
   * @param {*} x
   * @param {*} y
   */
  function ReceiveAStepInWithCtrlOrShiftForWork(x, y, str) {
    // console.log('ReceiveAStepInWithCtrlOrShiftForWork')
    if (x < 0 || x >= BoardSize) {
      return -1
    }
    if (y < 0 || y >= BoardSize) {
      return -1
    }
    if (str != 'ctrl' && str != 'shift') {
      return -1
    }
    var S = TransferDToC(x) + TransferDToC(y)
    var tmpNode = content.CurrentNode
    while (tmpNode != null && tmpNode.NextNode != null) {
      tmpNode = tmpNode.NextNode
    }
    if (tmpNode == null) {
      // 当前棋盘为空...
      // console.log('ok the board is null')
      S = 'B[' + S + '];'
      AddStep(S)
      return -1
    } else {
      if (tmpNode.PreNode != null || tmpNode.parent.parentNode != null) {
        ReceiveAStepInForWork(x, y)
        return -1
      } else {
        // 把棋子加入到第一手里面去...
        var S1 = tmpNode.ShapeStr
        var S2 = ''
        if (!S1 || S1 == '') {
          S1 = 'B' + tmpNode.StepStr
        }
        if (S1 == '') {
          return -1
        }
        S2 = S1.charAt(0)
        S1 = S1.slice(1)
        // console.log('S2 = ', S2)
        // console.log('S1 = ', S1)
        if (str == 'ctrl') {
          S1 = S2 + '[' + S1 + 'W' + S + '];'
        } else if (str == 'shift') {
          S1 = S2 + '[' + S1 + 'B' + S + '];'
        }
        tmpNode.ReadFromString(S1)
        tmpNode.StepStr = ''
        // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeee')
        // debugger
        GenerateDrawArray()
        return -1
      }
    }
  }

  /**
   * 获取当前该哪一方落子...
   * 1 黑方  2 白方...
   */
  function GetCurrentStepinColor() {
    var ii = GetCurrentColor()
    if (ii == 4) {
      // 特殊处理下第一手为形的情况...
      var tmpNode = content.CurrentNode.NextNode
      if (tmpNode != null) {
        if (tmpNode.color == 1) {
          return 1
        } else {
          return 2
        }
      } else {
        tmpNode = content.Children[0].FirstNode
        if (tmpNode.color == 1) {
          return 1
        } else {
          return 2
        }
      }
    } else if (ii == 0 || ii == 2) {
      return 1
    } else {
      return 2
    }
  }

  /*
	  判断一个点是不是能落子
	  包括  是不是已经有棋子了，是不是禁着点，  是不是打劫
	*/
  function CheckCanSetpIn(x, y, color) {
    // console.log(x)
    // console.log(y)
    // console.log(color)
    if (x < 0 || x >= BoardSize) {
      return false
    }
    if (y < 0 || y >= BoardSize) {
      return false
    }
    if (Board[x][y] != 0) {
      return false
    }
    // 首先判断下是不是有气，然后再看下是不是打劫
    // 先将当前的Board 和NumbericBoard进行备份
    var BackBoard1 = new Array()
    var BackBoard2 = new Array()
    for (var i = 0; i < 19; i++) {
      BackBoard1[i] = new Array()
      BackBoard2[i] = new Array()
      for (j = 0; j < 19; j++) {
        BackBoard1[i][j] = 0
        BackBoard2[i][j] = 0
      }
    }
    DeepCopy(BackBoard1, Board, 18)
    DeepCopy(BackBoard2, NumbericBoard, 18)
    if (color) {
      ii = color
    } else {
      var ii = GetCurrentColor()
      if (ii == 0 || ii == 2 || ii == 4) {
        ii = 1
      } else {
        ii = 2
      }
    }

    if (StepIn(ii, x, y, true) == true) {
      DeepCopy(Board, BackBoard1, 18)
      DeepCopy(NumbericBoard, BackBoard2, 18)
      return true
    } else {
      DeepCopy(Board, BackBoard1, 18)
      DeepCopy(NumbericBoard, BackBoard2, 18)
      return false
    }
  }

  /**
   * 检查一下这个点是不是作业里面的正确的落子点...
   * @param {*} x
   * @param {*} y
   */
  function CheckIsRightStepIn(x, y) {
    if (x < 0 || x >= BoardSize) {
      return false
    }
    if (y < 0 || y >= BoardSize) {
      return false
    }
    var tmpNode = content.CurrentNode
    // console.log(tmpNode)
    if (tmpNode == null) {
      return false
    }
    var tmpNode1 = tmpNode.NextNode
    // console.log(tmpNode1)
    if (typeof tmpNode1 == 'undefined') {
      //cb加
      // console.log(tmpNode.NextNode)
      return false
    }

    if (tmpNode1.NextNode != null) {
      if (tmpNode1.x == x && tmpNode1.y == y) {
        if (tmpNode1.NextNode != null) {
          return true
        }
        if (tmpNode1.NodeEnd == 1) {
          // 正解
          return true
        }
      }
    }

    // 看一下是否有变化...
    if (tmpNode.Children != null) {
      for (var i = 0; i < tmpNode.Children.length; i++) {
        if (tmpNode.Children[i] != null) {
          tmpNode1 = tmpNode.Children[i].FirstNode
          // debugger
          if (tmpNode1 != null) {
            if (tmpNode1.x == x && tmpNode1.y == y) {
              if (tmpNode1.NextNode != null) {
                return true
              }
              if (tmpNode1.NodeEnd == 1) {
                // 正解
                return true
              }
            }
          }
        }
      }
    }
    return false
  }
  /*
		删除当前标记
	*/
  function DeleteNowGraph(str) {
    if (content == null) {
      return null
    }
    if (content.CurrentNode == null) {
      return null
    }
    var tmpNode = content.CurrentNode
    if (tmpNode.GraphStr == null) {
      return 'nomark'
    } else if (str == 'delete') {
      tmpNode.GraphStr = null
    }
    // console.log('ffffffffffffffff')
    // debugger
    GenerateDrawArray()
  }
  /*
		删除当前分支
	*/
  function DeleteNowParent(str) {
    var tmpNode
    var ChildNum = 0
    if (content == null) {
      return null
    }
    if (content.CurrentNode == null) {
      return null
    }
    if (content.CurrentNode.parent.parentNode == null) {
      return 'nobranch'
    }
    if (str == 'delete') {
      tmpNode = content.CurrentNode
      for (var i = 0; i < tmpNode.parent.parentNode.Children.length; i++) {
        var tmpNode1 = tmpNode.parent.parentNode.Children[i].FirstNode
        while (1) {
          if (tmpNode1 != tmpNode) {
            break
          } else if (tmpNode1 == tmpNode) {
            ChildNum = i
            break
          } else {
            // console.log('error')
            break
          }
        }
      }
      tmpNode = tmpNode.parent.parentNode
      tmpNode.Children.splice(ChildNum, 1)
      content.CurrentNode = tmpNode
    }
    // console.log('gggggggggggggggggggggggggg')
    // debugger
    GenerateDrawArray()
  }
  /*
		悔棋
	*/
  function RegretAtep(cmd) {
    if (content == null) {
      return null
    }
    if (content.CurrentNode == null) {
      return null
    }
    var S = ''
    var tmpNode = content.CurrentNode
    while (tmpNode.NextNode != null) {
      tmpNode = tmpNode.NextNode
    }
    if (content.CurrentNode == tmpNode) {
      //当前节点为最后一个节点
      if (tmpNode.PreNode == null) {
        if (tmpNode.parent.parentNode == null) {
          //处于分支中,回到父节点
          ClearAll()
          return null
          //前台要调用一个重新画变化图的方法
        } else {
          if (cmd != 'delete') {
            return 'showmodal'
          }
          var ChildNum = 0
          for (var i = 0; i < tmpNode.parent.parentNode.Children.length; i++) {
            var tmpNode1 = tmpNode.parent.parentNode.Children[i].FirstNode
            while (1) {
              if (tmpNode1 != tmpNode) {
                break
              } else if (tmpNode1 == tmpNode) {
                ChildNum = i
                break
              } else {
                // console.log('error')
                break
              }
            }
          }
          S = content.CurrentNode.KeyStr
          tmpNode = tmpNode.parent.parentNode
          tmpNode.Children.splice(ChildNum, 1)
          content.CurrentNode = tmpNode
        }
      } else {
        // console.log('Delete A Node')
        S = content.CurrentNode.KeyStr
        content.CurrentNode = content.CurrentNode.PreNode
        content.CurrentNode.NextNode = null
        content.SetCount(content.CurrentNode.StepCount)
        // uni.showToast({
        // 	title:"悔棋一手",
        // 	content:" ",
        // 	duration:800,
        // 	icon:"none",
        // })
      }
    } else {
      // uni.showModal({
      //   title: '当前手不是最后一手，无法悔棋',
      //   content: ' ',
      //   confirmText: '确定',
      //   success: (res) => {
      //     if (res.confirm) {
      //       console.log('点击了确定')
      //     }
      //   }
      // })
      // console.log('当前手不是最后一手，无法悔棋')
      return null
    }
    // debugger
    GenerateDrawArray()
    return S
  }
  /*
		滑动时获取当前棋子的位置
	*/
  function SliderToCurrentStep(CurrentValue) {
    var tmpNode
    if (content == null) {
      return null
    }
    if (content.CurrentNode == null) {
      return null
    }
    tmpNode = content.FirstNode
    while (1) {
      if (tmpNode.StepCount == CurrentValue) {
        content.CurrentNode = tmpNode
        break
      } else if (tmpNode.StepCount != CurrentValue) {
        if (tmpNode.NextNode != null) {
          tmpNode = tmpNode.NextNode
        } else if (tmpNode.NextNode == null) {
          content.CurrentNode = tmpNode
          break
        }
      } else {
        // console.log('error')
        break
      }
    }
    // console.log('iiiiiiiiiiiiiiiiiiiiiiiiii')
    // debugger
    GenerateDrawArray()
  }
  /*
	  获取当前节点的标记图标
	*/
  function GetOutGraphicStr() {
    if (content == null) {
      return null
    }
    if (content.CurrentNode == null) {
      return null
    }
    var S = content.CurrentNode.GraphStr
    var S1 = content.CurrentNode.TheGStr
    var S2 = content.CurrentNode.ReceiveGStr
    if (S == null && S1 == null) {
      return null
    }
    var RR = new Array()
    if (S != null) {
      S = S.trim()
      if (S != '') {
        while (S != '') {
          let R1 = [S.charAt(0), TransferCToD(S.charAt(1)), TransferCToD(S.charAt(2))]
          RR.push(R1)
          S = S.slice(3)
        }
      }
    }
    if (S1 != null) {
      S1 = S1.trim()
      if (S1 != '' && NowShowAnswer) {
        while (S1 != '') {
          let R1 = [5, TransferCToD(S1.charAt(0)), TransferCToD(S1.charAt(1))]
          RR.push(R1)
          S1 = S1.slice(2)
        }
      }
    }
    // 添加当前用户的落子点...
    if (S2 != null) {
      S2 = S2.trim()
      while (S2 != '') {
        let R1 = [5, TransferCToD(S2.charAt(0)), TransferCToD(S2.charAt(1))]
        RR.push(R1)
        S2 = S2.slice(2)
      }
    }
    // console.log(RR)
    // console.log(NowShowAnswer)
    //    debugger
    return RR
  }
  /*
      获取边界信息
   */
  function GetBounderInfo() {
    //分别找到上下左右的边界点
    return [MostLeft, MostRight, MostTop, MostBottom]
  }

  /*
	   getRemoveString
	   点击棋盘后，移出掉死子或者恢复之前被移除的死子
	*/
  function GetRemoveString(x, y) {
    var S = TransferDToC(x) + TransferDToC(y) + ';'
    var S1 = ''
    var II = 0
    if (Board[x][y] == 0) {
      return
    }
    CurDeadString = ''
    for (var i = 0; i < RemovedStringArr.length; i++) {
      S1 = RemovedStringArr[i]
      S1 = S1.slice(1)
      // console.log(S1)
      if (S1.indexOf(S) > 0) {
        CurDeadString = S1
        RemovedStringArr.splice(i, 1)
        break
      }
    }
    if (CurDeadString.indexOf(S) >= 0) {
      // undoRemove
      removeAllDeadStone(x, y, Board[x][y], 0)
      II = S.length - 1
      if (S.substr(0, 1) == 'B') {
        BlackDead = BlackDead - Math.floor(II / 3)
      } else {
        WhiteDead = WhiteDead - Math.floor(II / 3)
      }
    } else {
      // remove dead stone
      if (Board[x][y] == 1) {
        S1 = 'B'
      } else {
        S1 = 'W'
      }
      removeAllDeadStone(x, y, Board[x][y], 1)
      if (S1 == 'B') {
        BlackDead = BlackDead + Math.floor(CurDeadString.length / 3)
      } else if (S1 == 'W') {
        WhiteDead = WhiteDead + Math.floor(CurDeadString.length / 3)
      }
      if (CurDeadString != '') {
        S1 = S1 + CurDeadString
        if (RemovedStringArr.indexOf(S1) < 0) {
          RemovedStringArr.push(S1)
        }
      }
    }
  }

  /*
		Undo all remove
	*/
  function UndoRemoveAll() {
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        DeadBoard[i][j] = 0
      }
    }
    var S = ';'
    var II = 0
    for (let i = 0; i < RemovedStringArr.length; i++) {
      S = RemovedStringArr[i]
      II = S.length - 1
      if (S.substr(0, 1) == 'B') {
        BlackDead = BlackDead - Math.floor(II / 3)
      } else {
        WhiteDead = WhiteDead - Math.floor(II / 3)
      }
    }
    RemovedStringArr = []
  }

  /*
	   removeAllDeadStone
	*/
  function removeAllDeadStone(x, y, color, status) {
    if (Board[x][y] != color) {
      return
    }
    var S = TransferDToC(x) + TransferDToC(y) + ';'
    if (status == 1) {
      // 添加死子
      if (CurDeadString.indexOf(S) >= 0) {
        return
      }
      CurDeadString = CurDeadString + S
      if (color == 1) {
        DeadBoard[x][y] = 11
      } else if (color == 2) {
        DeadBoard[x][y] = 12
      }
    } else {
      // 移出死子
      if (CurDeadString.indexOf(S) < 0) {
        return
      }
      CurDeadString = CurDeadString.replace(S, '')
      DeadBoard[x][y] = 0
    }
    if (x >= 1) {
      removeAllDeadStone(x - 1, y, color, status)
    }
    if (x < BoardSize - 1) {
      removeAllDeadStone(x + 1, y, color, status)
    }
    if (y >= 1) {
      removeAllDeadStone(x, y - 1, color, status)
    }
    if (y < BoardSize - 1) {
      removeAllDeadStone(x, y + 1, color, status)
    }
  }

  /*
	   获取双方的目数
	*/
  function GetMu(inB, inW) {
    BlackMu = 0
    WhiteMu = 0
    // 首先生成sBoard数组
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        sBoard[i][j] = Board[i][j]
        if (DeadBoard[i][j] > 0) {
          sBoard[i][j] = 0
        }
      }
    }
    for (let i = 0; i < BoardSize; i++) {
      for (let j = 0; j < BoardSize; j++) {
        BoardString = ''
        MuDeadString = ''
        GetBelongString(i, j)
        if (BoardString.length < 3) {
          if (BoardString.indexOf('1') >= 0) {
            FillBoard(1)
          } else if (BoardString.indexOf('2') >= 0) {
            FillBoard(2)
          } else {
            FillBoard(3)
          }
        } else {
          FillBoard(3)
        }
      }
    }
    inB = BlackMu
    inW = WhiteMu
    // console.log('Black:', BlackMu, ',', WhiteDead)
    // console.log('White:', WhiteMu, ',', BlackDead)
  }

  /*
	   GetBelongString
	   用于本地计算目数
	*/
  function GetBelongString(i, j) {
    var nColor = sBoard[i][j]
    var uk = ''
    if (nColor != 0) {
      if (nColor == 1) {
        uk = '1;'
      } else if (nColor == 2) {
        uk = '2;'
      } else {
        uk = '3;'
      }
      if (BoardString.indexOf(uk) < 0) {
        BoardString = BoardString + uk
      }
      return
    }
    var tmpStr = TransferDToC(i + 1) + TransferDToC(j) + ';'
    if (MuDeadString.indexOf(tmpStr) >= 0) {
      return
    }
    if (i > 0) {
      nColor = sBoard[i - 1][j]
      if (nColor != 0) {
        if (nColor == 1) {
          uk = '1;'
        } else if (nColor == 2) {
          uk = '2;'
        } else {
          uk = '3;'
        }
        if (BoardString.indexOf(uk) < 0) {
          BoardString = BoardString + uk
        }
      } else if (nColor == 0) {
        if (MuDeadString.indexOf(tmpStr) < 0) {
          MuDeadString = MuDeadString + tmpStr
        }
        GetBelongString(i - 1, j)
      }
    }

    if (i < BoardSize - 1) {
      nColor = sBoard[i + 1][j]
      if (nColor != 0) {
        if (nColor == 1) {
          uk = '1;'
        } else if (nColor == 2) {
          uk = '2;'
        } else {
          uk = '3;'
        }
        if (BoardString.indexOf(uk) < 0) {
          BoardString = BoardString + uk
        }
      } else {
        if (MuDeadString.indexOf(tmpStr) < 0) {
          MuDeadString = MuDeadString + tmpStr
        }
        GetBelongString(i + 1, j)
      }
    }

    if (j > 0) {
      nColor = sBoard[i][j - 1]
      if (nColor != 0) {
        if (nColor == 1) {
          uk = '1;'
        } else if (nColor == 2) {
          uk = '2;'
        } else {
          uk = '3;'
        }
        if (BoardString.indexOf(uk) < 0) {
          BoardString = BoardString + uk
        }
      } else {
        if (MuDeadString.indexOf(tmpStr) < 0) {
          MuDeadString = MuDeadString + tmpStr
        }
        GetBelongString(i, j - 1)
      }
    }

    if (j < BoardSize - 1) {
      nColor = sBoard[i][j + 1]
      if (nColor != 0) {
        if (nColor == 1) {
          uk = '1;'
        } else if (nColor == 2) {
          uk = '2;'
        } else {
          uk = '3;'
        }
        if (BoardString.indexOf(uk) < 0) {
          BoardString = BoardString + uk
        }
      } else {
        if (MuDeadString.indexOf(tmpStr) < 0) {
          MuDeadString = MuDeadString + tmpStr
        }
        GetBelongString(i, j + 1)
      }
    }
  }

  /*
	   FillBaord
	   用于本地计算目数
	*/
  function FillBoard(inType) {
    if (MuDeadString == '') {
      return
    }
    if (inType == 0) {
      return
    }
    var S = ''
    var II = 0
    var x, y
    while (MuDeadString != '') {
      II = MuDeadString.indexOf(';')
      if (II < 0) {
        return
      }
      S = MuDeadString.substr(0, II + 1)
      MuDeadString = MuDeadString.substr(II + 1)
      x = TransferCToD(S.substr(0, 1)) - 1
      y = TransferCToD(S.substr(1, 1))
      if (inType == 1) {
        sBoard[x][y] = 10
        BlackMu++
      } else if (inType == 2) {
        sBoard[x][y] = 20
        WhiteMu++
      } else {
        sBoard[x][y] = 30
      }
    }
  }

  /*
	  SaveToFile
	  把棋谱保存成本地文件.ctn
	*/
  function SaveToFile() {
    var S = ''
    var S1 = String.fromCharCode(13) + String.fromCharCode(10)
    S = '<Basic_info CopyRight="新博围棋"' + S1
    S = S + 'CommentBy=""' + S1
    S = S + 'MainVersion="0"' + S1
    S = S + 'MinorVersion="0"' + S1
    S = S + 'BlackPlayer="' + BlackName + '"' + S1
    S = S + 'WhitePlayer="' + WhiteName + '"' + S1
    S = S + 'MatchName=""' + S1
    S = S + 'MatchResult="' + MatchResult + '"' + S1
    S = S + 'MatchLocation="少儿围棋教学对弈平台"' + S1
    S = S + 'MatchTime="2019-01-01" />' + S1
    S = S + '<Board size="' + BoardSize + '"' + S1
    S = S + 'type="0" />' + S1
    S = S + '<RefDataURI link="#" />' + S1
    S = S + '<Init> <![CDATA[' + S1 + 'I[00];' + S1 + ']]>' + S1 + '</Init>' + S1
    S = S + '<Steps> <![CDATA[' + S1 + SaveToString() + S1 + ']]>' + S1 + '</Steps>' + S1
    return S
  }

  /*
	   获取从第一手到当前手的String
	*/
  function GetToCurrent(inType) {
    // console.log(inType)
    if (content == null) {
      return
    }
    if (content.CurrentNode == null) {
      return
    }
    var S = ''
    var tmpNode = content.CurrentNode
    while (tmpNode != null) {
      if (inType) {
        S = tmpNode.SaveToString() + S
      } else {
        S = tmpNode.SaveToString_Simple() + S
      }

      if (tmpNode.PreNode != null) {
        tmpNode = tmpNode.PreNode
      } else {
        if (tmpNode.parent == null) {
          return S
        }
        if (tmpNode.parent.parentNode == null) {
          return S
        }
        tmpNode = tmpNode.parent.parentNode
      }
    }
  }

  function getTimuText() {
    if (content == null) {
      // debugger
      return ''
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      // debugger
      return ''
    }
    while (1) {
      if (tmpNode.PreNode != null) {
        tmpNode = tmpNode.PreNode
      } else {
        if (tmpNode.parent != null && tmpNode.parent.parentNode != null) {
          tmpNode = tmpNode.parent.parentNode
        } else {
          // debugger
          return tmpNode.CommentStr
        }
      }
    }
    // debugger
  }

  /*
	  查看答案
	*/
  function ShowAnswer(param) {
    // debugger
    if (NowShowAnswer) {
      return
    }
    NowShowAnswer = param
    // debugger
    if (content == null) {
      return
    }
    if (content.TheAnswer == null) {
      WriteStart = 1
      return
    }
    if (content.TheAnswer == '') {
      // 如果没有<M的答案，看下第一手是不是有形
      WriteStart = 1
      return
    }
    ClearAll()
    var S = content.TheAnswer
    WriteStart = 1
    content.ReadFromString(S)
  }

  /*
	  查看下错解
	*/
  function CheckErrorAction(ErrorStr) {
    if (content == null) {
      return
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      return
    }
    while (tmpNode.PreNode != null) {
      tmpNode = tmpNode.PreNode
    }
    var S = tmpNode.SaveToString()
    S = S + ErrorStr
    ClearAll()
    // console.log(S)
    WriteStart = 1
    content.ReadFromString(S)
  }

  /*
	  取消查看答案
	*/
  function CancelAnswer() {
    if (!NowShowAnswer) {
      return
    }
    NowShowAnswer = false
    ClearAll()
    WriteStart = 0
    content.ReadFromString(OriString)
  }

  /**
   * 生成棋谱树所需要的数据...
   */
  function GenerateTreeData() {
    // 从第一手开始生成数据..
    var resArr = []
    if (content == null) {
      return resArr
    }
    resArr = content.GenerateTreeData()
    return resArr
  }

  /**
   * 获取当前节点的数据，用于棋谱树的显示...
   */
  function GetCurrentNodeInfo() {
    if (content == null) {
      return
    }
    var tmpNode = content.CurrentNode
    if (tmpNode == null) {
      return
    }
    return tmpNode.GenerateTreeData()
  }

  /**
   * 通过KeyStr来切换棋盘上的显示,这个用于和棋谱树之间的互动...
   * @param {*} inStr
   */
  function JumpPointByKeyStr(inStr) {
    if (content == null) {
      return
    }
    var tmpNode = content.GetANodeByKeyStr(inStr)
    if (tmpNode == null) {
      return
    }
    content.CurrentNode = tmpNode
    // console.log('jjjjjjjjjjjjjjjjjjjjjjjj')
    // debugger
    GenerateDrawArray()
  }

  function SetEndTypeByKeyStr(inStr, inValue) {
    if (content == null) {
      return
    }
    var tmpNode = content.GetANodeByKeyStr(inStr)
    if (tmpNode == null) {
      return
    }
    tmpNode.NodeEnd = inValue
  }

  /**
   * 获取当前node的KeyStr
   */
  function GetCurrentNodeKeyStr() {
    if (!content) {
      return ''
    }
    var tmpNode
    tmpNode = content.CurrentNode
    if (!tmpNode) {
      return ''
    }
    return tmpNode.KeyStr
  }

  /**
   * 为一道作业设置题目...
   * @param {*} inStr
   */
  function setTitle(inStr) {
    var tmpNode
    if (!content) {
      return
    }

    tmpNode = content.CurrentNode
    if (!tmpNode) {
      return
    }

    while (1) {
      if (tmpNode.parent.parentNode) {
        // 如果在分支里面，先跳出来...
        tmpNode = tmpNode.parent.parentNode
        continue
      }
      if (tmpNode.PreNode) {
        tmpNode = tmpNode.PreNode
        continue
      }
      tmpNode.CommentStr = inStr
      // debugger
      break
    }
  }

  function CheckTimuType() {
    if (!content) {
      return
    }
    if (
      content.FirstNode.TheGStr &&
      content.FirstNode.TheGStr != '' &&
      content.FirstNode.NextNode == null
    ) {
      return '1'
    }
  }

  /**
   * 设置下作业棋谱是黑先还是白先...
   * 1 黑先..
   * 2 白先..
   * @param {*} inColor
   */
  function SetWorkFirstColor(inColor) {
    this.FirstStepColor = inColor
  }

  /**
   * 获取第一手形所对应的string...
   */
  function GetShapeStrint() {
    return content.FirstNode.ShapeStr
  }

  /**
   * 通过当前棋盘，获取到95字节的数组
   * @returns 一维数组...
   */
  function GetZippedBoard() {
    var BZ = BoardSize // this is board size...
    let tmpBoard = new Array()
    for (let i = 0; i < 19; i++) {
      tmpBoard[i] = new Array()
      for (let j = 0; j < 19; j++) {
        tmpBoard[i][j] = 0
      }
    }
    // 把当前的棋盘Copy进去...
    for (let i = 0; i < BZ; i++) {
      for (let j = 0; j < BZ; j++) {
        tmpBoard[i][j] = Board[i][j]
      }
    }
    // 开始组装数据..
    let ResArr = new Array()
    for (let i = 0; i < 95; i++) {
      ResArr[i] = 0
    }

    var k = 0
    var a1
    var a2
    var a3
    var a4
    var tmpByte

    for (var i = 0; i < 19; i++) {
      tmpByte = 0
      a1 = tmpBoard[0][i]
      a2 = tmpBoard[1][i]
      a3 = tmpBoard[2][i]
      a4 = tmpBoard[3][i]
      tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6)
      ResArr[k] = tmpByte
      k++

      a1 = tmpBoard[4][i]
      a2 = tmpBoard[5][i]
      a3 = tmpBoard[6][i]
      a4 = tmpBoard[7][i]
      tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6)
      ResArr[k] = tmpByte
      k++

      a1 = tmpBoard[8][i]
      a2 = tmpBoard[9][i]
      a3 = tmpBoard[10][i]
      a4 = tmpBoard[11][i]
      tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6)
      ResArr[k] = tmpByte
      k++

      a1 = tmpBoard[12][i]
      a2 = tmpBoard[13][i]
      a3 = tmpBoard[14][i]
      a4 = tmpBoard[15][i]
      tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6)
      ResArr[k] = tmpByte
      k++

      a1 = tmpBoard[16][i]
      a2 = tmpBoard[17][i]
      a3 = tmpBoard[18][i]
      tmpByte = a1 + (a2 << 2) + (a3 << 4)
      ResArr[k] = tmpByte
      k++
    }

    if (BZ < 19) {
      ResArr[94] = 99 + BZ
    }

    return ResArr
  }

  /**
   * 生成打印信息...
   * startStep 表示起始打印的手数,如果没有，就表示从第一手开始
   * steopStep 表示结束打印的手数, 如果没有，就表示打印到最后...
   */
  function GeneratePrintInfo(startStep, stopStep) {
    // 	棋谱打印,只支持主谱部分...
    var startT
    var stopT
    if (startStep) {
      startT = startStep
    } else {
      startT = 1
    }
    if (stopStep) {
      stopT = stopStep
    } else {
      stopT = 10000
    }
    overLapArr = new Array()
    var tmpNode = content.FirstNode
    for (var i = 0; i < 19; i++) {
      for (var j = 0; j < 19; j++) {
        PrintBoard[i][j] = null
      }
    }
    var APrintNode = {
      showStr: '', //显示的字母...
      isShow: false // 是否显示...
    }
    var SkipFirst = false
    var CurrentOrder = 1
    while (tmpNode != null) {
      if (tmpNode.x == -2 || tmpNode.x == -3) {
        // 让子棋...
        SkipFirst = true
        // 遇到让子棋和形的时候,为了避免再次计算让子以及形的摆放,直接回到第一手去,看下Board里面的内容即可...
        GotoFirstStep()
        // console.log('kkkkkkkkkkkkkkkkkkkkkkkk')
        // debugger
        GenerateDrawArray()
        for (let i = 0; i < 19; i++) {
          for (let j = 0; j < 19; j++) {
            if (Board[i][j] > 0) {
              APrintNode = {}
              APrintNode.color = Board[i][j]
              APrintNode.isShow = false
              APrintNode.order = CurrentOrder
              APrintNode.showStr = 'a' + CurrentOrder
              CurrentOrder++
              AddAPrintNode(i, j, APrintNode)
            }
          }
        }
      } else {
        // 普通的对局...
        if (tmpNode.color == 1) {
          // 黑子...
          APrintNode = {}
          APrintNode.color = 1
          APrintNode.isShow = true
          APrintNode.order = CurrentOrder
          CurrentOrder++
          if (SkipFirst) {
            APrintNode.showStr = tmpNode.StepCount - 1
          } else {
            APrintNode.showStr = tmpNode.StepCount
          }
          if (tmpNode.StepCount >= startT && tmpNode.StepCount <= stopT) {
            APrintNode.isShow = true
          } else {
            APrintNode.isShow = false
          }
          AddAPrintNode(tmpNode.x, tmpNode.y, APrintNode)
        } else if (tmpNode.color == 2) {
          // 白子...
          APrintNode = {}
          APrintNode.color = 2
          APrintNode.isShow = true
          APrintNode.order = CurrentOrder
          CurrentOrder++
          if (SkipFirst) {
            APrintNode.showStr = tmpNode.StepCount - 1
          } else {
            APrintNode.showStr = tmpNode.StepCount
          }
          if (tmpNode.StepCount >= startT && tmpNode.StepCount <= stopT) {
            APrintNode.isShow = true
          } else {
            APrintNode.isShow = false
          }
          AddAPrintNode(tmpNode.x, tmpNode.y, APrintNode)
        }
      }
      tmpNode = tmpNode.NextNode
      if (!tmpNode) {
        //qgl改
        break
      }
      if (tmpNode.StepCount > stopT) {
        break
      }
    }

    var resNode = {}
    resNode.PrintArr = PrintBoard
    resNode.overLapArr = overLapArr
    return resNode
  }

  function AddAPrintNode(x, y, inNode) {
    if (x == -1 || y == -1) {
      //qgl 改
      return
    }
    if (PrintBoard[x][y] == null) {
      PrintBoard[x][y] = inNode
    } else {
      var ANode = PrintBoard[x][y]
      if (!ANode.isShow) {
        ANode.isShow = true
      }
      // 把inNode加入到重叠的数组里面去...
      var ok = false
      for (let i = 0; i < overLapArr.length; i++) {
        if (overLapArr.rightNode == ANode) {
          overLapArr.LeftArr.push(inNode)
          ok = true
          break
        }
      }
      if (ok) {
        return
      }
      // 需要重新插入进去..
      // 首先定位..
      var ii = -1
      for (let i = 0; i < overLapArr.length; i++) {
        if (overLapArr[i].rightNode.order > ANode.order) {
          ii = i
          break
        }
      }
      if (ii == -1) {
        ii = overLapArr.length
      }
      var tmpNode = {}
      tmpNode.rightNode = ANode
      tmpNode.LeftArr = new Array()
      tmpNode.LeftArr.push(inNode)
      if (overLapArr.length == 0 || ii == overLapArr.length) {
        overLapArr.push(tmpNode)
      } else {
        // 插入一个点...
        overLapArr.splice(ii, 0, tmpNode)
      }
    }
  }

  for (var i = 0; i < 19; i++) {
    Board[i] = new Array()
    NumbericBoard[i] = new Array()
    NumbericBoardForWork[i] = new Array()
    DeadBoard[i] = new Array()
    PreBoardArr[i] = new Array()
    PrintBoard[i] = new Array()
    sBoard[i] = new Array()
    for (var j = 0; j < 19; j++) {
      Board[i][j] = 0
      NumbericBoard[i][j] = 0
      DeadBoard[i][j] = 0
      sBoard[i][j] = 0
      PreBoardArr[i][j] = 0
      PrintBoard[i][j] = 0
    }
  }

  // AI形势、AI支招、棋子表情存放在当前手
  function saveCurrentData(obj) {
    if (obj.type == 'situation') {
      if (obj.isSave) {
        content.CurrentNode.situationData = obj.data
      } else {
        delete content.CurrentNode.situationData
      }
    }
    if (obj.type == 'action') {
      if (obj.isSave) {
        content.CurrentNode.actionData = obj.data
      } else {
        delete content.CurrentNode.actionData
      }
    }
    if (obj.type == 'face') {
      if (obj.isSave) {
        content.CurrentNode.faceData = obj.data
      } else {
        delete content.CurrentNode.faceData
      }
    }
  }
  // 判断当前棋子是否有 AI形势、AI支招、棋子表情数据
  function judgeAIData() {
    var AIData = []
    var tmpNode = content.CurrentNode
    if (!tmpNode) {
      return
    }
    if (tmpNode.situationData) {
      AIData.push({
        type: 'situation',
        data: tmpNode.situationData
      })
    }
    if (tmpNode.actionData) {
      AIData.push({
        type: 'action',
        data: tmpNode.actionData
      })
    }
    if (tmpNode.faceData) {
      AIData.push({
        type: 'face',
        data: tmpNode.faceData
      })
    }
    return AIData
  }

  this.SetBoardSize = SetBoardSize
  this.getCurrentComment = getCurrentComment
  this.GoInToVaryLast = GoInToVaryLast
  this.FormCurrentVary = FormCurrentVary
  // this.FormVaryPics =FormVaryPics;
  this.SaveToString = SaveToString
  this.ReadFromString = ReadFromString
  this.ClearAll = ClearAll
  this.GotoFirstStep = GotoFirstStep
  this.GotoPreStep = GotoPreStep
  this.GotoNextStep = GotoNextStep
  this.GotoLastStep = GotoLastStep
  this.DeleteDeadStone = DeleteDeadStone
  this.CopyBoard = CopyBoard
  this.CopyNumbericBoard = CopyNumbericBoard
  this.CopyNumbericForWork = CopyNumbericForWork
  this.CopyDeadBoard = CopyDeadBoard
  this.GetCurrentStepPosition = GetCurrentStepPosition
  this.AddStep = AddStep
  this.AddStepToLast = AddStepToLast
  this.GetCurrentSub = GetCurrentSub
  this.CheckInSub = CheckInSub
  this.JumpToMain = JumpToMain
  this.GetStepInfo = GetStepInfo
  this.GetCurrentColor = GetCurrentColor
  this.GetLastStepColor = GetLastStepColor
  this.ReceiveAStepIn = ReceiveAStepIn
  this.ReceiveAStepInWithColor = ReceiveAStepInWithColor
  this.ReceiveAStepInForWork = ReceiveAStepInForWork
  this.ReceiveAStepInWithCtrlOrShiftForWork = ReceiveAStepInWithCtrlOrShiftForWork
  this.GetCurrentStepinColor = GetCurrentStepinColor
  this.CheckCanSetpIn = CheckCanSetpIn
  this.CheckIsRightStepIn = CheckIsRightStepIn
  this.GetOutGraphicStr = GetOutGraphicStr
  this.GetBounderInfo = GetBounderInfo
  this.ReceiveACommentIn = ReceiveACommentIn
  this.GotoPreComment = GotoPreComment
  this.CountComment = CountComment
  this.GotoNextComment = GotoNextComment
  this.getStepComment = getStepComment
  this.GetRemoveString = GetRemoveString
  this.AddGraphic = AddGraphic
  this.AddAudio = AddAudio
  this.getCurrentVoice = getCurrentVoice
  this.GetMu = GetMu
  this.UndoRemoveAll = UndoRemoveAll
  this.RegretAtep = RegretAtep
  this.SliderToCurrentStep = SliderToCurrentStep
  this.GetToCurrent = GetToCurrent
  this.getTimuText = getTimuText
  this.ShowAnswer = ShowAnswer
  this.CheckErrorAction = CheckErrorAction
  this.CancelAnswer = CancelAnswer
  this.DeleteNowGraph = DeleteNowGraph
  this.DeleteNowParent = DeleteNowParent
  this.HasVoice = HasVoice
  this.GenerateTreeData = GenerateTreeData
  this.GetCurrentNodeInfo = GetCurrentNodeInfo
  this.JumpPointByKeyStr = JumpPointByKeyStr
  this.SetEndTypeByKeyStr = SetEndTypeByKeyStr
  this.GetCurrentNodeKeyStr = GetCurrentNodeKeyStr
  this.setTitle = setTitle
  this.CheckTimuType = CheckTimuType
  this.SetWorkFirstColor = SetWorkFirstColor
  this.GoToNextTenSteps = GoToNextTenSteps
  this.GoToPreTenSteps = GoToPreTenSteps
  this.GetShapeStrint = GetShapeStrint
  this.GetZippedBoard = GetZippedBoard
  this.ReadFromStream = ReadFromStream
  this.SaveToFile = SaveToFile
  this.GotoNextSub = GotoNextSub //qgl
  this.AddAudioNum = AddAudioNum //qgl
  this.changeAudioNum = changeAudioNum //qgl
  this.GetCount = GetCount //qgl
  this.saveCurrentData = saveCurrentData //qgl
  this.judgeAIData = judgeAIData //qgl
  this.GeneratePrintInfo = GeneratePrintInfo
  //this.GotoLastStep();
}

/*
   Some Common Function
*/

/*
  TransferDToC  将数字转成字母
*/
function TransferDToC(inNum) {
  var t = ''

  if (inNum > 7) {
    inNum = inNum + 1
  }

  t = String.fromCharCode(inNum + 65)
  return t
}

/*
   TransferCToD  将字母转成数字
*/
function TransferCToD(inC) {
  // 强制做下大小写转换，避免不必要的问题
  inC = inC.toUpperCase()
  var t = inC.charCodeAt(0)
  t = t - 65
  if (t > 8) {
    t = t - 1
  }

  if (t > 19) {
    t = -1
  }

  return t
}

/*
   比较两个数组是否一致
*/
function comparearray(a1, a2, ww, hh) {
  for (var i = 0; i <= ww; i++) {
    for (var j = 0; j <= hh; j++) {
      if (a1[i][j] != a2[i][j]) {
        return false
      }
    }
  }
  return true
}

/*
    把a2拷贝到a1里面去
*/
function DeepCopy(a1, a2, ww) {
  for (var i = 0; i <= ww; i++) {
    for (var j = 0; j <= ww; j++) {
      a1[i][j] = a2[i][j]
    }
  }
}

export { TStepNode, TSteps, TAliasQipu }
