<template>
    <div>
        <div class="main">
            <div class="masch">
              <div class="title">Reset password</div>
                <el-form class="email-from" :model="ruleForm" :rules="rules" ref="ruleForm">
                  <el-link @click="send" :underline="false">Send verification code</el-link>
                  <el-form-item  prop="email">
                        <!-- 邮箱 -->
                        <el-input
                        class="heikop"
                        v-model="ruleForm.email"
                        type="email"
                        autocomplete="off"
                        placeholder="Email"></el-input>
                        <img class="icon" src="@/assets/images/registered/tb-1.png" alt="">
                    </el-form-item>
                    
                    <el-form-item  prop="emailcode">
                        <el-input
                            class="heikop"
                            v-model="ruleForm.emailcode"
                            type="text"
                            autocomplete="off"
                            placeholder="Email verification code"
                        ></el-input>
                        <img class="icon_02" src="@/assets/images/registered/tb-2.png" alt="">
                        </el-form-item>
                   <!-- 密码 -->
                        <el-form-item  prop="password">
                        <el-input
                        class="heikop"
                        v-model.trim="ruleForm.password"
                        type="password"
                        maxlength="15"
                        autocomplete="off"
                        placeholder="Passworld"></el-input>
                        <!-- <img class="icon_03" src="@/assets/images/registered/tb-3.png" alt=""> -->
                    </el-form-item>
                    <el-form-item  prop="checkpassword">
                        <el-input
                            class="heikop"
                            v-model.trim="ruleForm.checkpassword"
                            type="password"
                            maxlength="15"
                            autocomplete="off"
                            placeholder="Confirm Passworld"
                        ></el-input>
                        <!-- <img class="icon_03" src="@/assets/images/registered/tb-4.png" alt=""> -->
                        </el-form-item>
                    
                    <!-- 提交 -->
                    <el-form-item>
                      <!-- <img class="resgted" src="@/assets/images/registered/registered.png" alt="" @click="submitForm('ruleForm')"> -->
                      <el-button type="success" @click="submitForm('ruleForm')">confirm</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonPicker,ElLoading } from 'element-plus'
import { send_pass,recover } from '../../service/login/login'
export default {
      data(){
      let username = (rule, value, callback) => {
      if (!value) {
      return callback(new Error('Please input username'))
      }else{
         callback()
      }
      }
      let Email = (rule, value, callback) => {
    const mailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
    if (!value) {
      return callback(new Error('Please input email address'))
    }else{
      setTimeout(() => {
      if (mailReg.test(value)) {
        callback()
      } else {
        callback(new Error('Please enter the correct email address'))
      }
    }, 100)
    }
    }
      let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password'));
        } else {
          if (this.ruleForm.password !== '') {
            this.$refs.ruleForm.validateField('checkpassword');
          }
          callback();
        }
      }
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password again'));
        } else {
          if (value != this.ruleForm.password) {
          callback(new Error("Two inputs don't match!"));
          }
          callback();
        }
      }
      let emailcode = (rule, value, callback) => {
      if (!value) {
      return callback(new Error('Please input emailcode'))
      }else{
         callback()
      }
      }
        return {
           ruleForm:{
               email:'',
               emailcode:'',
               password:'',
               checkpassword:'',
           },
           rules:{
            email:[
                {validator:Email,trigger:'blur'}
            ],
            password:[
               {validator:validatePass,trigger:'blur'} 
            ],
            checkpassword:[
               {validator:validatePass2,trigger:'blur'} 
            ],
            username:[
                {validator:username,trigger:'blur'}
            ],
            emailcode:[
                {validator:emailcode,trigger:'blur'}
            ]
         }
        }
    },
    methods:{
      send(){
          if(this.ruleForm.email!=''){
            let params={email:this.ruleForm.email}
            send_pass(params).then(res=>{
              if(res.code==0){
                this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              });
              }else{
                // this.$message.closeAll();
              //   this.$message({
              //   showClose: true,
              //   message: res.msg,
              //   type: 'error'
              // });
              }
            })
          }
      },
        submitForm(formName){
          let params = new FormData()
          params.append('username', this.ruleForm.email)
          params.append('password', this.ruleForm.password)
          params.append('code', this.ruleForm.emailcode)
            this.$refs[formName].validate((valid) => {
              if(valid){
                recover(params).then(res=>{
                  if(res.code==0){
                    this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success'
                  });
                  this.$router.push('./login')
                }else{
                this.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
                });
                }
                })
              }  
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';
 .main{
        width: 70%;
        height: 100%;
        margin: rem(20px) auto;
        .masch{
        width: rem(1044px);
        height: rem(623px);
        background:url(~@/assets/images/registered/k.png);
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .title{
          position: absolute;
          top: rem(-40px);
          left: rem(-30px);
          font-weight: 700;
            width: 32%;
            text-align: center;
            font-size: rem(24px);
            color: #fff;
        }
        .email-from{
          width: 70%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          .el-link{
            position: relative;
            right: -74%;
            margin-bottom:rem(10px);
            border-bottom: 1px solid #606266;
          }
          .el-link:hover{
            color: #409eff;
            border-bottom: 1px solid #409eff;
          }
          .icon{
          position: absolute;
          right: rem(20px);
          top: 50%;
          transform: translateY(-50%);
          width:rem(36px);
          height: rem(29px);
        }
        .icon_02{
          position: absolute;
          right: rem(20px);
          top: 50%;
          transform: translateY(-50%);
          width:rem(33px);
          width:rem(33px);
          height: rem(40px);
        }
        .icon_03{
          position: absolute;
          right: rem(20px);
          top: 50%;
          transform: translateY(-50%);
          width:rem(30px);
          height: rem(40px);
        }
        }
        .heikop .el-input__inner{
          height: 55px;
        }
        .resgted{
          width: rem(185px);
          height: rem(63px);
          cursor: pointer;
        }
        }
        .cvv{
            margin:0 rem(20px);
            color: #606266;
        }
    }

</style>