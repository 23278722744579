<template>
  <div class="main"
       :class="[getBgClass()]">
    <div class="title">
      <img :src="imgUrl(item)"
           alt=""
           v-for="(item,index) in selectColor"
           :key="index"
           @click="setColor(item)">
    </div>
    <div class="body">
      <div v-for="(item,index) in smallLevelData"
           :key="index">
        <img v-show="toNextData === index"
             class=""
             src="@/assets/images/gogame/smalllevel/tb-act.png"
             alt="">
        <img v-show="toNextData !== index"
             class=""
             src="@/assets/images/gogame/smalllevel/tb.png"
             alt="">
        <span class="level"
              @click="selectLevel(index)">{{setLevel(index+1)}}</span>
        <div class="mengban"
             v-if="item.unlock === 0">
          <img src="@/assets/images/gogame/smalllevel/mengban.png"
               alt="">
          <img class="suo"
               src="@/assets/images/gogame/smalllevel/suo.png"
               alt="">
        </div>
      </div>

    </div>
    <div class="foot">
      <img @click="toNext"
           src="@/assets/images/gogame/smalllevel/qr.png"
           alt="">
      <img @click="back"
           src="@/assets/images/gogame/smalllevel/qx.png"
           alt="">
    </div>

  </div>
</template>

<script setup>
import { getSmallLevelAPI } from '@/service/level/level.js';
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

let smallLevelData = ref([]);
let parentid = route.query.parentid;
let gametype = route.query.gametype;
const getGameData = async () => {
  let data = await getSmallLevelAPI({
    xb_userid: JSON.parse(sessionStorage.getItem('info')).go_username,
    parentid
  });
  data.data.forEach((item) => {
    smallLevelData.value.push(item);
  });
};
getGameData();

const getBgClass = () => {
  let url;
  switch (gametype) {
    case '1':
      url = 'chizi';
      break;
    case '2':
      url = 'lu9';
      break;
    case '3':
      url = 'lu13';
      break;
    case '4':
      url = 'lu19';
      break;
  }
  return url;
};

//动态绑定img的src
const imgUrl = (item) => {
  let url;
  switch (item.cmd) {
    case 1:
      item.isSelected ? (url = item.selectedImg) : (url = item.img);
      break;
    case 2:
      item.isSelected ? (url = item.selectedImg) : (url = item.img);
      break;
    case 0:
      item.isSelected ? (url = item.selectedImg) : (url = item.img);
      break;
  }
  return require(`@/assets/images/gogame/smalllevel/${url}.png`);
};

const selectColor = ref([
  {
    cmd: 1,
    img: 'heizi0',
    selectedImg: 'heizi1',
    isSelected: true
  },
  {
    cmd: 2,
    img: 'baizi0',
    selectedImg: 'baizi1',
    isSelected: false
  },
  {
    cmd: 0,
    img: 'caixian0',
    selectedImg: 'caixian1',
    isSelected: false
  }
]);

let saveColor = 1;
const setColor = (data) => {
  selectColor.value.forEach((item) => {
    item.isSelected = false;
    if (data.cmd === item.cmd) {
      item.isSelected = true;
      saveColor = data.cmd;
    }
  });
};

let biglevel = route.query.biglevel;
const setLevel = (index) => {
  return Number(biglevel) * 10 + Number(index);
};

const selectLevel = (index) => {
  toNextData.value = index;
};
const toNextData = ref(0);

const toNext = () => {
  // 带查询参数，结果是 /register?plan=private
  // parentid=5&gametype=1&guanqianum=1
  let smallid = smallLevelData.value[toNextData.value].id;
  let titleArr = `${gametype},${Number(biglevel) + 1},${toNextData.value + 1}`;
  router.push({ path: '/chess', query: { smallid, color: saveColor, title: titleArr } });
};

const back = () => {
  router.back();
};
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';

.main {
  width: rem(1359px);
  height: rem(900px);
  border-radius: rem(50px);
  background-size: cover;
  margin-left: rem(100px);
  padding: rem(160px) rem(60px) 0 rem(60px);

  .title {
    display: flex;
    justify-content: space-around;
    padding: 0 20%;

    img {
      width: rem(198px);
      height: rem(48px);
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    padding: rem(60px) 0 0 0;
    div {
      position: relative;
      flex: 1;
      text-align: center;
      margin-bottom: rem(32px);

      img {
        width: rem(211px);
        height: rem(214px);
      }

      .level {
        color: #921701;
        font-weight: 700;
        font-size: rem(20px);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        padding-top: rem(178px);
        cursor: pointer;
      }

      .mengban {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        position: absolute;
      }

      .suo {
        width: rem(38px);
        height: rem(50px);
        position: absolute;
        // margin-top: -50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .foot {
    display: flex;
    justify-content: space-around;
    padding: 0 rem(300px);
    img {
      width: rem(211px);
      height: rem(92px);
      cursor: pointer;
    }
  }
}

.chizi {
  background-image: url(~@/assets/images/gogame/level/chizi/bg.png);
}
.lu9 {
  background-image: url(~@/assets/images/gogame/level/9lu/bg.png);
}
.lu13 {
  background-image: url(~@/assets/images/gogame/level/13lu/bg.png);
}
.lu19 {
  background-image: url(~@/assets/images/gogame/level/19lu/bg.png);
}
</style>
