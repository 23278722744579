<template>
  <div class="container">
    <div class="body"
         ref='qipanBox'
         :style="{width:qipanWidth+'px'}">
      <uni-board ref="uniBoard"
                 :getBoardSize="gameInfo.BoardSize"
                 :getType="1"
                 @boardEvent="boardEventFn"
                 @boardEventTree="boardEventTreeFn"
                 :getColor="0"
                 :noMove="isnoMove" />
    </div>

    <div class="right">
      <div class="infobox">
        <chess-info :steps="tempSteps"
                    :playerInfo="playerInfo"
                    :komi="gameInfo.komi"
                    :title="title" />

      </div>

      <div class="common-res"
           v-if="gameInfo.ChessResult !=='playing'"
           :class="[winer== 'me'?'win-res':'lose-res']">
        <div class="btn-box">
          <img src="@/assets/images/gogame/gamebtn/next.png"
               alt=""
               @click="letAgain">
        </div>

      </div>
      <div class="foot">
        <chess-btn :gameInfo="gameInfo"
                   :tempSteps="tempSteps" />
      </div>
    </div>
  </div>
</template>

<script setup>
import UniBoard from '@/components/chess-board/SoundBoard.vue';
import ChessInfo from './ChessCpn/ChessInfo.vue';
import ChessBtn from './ChessCpn/ChessBtn.vue';
import ChessRes from './ChessCpn/ChessRes.vue';

let drawboradtime;
window.onresize = function () {
  if (drawboradtime) clearTimeout(drawboradtime);
  drawboradtime = setTimeout(() => {
    initBoard();
  }, 500);
};

import { ref, onMounted, onBeforeMount, nextTick, onBeforeUnmount, defineExpose, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const route = useRoute();
const store = useStore();

import uni from '@/utils/eventbus.js';
import { ElMessageBox, ElMessage } from 'element-plus';

const qipanWidth = ref(0);
const qipanBox = ref(null);

onMounted(() => {
  //使棋盘长宽相等
  qipanWidth.value = qipanBox.value.offsetHeight;
  initSocket();
});

const color = route.query.color;
let smallid = route.query.smallid;

const initSocket = () => {
  store.commit('startAISocket', {
    itemID: smallid,
    Color: color,
    xb_userid: JSON.parse(sessionStorage.getItem('info')).go_username,
    f_password: JSON.parse(sessionStorage.getItem('info')).go_password
  });

  uni.on('gameInfo', initGameInfo);
  uni.on('LuoZiData', addzi);
  uni.on('GameEnding', endgame);
  uni.on('XingShiData', chessRes);
  uni.on('WithdrawRes', handleWithdrawRes);

  store.state.mainSocket.connect();
};

onBeforeUnmount(() => {
  store.state.mainSocket.unconnect();
  ['gameInfo', 'LuoZiData', 'GameEnding', 'XingShiData', 'WithdrawRes'].forEach((item) => {
    uni.off(item);
  });
});

const gameInfo = ref({
    BoardSize: 9,
    StepStr: '',
    Color: 1,
    Komi: '',
    Steps: 0,
    ChessResult: 'playing',
    IsFinished: false,
    judge:true,
    GameType: 0
  }),
  playerInfo = ref({
    blackPlayer: {
      name: 'AI-xiaoxin',
      eatzi: 0,
      avatar: 'http://duiyi.xbkids.cn/wx/pu/dist/head/1.png'
    },
    whitePlayer: {
      name: 'AI-xiaoxin',
      eatzi: 0,
      avatar: 'http://duiyi.xbkids.cn/wx/pu/dist/head/1.png'
    }
  }), //前面两个保存基本的对局信息，
  showXingShi = ref(false),
  tempSteps = ref(0); //复盘时候使用

const initGameInfo = (data) => {
  let tempKomi;
  if (data.GameType == 4 || data.GameType == 11) {
    //吃子，有形，步数-1
    tempKomi = '0';
  } else {
    if (data.BoardSize === 13) {
      tempKomi = '3又1/4';
    } else {
      tempKomi = '3又3/4';
    }
  }

  gameInfo.value = {
    BoardSize: data.BoardSize,
    StepStr: data.StepStr,
    Color: data.Color,
    Komi: tempKomi,
    Steps: 0,
    ChessResult: 'playing',
    IsFinished: false,
    judge:true,
    GameType: data.GameType
  };

  if (data.Color == 1) {
    playerInfo.value.blackPlayer.name = 'me';
    playerInfo.value.whitePlayer.name = 'AI-xiaoxin';
  } else {
    playerInfo.value.whitePlayer.name = 'me';
    playerInfo.value.blackPlayer.name = 'AI-xiaoxin';
  }

  initBoard();
};

const uniBoard = ref(null);
const initBoard = () => {
  nextTick(() => {
    uniBoard.value.SetBoardSize(gameInfo.value.BoardSize);
    nextStep();
  });
};

const isnoMove = ref(false);
//watch下棋步数，控制棋盘点击
watch(
  () => gameInfo.value.Steps,
  (newVal, oldVal) => {
    // console.log('deep', newVal, oldVal);
    stepsForMove();
  },
  { deep: true }
);

const stepsForMove = () => {
  const setIsNoMove = (steps) => {
    //steps,分有棋形和没棋形的情况，有棋形从第2步开始，没棋形从第1步开始
    if (gameInfo.value.Steps % 2 === steps) {
      //轮黑走
      if (gameInfo.value.Color === 1) {
        //执黑
        isnoMove.value = false;
      } else {
        //执白
        isnoMove.value = true;
      }
    } else {
      //轮白走
      if (gameInfo.value.Color === 1) {
        //执黑
        isnoMove.value = true;
      } else {
        //执白
        isnoMove.value = false;
      }
    }
  };

  if (gameInfo.value.GameType === 4 || gameInfo.value.GameType === 11) {
    //吃子，有棋形，手数从2开始,,,Steps % 2=1
    setIsNoMove(1);
  } else {
    setIsNoMove(0);
  }
};

const addzi = (data) => {
  // console.log(data);
  gameInfo.value.StepStr += data.StepStr + ';';
  nextStep();
};

// 下一步
const nextStep = () => {
  if (gameInfo.value.StepStr) {
    uniBoard.value.LoadFromString(gameInfo.value.StepStr);
    uniBoard.value.WriteCount();
    uniBoard.value.cancelDrawSelfAN();

    nextTick(() => {
      tempSteps.value = gameInfo.value.Steps = uniBoard.value.GetStepInfo()[0];
    });
  }
};

// 提子数
const boardEventFn = (cmd, s) => {
  // console.log(cmd, s);
  if (cmd == 3) {
    let firInd = s.indexOf(' ');
    let secInd = s.lastIndexOf(' ');
    playerInfo.value.whitePlayer.eatzi = s.slice(firInd, secInd);
    playerInfo.value.blackPlayer.eatzi = s.slice(secInd);
  }
};

//通知ai落子
const boardEventTreeFn = (cmd, msg, number, number1) => {};

const winer = ref(''); //保存最终输赢
const endgame = (data) => {
  let num;
  let num1;
  // ResultStr: '7 6.5:0:+6.5' let str = "9 122.25:21:+101.25"
  // console.log(data.ResultStr);
  let endType = data.ResultStr.split(' ');
  // console.log(endType);
  if (endType[0] == 0) {
    gameInfo.value.ChessResult = 'black wins by white resignation';
  } else if (endType[0] == 1) {
    gameInfo.value.ChessResult = 'white wins by black resignation';
  } else if (endType[0] == 3) {
    gameInfo.value.ChessResult = `black wins by time`;
  } else if (endType[0] == 4) {
    gameInfo.value.ChessResult = `white wins by time`;
  } else if (endType[0] == 6) {
    num1 = Math.abs(-endType[1].split(':').pop());
    gameInfo.value.ChessResult = `black wins white with${num1}stones more`;
  } else if (endType[0] == 7) {
    num1 = Math.abs(-endType[1].split(':').pop());
    gameInfo.value.ChessResult = `white wins black with${num1}stones more`;
  } else if (endType[0] == 8) {
    num = endType[1].split(':')[0];
    num1 = Math.abs(-endType[1].split(':').pop());
    num1 = `${Math.floor(num1)}又${Math.round((num1 - Math.floor(num1)) / 0.25)}/4`;
    gameInfo.value.ChessResult = `black wins white with${num1}stones more`;
  } else if (endType[0] == 9) {
    num = endType[1].split(':')[0];
    num1 = Math.abs(-endType[1].split(':').pop());
    num1 = `${Math.floor(num1)}又${Math.round((num1 - Math.floor(num1)) / 0.25)}/4`;
    gameInfo.value.ChessResult = `white wins black with${num1}stones more`;
  }
  if (gameInfo.value.ChessResult == 'playing') console.log('hello');
  ElMessageBox.confirm(gameInfo.value.ChessResult, 'Confirm');

  if (gameInfo.value.ChessResult.split(' ')[0] == 'black' && gameInfo.value.Color == 1) {
    winer.value = 'me';
  } else if (gameInfo.value.ChessResult.split(' ')[0] == 'white' && gameInfo.value.Color == 2) {
    winer.value = 'me';
  } else {
    winer.value = 'ai';
  }

  gameInfo.value.IsFinished = true;
};

const chessRes = (data) => {
  // console.log(data.JudgeData.Data.toString())
  let arr1 = [];
  let arr2 = [];
  let arr3 = [];

  let boardsize = gameInfo.value.BoardSize;
  let komi;
  switch (boardsize) {
    case 9:
    case 13:
      komi = 6.5;
      break;
    case 19:
      komi = 7.5;
  }
  let res;
  if (data.JudgeData.BlackMu > data.JudgeData.WhiteMu + komi) {
    res = `黑胜${data.JudgeData.BlackMu - data.JudgeData.WhiteMu - komi}目`;
  } else {
    res = `白胜${data.JudgeData.WhiteMu + komi - data.JudgeData.BlackMu}目`;
  }
  // this.xingshiData = {
  //   BlackMu: data.JudgeData.BlackMu,
  //   WhiteMu: data.JudgeData.WhiteMu,
  //   komi,
  //   res
  // };

  if (data.JudgeData.b2 === 0) return;

  if (data.JudgeData.b1 === 1) {
    //形势判断
    // uni.showModal({
    //   title: '提示',
    //   content: `黑${data.JudgeData.BlackMu}子,白${data.JudgeData.WhiteMu},官子${data.JudgeData.GuanZi}`,
    //   showCancel: false
    // });
  } else {
    //申请数棋
    // uni.showModal({
    //   title: '提示',
    //   content: `黑${data.JudgeData.BlackMu}子,白${data.JudgeData.WhiteMu},官子${data.JudgeData.GuanZi}`,
    //   showCancel: false
    // });
  }
  showXingShi.value = true;

  let mudatarr = data.JudgeData.Data;
  // console.log(mudatarr);
  let times = Math.ceil(mudatarr.length / boardsize);
  for (let i = 0; i < mudatarr.length; i++) {
    if (mudatarr[i] > 100) {
      mudatarr[i] -= 200;
    }
  }
  //console.log(boardsize, mudatarr, mudatarr.length, times);
  arr3 = deepClone(mudatarr);
  for (let z = 0; z < times; z++) {
    arr1 = arr3.slice(0, times);
    arr2.push(arr1);
    arr3.splice(0, times);
    //console.log(arr1, arr3);
  }
  console.log(arr2);
  uniBoard.value.drawSelfAN(arr2);
  // this.butFlag = false;
};

const handleWithdrawRes = (data) => {
  // console.log(data);
  if (data.UndoRes === 1) {
    //上一步
    if (gameInfo.value.Steps > 1) {
      let tempQipu = gameInfo.value.StepStr.split(';');
      tempQipu.pop(); //抛出的为空
      tempQipu.pop(); //倒数第一子
      tempQipu.pop(); //倒数第二子
      gameInfo.value.StepStr = tempQipu.join(';') + ';';
      uniBoard.value.RegretAtep('delete');
      uniBoard.value.RegretAtep('delete');
      tempSteps.value = gameInfo.value.Steps = uniBoard.value.GetStepInfo()[0];
    }
  } else {
    let content;
    switch (data.ErrorNO) {
      case 1:
        content = 'board is empty, can not undo';
        break;
      case 2:
        content = "it's not your turn, can not undo";
        break;
      case 3:
        content = 'you can not undo 2 times continuously';
        break;
      case 4:
        content = 'no more undo permission';
        break;
      case 5:
        content = 'no undo permission in capture game.';
        break;
    }
    ElMessageBox.confirm(content, 'Confirm');
  }
};

//拷贝
const deepClone = (obj) => {
  let _obj = JSON.stringify(obj),
    objClone = JSON.parse(_obj);
  return objClone;
};

const nextGame = () => {
  uniBoard.value.cancelDrawSelfAN();
  smallid += 1;
  // chessResult.value = 'playing';
  nextTick(() => {
    store.state.mainSocket.applyGame(smallid);
  });
};

const letAgain = () => {
  uniBoard.value.cancelDrawSelfAN();
  nextTick(() => {
    store.state.mainSocket.applyGame(smallid);
  });
};

const title = ref('');
const setTielt = () => {
  let titleArr = route.query.title;
  titleArr = titleArr.split(',');
  switch (titleArr[0]) {
    case 1:
    case 2:
    case 3:
    case 4:
  }
};

const setTempSteps = () => {
  tempSteps.value = uniBoard.value.GetStepInfo()[0];
};

defineExpose({
  uniBoard,
  setTempSteps
});
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';
.container {
  height: 100%;
  width: 100%;
  display: flex;
}

.body {
  height: 100%;
  padding-right: rem(30px);
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .common-res {
    width: rem(553px);
    height: rem(195px);
  }

  .win-res {
    background: url(~@/assets/images/gogame/chessres/win.png) no-repeat;
    background-size: cover;
  }

  .lose-res {
    background: url(~@/assets/images/gogame/chessres/lost.png) no-repeat;
    background-size: cover;
  }
}

.infobox {
  width: rem(553px);
  height: rem(432px);
  // background-color: sandybrown;
}

.btn-box {
  padding: rem(90px) 0 0 0;
  text-align: center;
  img {
    width: rem(180px);
    height: rem(60px);
  }
}
</style>
