<template>
  <div class="main">
    <div class="header"><img src="@/assets/images/kifu/vt.png" alt="" /></div>
    <div class="menu">
      <div class="title">upload kifu</div>
      <div class="tit_info">
        <p v-for="(items, index) in lisp" :key="index" @click="match((cur = index), 1)" :class="{ on: cur == index }">
          {{ items }}
        </p>
      </div>
    </div>
    <div class="info_main">
      <div class="search" style="display:none">
        <input type="text" />
        <img src="@/assets/images/kifu/ss.png" alt="" />
      </div>
      <div class="select_tit">
        <div class="label" v-for="(item, index) in qipuinfo" :key="index" @click="qipushow(item.f_gamekey)">
          <div class="title">
            <span
              >Casual Play| {{ item.f_steps }} Mov|
              {{ item.f_boardsize == 9 ? '9x9' : item.f_boardsize == 13 ? '13x13' : '19x19' }} Board</span
            ><span>{{ item.gmt_create }}</span>
          </div>
          <div class="info">
            <span>小新 小新</span>
            <span>25k</span>
            <span v-show="item.f_result == 0" class="black">Black wins by resignation</span>
            <span v-show="item.f_result == 1" class="white">White wins by resignation</span>
          </div>
        </div>
      </div>
      <div class="foolter">
        <div class="fenye">
          <img src="@/assets/images/kifu/ym-1.png" @click="prev(index)" alt="" />
          <span>{{ pages }}/{{ Math.ceil(total / 7) }}</span>
          <img src="@/assets/images/kifu/ym-2.png" @click="next(index)" alt="" />
        </div>
      </div>
    </div>
    <div  class="info_right">
      <div class="qipan">
        <div ref="qipanBox" :style="{ height: qipanheight + 'px' }">
          <uni-board ref="uniBoard" :getBoardSize="gameInfo.BoardSize" :getType="1" :getColor="0" :noMove="true" />
        </div>
        <div class="qpbtn">
          <chess-btn class="main" :gameInfo="gameInfo"
                   :tempSteps="tempSteps" />
        </div>
        <div class="btn_info" style="display:none;">
          <div v-for="(item, i) in list" :key="i">
            <img :src="imgurl(item)" alt="" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="infomatch.dialogFormVisible">
      <el-form :model="infomatch.form" label-width="100px">
        <el-form-item label="Alias Name" :label-width="name">
          <el-input style="width: 100%" v-model="infomatch.form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Rank" :label-width="level">
          <el-select style="width: 100%" v-model="infomatch.form.level" placeholder="Please select a level">
            <el-option v-for="item in option()" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="infomatch.dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="infor">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import router from '@/router';
import { All_Matches, The_ongoing } from '../../service/kifu/kifu';
import { information } from '../../service/login/login';
import UniBoard from '@/components/chess-board/SoundBoard.vue';
import ChessBtn from '../gogame/ChessCpn/ChessBtn.vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted, onBeforeMount, nextTick, onBeforeUnmount, defineExpose, watch, reactive, computed } from 'vue';
import uni from '@/utils/eventbus.js';

//棋盘自适应，刷新棋盘
let drawboradtime;
window.onresize = function () {
  qipanheight.value = qipanBox.value.offsetWidth;
  if (drawboradtime) clearTimeout(drawboradtime);
  drawboradtime = setTimeout(() => {
    initBoard();

  }, 500);
};
//没有新博号的数据
const option = () => {
  let arr = [];
  for (var i = 25; i >= 1; i--) {
    arr.push(i + 'k');
  }
  for (var k = 1; k <= 5; k++) {
    arr.push(k + 'd');
  }
  return arr;
};
//跟人信息的数据
let infomatch = reactive({
  dialogFormVisible: false,
  form: {
    name: '',
    level: ''
  }
});

const infor = () => {
  console.log(infomatch.form.name);
  console.log(infomatch.form.level);
  let params = new FormData();
  params.append('sessionid', sessionStorage.getItem('sessid'));
  params.append('nickname', infomatch.form.name);
  params.append('level', infomatch.form.level);
  information(params).then((res) => {
    if (res.code == 0) {
      ElMessage({
        showClose: true,
        message: res.msg,
        type: 'success'
      });
      sessionStorage.setItem('info', JSON.stringify(res.data));
      infomatch.dialogFormVisible = false;
    } else {
      ElMessage({
        showClose: true,
        message: res.msg,
        type: 'error'
      });
    }
  });
};

const list = ['aj-1', 'aj-2', 'aj-3', 'aj-4', 'aj-5', 'aj-6', 'aj-7', 'aj-8'];
const lisp = ['All Matches', 'The ongoing'];
const cur = ref(0);
const gameInfo = ref({
  BoardSize:9,
  StepStr:'',
  IsFinished:true,
  Steps:0,
  judge:false
});
const imgurl = (url) => {
  return require(`@/assets/images/kifu/${url}.png`);
};
const qipanheight = ref(0);
const qipanBox = ref(null);
// let show=ref(false)
let qipuinfo = ref([]);
let pages = ref(1);
let total = ref(null);
onMounted(() => {
  //使棋盘长宽相等
  qipanheight.value = qipanBox.value.offsetWidth;
  initBoard();
});

//页面数据
const match = (a, page) => {
  if (sessionStorage.getItem('sessid')) {
    if (sessionStorage.getItem('info') != null && JSON.parse(sessionStorage.getItem('info')).go_username == '') {
      infomatch.dialogFormVisible = true;
    } else {
      sessionStorage.setItem('tab', a);
      if (a == 0) {
        pages.value = page;
        let params = new FormData();
        params.append('f_username', JSON.parse(sessionStorage.getItem('info')).go_username);
        params.append('isfinish', 0);
        params.append('type', 1);
        params.append('page', page);
        All_Matches(params).then((res) => {
          qipuinfo.value = res.data.data;
          total.value = res.data.total;
          console.log(res.data.data);
        });
      } else if (a == 1) {
        pages.value = page;
        let params = new FormData();
        params.append('f_username', JSON.parse(sessionStorage.getItem('info')).go_username);
        params.append('isfinish', 1);
        params.append('type', 1);
        params.append('page', page);
        All_Matches(params).then((res) => {
          qipuinfo.value = res.data.data;
          total.value = res.data.total;
          console.log(res.data.data);
        });
      } else {
        console.log(123);
      }
    }
  } else {
    router.push('./login');
  }
};
match(0, 1);
//棋谱数据
const qipushow = (a) => {
  let params = new FormData();
  params.append('f_gamekey', a);
  The_ongoing(params).then((res) => {
    //把字符串转换成棋谱格式
    let arr=[]
    let qipu=res.data.f_qipu
    for (let i=0;i<=qipu.length-3;i+=3){
      let tempstr = qipu[i]+'['+qipu[i+1]+qipu[i+2]+'];'
      arr.push(tempstr)
    }
    gameInfo.value.StepStr= arr.join('')
    gameInfo.value.BoardSize=res.data.f_boardsize
    initBoard();
  });
};
//上一页
const prev = () => {
  console.log(gameInfo.value.StepStr)
  let tab = sessionStorage.getItem('tab');
  if (pages.value == 1) {
    pages.value = 1;
    match(tab, pages.value);
  } else {
    pages.value--;
    match(tab, pages.value);
  }
};
//下一页
const next = () => {
  let tab = sessionStorage.getItem('tab');
  if (pages.value < Math.ceil(total.value / 7)) {
    pages.value++;
    match(tab, pages.value);
  } else {
    pages.value = Math.ceil(total.value / 7);
    match(tab, pages.value);
  }
};

const tempSteps = ref(0);
const uniBoard = ref(null);
const initBoard = () => {
  nextTick(() => {
    uniBoard.value.SetBoardSize(gameInfo.value.BoardSize);
    nextStep();
  });
};
const nextStep = () => {
  if (gameInfo.value.StepStr) {
    uniBoard.value.LoadFromString(gameInfo.value.StepStr);
    uniBoard.value.WriteCount();
    uniBoard.value.cancelDrawSelfAN();

    nextTick(() => {
      tempSteps.value = gameInfo.value.Steps = uniBoard.value.GetStepInfo()[0];
    });
  }
};
const setTempSteps = () => {
  tempSteps.value = uniBoard.value.GetStepInfo()[0];
};
defineExpose({
  uniBoard,
  setTempSteps
});
</script>

<style lang="scss" scoped>
@import '@/assets/css/rem.scss';
@import '@/assets/css/kifu.scss';
</style>
