<template>
  <div class="main"
       :class="[getBgClass()]">
    <div v-for="(item,index) in bigLevelData"
         :key="index">
      <img class=""
           :src="imgUrl(index)"
           alt=""
           @click="toNext(item,index)">
      <div class="start_box">
        <img src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/biglevel/x-1.png"
             mode=""
             v-for="(num,index) in Math.floor(item.f_starnum)"
             :key="index"
             class="start" />
        <img src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/biglevel/x-2.png"
             mode=""
             v-for="(num,index) in Math.ceil(item.f_starnum) - Math.floor(item.f_starnum)"
             :key="index"
             class="start" />
        <img src="http://duiyi.xbkids.cn/wx/img/ai/weiqi/biglevel/x-3.png"
             mode=""
             v-for="(num,index) in 5 - Math.ceil(item.f_starnum)"
             :key="index"
             class="start" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getBigLevelAPI } from '@/service/level/level.js';
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

let bigLevelData = ref([]);
const gametype = route.query.parentid;

const getBigLevel = async () => {
  if(sessionStorage.getItem('info')!=null){
    let username=JSON.parse(sessionStorage.getItem('info')).go_username
    let data = await getBigLevelAPI({
    xb_userid: username,
    parentid: gametype
  });
  data.data.forEach((item) => {
    bigLevelData.value.push(item);
  });
  }
};
getBigLevel();

const getBgClass = () => {
  let url;
  switch (gametype) {
    case '1':
      url = 'chizi';
      break;
    case '2':
      url = 'lu9';
      break;
    case '3':
      url = 'lu13';
      break;
    case '4':
      url = 'lu19';
      break;
  }
  return url;
};

const toNext = (data, index) => {
  // 带查询参数，结果是 /register?plan=private
  // parentid=5&gametype=1&guanqianum=1

  router.push({ path: '/smalllevel', query: { gametype, parentid: data.id, biglevel: index } });
};

//动态绑定img的src
const imgUrl = (index) => {
  let url;
  switch (route.query.parentid) {
    case '1':
      url = 'chizi';
      break;
    case '2':
      url = '9lu';
      break;
    case '3':
      url = '13lu';
      break;
    case '4':
      url = '19lu';
      break;
  }
  return require(`@/assets/images/gogame/level/${url}/tb-${index + 1}.png`);
};
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';

.main {
  width: rem(1359px);
  height: rem(900px);
  background-size: cover;
  border-radius: rem(50px);

  margin-left: rem(100px);
  padding: rem(240px) rem(70px) 0 rem(70px);

  display: flex;
  flex-wrap: wrap;

  div {
    position: relative;
    // width: 20%;
    text-align: center;
    // height: 30%;
    flex: 1;
    margin-bottom: rem(120px);

    img {
      width: rem(211px);
      height: rem(214px);
      cursor: pointer;
    }
    .start_box {
      position: absolute;
      display: flex;
      top: rem(160px);
      left: 0;
      right: 0;
      padding: 0 20%;
    }

    .start {
      width: rem(30px);
      height: rem(30px);
      padding: 0 rem(6px);
    }
  }
}

.chizi {
  background-image: url(~@/assets/images/gogame/level/chizi/bg.png);
}
.lu9 {
  background-image: url(~@/assets/images/gogame/level/9lu/bg.png);
}
.lu13 {
  background-image: url(~@/assets/images/gogame/level/13lu/bg.png);
}
.lu19 {
  background-image: url(~@/assets/images/gogame/level/19lu/bg.png);
}
</style>
