<template>
  <div class="main">
    <div>
      <img class="tu"
           src="@/assets/images/home/tu-1.png"
           alt="">
      <img class="wz"
           src="@/assets/images/home/wz-1.png"
           alt="">
    </div>
    <div>
      <img class="wz"
           src="@/assets/images/home/wz-2.png"
           alt="">
      <img class="tu"
           src="@/assets/images/home/tu-2.png"
           alt="">
    </div>
    <div>
      <img class="tu"
           src="@/assets/images/home/tu-3.png"
           alt="">
      <img class="wz"
           src="@/assets/images/home/wz-3.png"
           alt="">
    </div>
    <div class="flooter">
      <p style="margin:10px 0">Copyright 2021 Grand Genesis LLC</p>
      <p style="margin:10px 0">Terms & Conditions | Privacy | Customer Support</p>
    </div>
  </div>
</template>

<script setup>
</script>
<style scoped lang="scss">
@import '@/assets/css/rem.scss';
.main {
  width: 100%;
  height: 100%;
  padding: rem(30px);
  div {
    float: left;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: rem(20px);
    .tu {
      width: 45%;
      height: rem(465px);
    }
    .wz {
      width: 45%;
      height: rem(453px);
    }
  }
  .flooter {
    display: block;
    font-size: rem(20px);
    text-align: center;
    color: #fff;
  }
}
</style>
