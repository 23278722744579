<template>
  <div class="main"
       v-if="gameTypeData">
    <div v-for="item in gameTypeData"
         :key="item.f_name">
      <img class=""
           :src="imgUrl(item.f_name)"
           alt=""
           @click="toNext(item)" />
    </div>
  </div>
  <div class="main"
       v-else>
    <div v-for="item in gameTypeDataNo"
         :key="item.f_name">
      <img class=""
           :src="imgUrl(item.f_name)"
           alt=""
           @click="toNext" />
    </div>
    <el-dialog v-model="infomatch.dialogFormVisible">
      <el-form :model="infomatch.form"
               label-width="100px">
        <el-form-item label="Alias Name"
                      :label-width="name">
          <el-input style="width:100%;"
                    v-model="infomatch.form.name"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Rank"
                      :label-width="level">
          <el-select style="width:100%;"
                     v-model="infomatch.form.level"
                     placeholder="Please select a level">
            <el-option v-for="item in option()"
                       :key="item"
                       :label="item"
                       :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="infomatch.dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary"
                     @click="infor">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElLoading, ElMessage } from 'element-plus';
import { information } from '../../service/login/login';
import { getGameTypeAPI } from '@/service/level/level.js';
import { ref, reactive } from 'vue';
import router from '@/router';
console.log(sessionStorage.getItem('info'));
//等级
const option = () => {
  let arr = [];
  for (var i = 25; i >= 1; i--) {
    arr.push(i + 'k');
  }
  for (var k = 1; k <= 5; k++) {
    arr.push(k + 'd');
  }
  return arr;
};
//跟人信息的数据
let infomatch = reactive({
  dialogFormVisible: false,
  form: {
    name: '',
    level: ''
  }
});
//个人信息填写
const infor = () => {
  console.log(infomatch.form.name);
  console.log(infomatch.form.level);
  let params = new FormData();
  params.append('sessionid', sessionStorage.getItem('sessid'));
  params.append('nickname', infomatch.form.name);
  params.append('level', infomatch.form.level);
  information(params).then((res) => {
    if (res.code == 0) {
      ElMessage({
        showClose: true,
        message: res.msg,
        type: 'success'
      });
      sessionStorage.setItem('info', JSON.stringify(res.data));
      infomatch.dialogFormVisible = false;
    } else {
      ElMessage({
        showClose: true,
        message: res.msg,
        type: 'error'
      });
    }
  });
};

let gameTypeData = ref();
const getGameType = async () => {
  if (sessionStorage.getItem('info') != null) {
    let username = JSON.parse(sessionStorage.getItem('info')).go_username;
    let data = await getGameTypeAPI({
      xb_userid: username
    });
    gameTypeData.value = data.data.home;
    console.log(gameTypeData);
  }
};
getGameType();

const toNext = (data) => {
  if (data.id) {
    console.log(data.id);
    router.push({ path: '/biglevel', query: { parentid: data.id } });
  } else {
    if (sessionStorage.getItem('info') != null && JSON.parse(sessionStorage.getItem('info')).go_username == '') {
      infomatch.dialogFormVisible = true;
    } else {
      let sessid = sessionStorage.getItem('sessid');
      if (sessid == null) {
        router.push({ path: '/login' });
      }
    }
  }
  // 带查询参数，结果是 /register?plan=private
};

//动态绑定img的src
const imgUrl = (name) => {
  let url;
  switch (name) {
    case '吃子':
      url = 'chizi';
      break;
    case '9路围空':
      url = '9lu';
      break;
    case '13路围空':
      url = '13lu';
      break;
    case '19路围空':
      url = '19lu';
      break;
  }
  return require(`@/assets/images/gogame/type/${url}.png`);
};

const gameTypeDataNo = [
  {
    f_name: '吃子'
  },
  {
    f_name: '9路围空'
  },
  {
    f_name: '13路围空'
  },
  {
    f_name: '19路围空'
  }
];
</script>

<style scoped lang="scss">
@import '@/assets/css/rem.scss';

.main {
  width: rem(1359px);
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: rem(50px);

  margin-left: rem(100px);
  padding: rem(80px) rem(140px);

  display: flex;
  flex-wrap: wrap;

  div {
    width: 50%;
    text-align: center;
    height: 50%;
    .el-select {
      text-align: left;
    }
    img {
      width: rem(330px);
      height: rem(371px);
      cursor: pointer;
    }
  }
}
</style>
